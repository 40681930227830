import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-simple-toasts";
import { INVITEGUEST } from "./apiConfig";
import Swal from "sweetalert2";
function ViewModal(props) {
  // ----------->VARIABLES -------------->

  const [idproof, setIdproof] = useState();
  const [formRemarks, setFormRemarks] = useState();
  const [makeRemarksRequired, setMakeRemarksRequired] = useState(false);
  const [cameraModalShow, setCameraModalShow] = useState(false);
  // const [panNumber, setPanNumber] = useState();
  // const [aadharNumber, setAadharNumber] = useState();
  // const [panFile, setPanFile] = useState(null);
  // const [aadharFile, setAadharFile] = useState(null);
  // const [visitorProfileImage, setVisitorProfileImage] = useState(null);
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();
  //constants
  const refPan = useRef(null);
  const refAadhar = useRef(null);
  const [loadingTime, setloadingTime] = useState(false);

  //ERROR
  const [errorAadhar, setErrorAadhar] = useState(false);
  const [errorPan, setErrorPan] = useState(false);
  const [errorProfile, setErrorProfile] = useState(false);

  // ----------->FUNCTIONS -------------->
  function makeAadharValidations(data) {
    let myAadhar = data;
    let pattern = /^[0-9]+$/;
    let error = myAadhar.match(pattern);

    let countCheck = myAadhar.length;
    if (!error) {
      setErrorAadhar(true);
      return false;
    } else if (countCheck != 12) {
      setErrorAadhar(true);
      return false;
    } else {
      setErrorAadhar(false);
      return true;
    }
  }
  function makePanValidations(data) {
    let mypan = data;
    let pattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    let error = pattern.test(mypan);
    if (!error) {
      setErrorPan(true);
      return false;
    } else {
      setErrorPan(false);
      return true;
    }
  }
  function clearInputFiles() {
    if (refAadhar?.current) {
      refAadhar.current.value = "";
    } else if (refPan?.current) {
      refPan.current.value = "";
    }
  }
  const handleSubmit = (data) => {
    data.preventDefault();
    let hours = new Date().getHours();
    let minutes = new Date().getMinutes();
    let temp = `${hours}:${minutes}`;
    // let aadharInfo;
    // let panInfo;
    // if (idproof == "aadhar") {
    //   aadharInfo = makeAadharValidations(aadharNumber);
    // } else if (idproof == "pancard") {
    //   panInfo = makePanValidations(panNumber);
    // }
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("check_in", temp);
    formData.append("status", 2);
    // if (aadharNumber) {
    //   formData.append("aadhaar_number", aadharNumber);
    // }
    // if (panNumber) {
    //   formData.append("pan_card_number", panNumber);
    // }
    // formData.append("aadhaar_card_file", aadharFile);
    // formData.append("pan_card_file", panFile);
    formData.append("start_date", props.start_date_time);
    formData.append("end_date", props.end_date_time);
    // formData.append("profile_image_file", visitorProfileImage);

    // console.log(...formData);

    // if ((aadharInfo && errorProfile) || (errorProfile && panInfo)) {
      axios
        .post(`${INVITEGUEST}/${props.id}`, formData)
        .then((res) => {
          if (res?.data?.status) {
            // toast(`SuccessFully Checked in !`, {
            //   time: 2000,
            //   position: "top-right",
            //   clickable: true,
            //   clickClosable: true,
            //   className: "success",
            // });
            Swal.fire({
              html: `<i>SuccessFully Checked in!</i>`,
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 2000,
            });
            setloadingTime(true);
          } else {
              Swal.fire({
                    html: `<i>Something went wrong.</i>`,
                    icon: "warning",
                    title: "Warning",
                    showConfirmButton: false,
                    timer: 2000,
                  });
          }
          console.log(res, "API-CHECKIN");
        })
        .catch((error) => console.log(error));
    // }
  };
  const handleReject = (e) => {
    
    e.preventDefault()
      const formData = {
        rejected_reason: formRemarks,
        status: 5,
      };
      axios
        .put(`${INVITEGUEST}/${props.id}`, formData)
        .then((res) => {
          console.log(res,"res")
          if (res?.data?.status) {
            // toast(`SucessFully Rejected!`, {
            //   time: 2000,
            //   position: "top-right",
            //   clickable: true,
            //   clickClosable: true,
            //   className: "success",
            // });
            Swal.fire({
              html: `<i>SuccessFully Rejected!</i>`,
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 2000,
            });
            setloadingTime(true);
          } else {
            // toast("something went wrong. Please Try Again!", {
            //   time: 1000,
            //   position: "top-right",
            //   clickable: true,
            //   clickClosable: true,
            //   className: "error",
            // });
            Swal.fire({
              html: `<i>Something wrong</i>`,
              icon: "warning",
              title: "Warning",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          console.log(res, "API-REJECTION");
        })
        .catch((error) => console.log(error));
   
  };
  function makeTime(time) {
    let defaultTime = time;
    let edited = defaultTime?.split(":");
    if (edited) {
      let final = `${edited[0]}:${edited[1]} Hrs`;
      return final;
    }
  }
  function makeTimeVisiting(time) {
    let defaultTime = time;
    let edited = defaultTime?.split(":");
    if (edited) {
      let final = `${edited[0]} Hours`;
      return final;
    }
  }
  // ----------->USEEFFECTS -------------->
  useEffect(() => {
    let interval;
    if (loadingTime) {
      interval = setInterval(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loadingTime]);
  // useEffect(() => {
  //   clearInputFiles();
  //   setAadharNumber("");
  //   setPanNumber("");
  // }, [idproof]);

  return (
    <>
      <Modal {...props} className="checkInModal">
        {/* {props.start_date_time}
        {props.end_date_time} */}

        <form action="" className="checkInForm" >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Visitor Check In
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="Guest Name" className="visitors_modal_lable">
                    Guest Name
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    value={props.guest_name}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="visitors_modal_lable">
                    Guest Email ID
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    value={props.guest_email}
                    disabled
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{ display: props.invited_by ? "block" : "none" }}
                >
                  <label htmlFor="" className="visitors_modal_lable">
                    Invited By
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    value={props.invited_by}
                    disabled
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{ display: props.user_email_id ? "block" : "none" }}
                >
                  <label htmlFor="" className="visitors_modal_lable">
                    User Email ID
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    value={props.user_email_id}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="visitors_modal_lable">
                    Invited To
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    value={props?.property_name}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="visitors_modal_lable">
                    Invited date & Time
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    value={[
                      moment(props.invited_date).format("Do MMM YYYY"),
                      makeTime(props.invited_time),
                    ]}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="visitors_modal_lable">
                    Visiting Hours
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    value={makeTimeVisiting(props.visiting_hours)}
                    disabled
                  />
                </div>
                {/* <div className="col-md-6">
                  <label
                    htmlFor=""
                    className="visitors_modal_lable notDisableInput"
                  >
                    Member Body Temperature
                  </label>
                  <input type="text" className="visitors_modal_input" />
                </div> */}
                {/* <div className="col-md-6">
                  <label
                    htmlFor=""
                    className="visitors_modal_lable notDisableInput"
                  >
                    Visitor ID Proof <span className="text-danger">*</span>
                  </label>
                  <select
                    name=""
                    id=""
                    className="visitors_modal_input"
                    value={idproof}
                    onChange={(e) => {
                      setIdproof(e.target.value);
                    }}
                    required
                  >
                    <option value=""></option>
                    <option value="aadhar">Aadhar</option>
                    <option value="pancard">PanCard</option>
                  </select>
                </div> */}

                {/* <div className="col-md-6 align-self-end">
                  <label
                    htmlFor=""
                    className="visitors_modal_lable notDisableInput"
                  >
                    Visitor Image<span className="text-danger">*</span>
                  </label>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mb-2 w-100"
                      style={{
                        backgroundColor: errorProfile
                          ? "#2b8a3e"
                          : "dodgerblue",
                        borderColor: errorProfile ? "#2b8a3e" : "dodgerblue",
                      }}
                      onClick={() => setCameraModalShow(true)}
                    >
                      Take picture
                    </Button>
                    <input
                      type="file"
                      name="profileUpload"
                      className="visitors_modal_input "
                      style={{ paddingTop: "10px" }}
                      placeholder="Upload Image"
                      onChange={(e) => {
                        setVisitorProfileImage(e.target.files[0]);
                        setErrorProfile(true);
                      }}
                      required={!errorProfile}
                      accept=".png, .jpg, .jpeg, .pdf"
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-12">
                  {idproof ? (
                    idproof === "aadhar" ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="aadharNumbr"
                              className="visitors_modal_lable notDisableInput"
                            >
                              Aadhar Number
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="visitors_modal_input"
                              value={aadharNumber}
                              onChange={(e) => setAadharNumber(e.target.value)}
                              name="aadharNumbr"
                              required={idproof == "aadhar"}
                            />
                            <p
                              style={{
                                display: errorAadhar ? "block" : "none",
                              }}
                              className="inputError"
                            >
                              Enter valid AadharCard Number{" "}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="aadharFile"
                              className="visitors_modal_lable notDisableInput"
                            >
                              Upload Aadhar Image
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              className="visitors_modal_input "
                              onChange={(e) => setAadharFile(e.target.files[0])}
                              name="aadharFile"
                              style={{ marginLeft: "5px" }}
                              required={idproof == "aadhar"}
                              ref={refAadhar}
                              accept=".png, .jpg, .jpeg, .pdf"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="panNumbr"
                              className="visitors_modal_lable notDisableInput"
                            >
                              Pan Number
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="visitors_modal_input"
                              value={panNumber}
                              onChange={(e) => setPanNumber(e.target.value)}
                              name="panNumbr"
                              required={idproof == "pancard"}
                            />
                            <p
                              style={{
                                display: errorPan ? "block" : "none",
                              }}
                              className="inputError"
                            >
                              Enter valid AadharCard Number{" "}
                            </p>{" "}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="panFile"
                              className="visitors_modal_lable notDisableInput"
                            >
                              Upload PanCard Image
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              className="visitors_modal_input "
                              name="panFile"
                              onChange={(e) => setPanFile(e.target.files[0])}
                              style={{ marginLeft: "5px" }}
                              required={idproof == "pancard"}
                              ref={refPan}
                              accept=".png, .jpg, .jpeg, .pdf"
                            />
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </div> */}
                <div className="col-md-6">
                  <label htmlFor="" className="visitors_modal_lable">
                    Invited Purpose
                  </label>
                  <textarea
                    name=""
                    id=""
                    cols="24"
                    rows="3"
                    className="visitors_modal_textarea"
                    value={props.invited_purpose}
                    disabled
                  ></textarea>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor=""
                    className="visitors_modal_lable notDisableInput"
                  >
                    Remarks (if rejected) <span className="text-danger"></span>
                  </label>
                  <textarea
                    name=""
                    id=""
                    cols="24"
                    rows="3"
                    className="visitors_modal_textarea"
                    value={formRemarks}
                    onChange={(e) => setFormRemarks(e.target.value)}
                    required={makeRemarksRequired}
                  ></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          
            <button className="btn visitor_button" type="submit" onClick={handleSubmit} > 
              Allow
            </button>
            <button
              className="btn visitor_button"
              onClick={(e)=>{
                
                if(!formRemarks) {
                  // toast("Remarks Field is Required", {
                  //   time: 1500,
                  //   position: "top-right",
                  //   clickable: true,
                  //   // clickClosable: true,
                  //   className: "warning",
                  // });
                  Swal.fire({
                    html: `<i>Remarks Field is Required</i>`,
                    icon: "warning",
                    title: "Warning",
                    showConfirmButton: false,
                    timer: 2000,
                  });

                } else {
                  handleReject(e)
                }
               } }
            >
              Reject
            </button>
          </Modal.Footer>
        </form>
        <div className="lakshmanan">
          {/* <CameraModal
            className="lakshmanan"
            show={cameraModalShow}
            onHide={() => setCameraModalShow(false)}
            id={props.id}
            errorProfile={errorProfile}
            setErrorProfile={setErrorProfile}
          /> */}
        </div>
      </Modal>
    </>
  );
}

export default ViewModal;
