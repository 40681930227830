import React from 'react';
import './textDivider.css';

function TextDivider({ children }) {
  return (
    <div className="dividerContainer">
      <span>{children}</span>
    </div>
  );
}

export default TextDivider;
