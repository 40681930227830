import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-simple-toasts";
import {
  BOOKINGS,
  CORPORATE,
  CORPORATEPROPERTYLIST,
  GETEMPLOYEE,
  INVITEGUEST,
  NONBOOKINGPROPETIES,
  PROPERTYLIST,
  USERMAIL,
} from "./apiConfig";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";

function InviteGuest(props) {
  // ----------->VARIABLES -------------->
  const [forUserMail, setForUserMail] = useState();
  const [foruserId, setForUserId] = useState();
  const [propertiesList, setPropertiesList] = useState();
  const [propertyId, setPropertyId] = useState();
  const [formGuestFor, setFormGuestFor] = useState();
  const [formUserMail, setFormUserMail] = useState();
  const [formuserName, setFormUserName] = useState();
  const [formGuestName, setFormGuestName] = useState();
  const [formGuestMail, setFormGuestMail] = useState();
  const [formGuestPhone, setFormGuestPhone] = useState();
  const [formGuestInviteTo, setFormGuestInviteTo] = useState();
  const [formDate, setFormDate] = useState();
  const [formTime, setFormTime] = useState();
  const [formVisitingMinutes, setFormVisitingMinutes] = useState(0);
  const [formVisitingHour, setFormVisitingHour] = useState("0.00");
  const [formVisitingPurpose, setFormVisitingPurpose] = useState();
  const [formDataUserName, setFormDataUserName] = useState();
  const [formDataUserMail, setFormDataUserMail] = useState();
  //genearl constants
  const hours = [0, 1, 2, 3, 4];
  const minutes = [0, 15, 30, 45];
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  const [loadingTime, setloadingTime] = useState(false);
  const [inputTime, setInputTime] = useState();
  //errors
  const [errorGuestName, setErrorGuestName] = useState(false);
  const [errorGuestMail, setErrorGuestMail] = useState(false);
  const [errorGuestPhone, setErrorGuestPhone] = useState(false);
  const [errorvisitingHours, setErrorVisitingHours] = useState(false);
  const [errorMaxTime, setErrorMaxTime] = useState(false);

  //new variables
  const [userNameMail, setUserNameMail] = useState();
  const [corporateName, setCorporateName] = useState();
  const [corporateList, setCorporateList] = useState();
  const [nonBookingProperties, setNonBookingProperties] = useState();
  const [bookings, setBookings] = useState();
  const [bookingId, setBookingId] = useState();

  //new variables

  const mailMap = forUserMail?.map((usermail, index) => {
    return (
      <option value={usermail?.email} key={index}>
        {/* {findUserName(e.target.value, usermail)} */}
        {usermail?.email}
      </option>
    );
  });
  const propertyMap = propertiesList?.map((property, index) => {
    // let tempStartDate = new Date(property?.start_date);
    // let startDate = tempStartDate.toLocaleTimeString();
    // let tempEndDate = new Date(property?.end_date);
    // let endDate = tempEndDate.toLocaleTimeString();
    // {`${property?.property_name}
    //     - ${property?.property_code}`}
    return (
      <option value={property?.id} key={index} className="form-control">
        {`${property?.property_name}  `}
      </option>
    );
  });
  const corporateNameMap = corporateList?.map((corporate, index) => {
    return (
      <option value={corporate.id} key={index}>
        {corporate.name}
      </option>
    );
  });
  const nonBookingPropertyMap = nonBookingProperties?.map((property, index) => {
    return (
      <option
        value={property.id}
        key={index}>{`${property.property_name}`}</option>
    );
  });
  // -${property.property_code}-${property.start_at}-${property.end_at}
  // ----------->FUNCTIONS -------------->
  const getPropertyList = () => {
    axios
      .get(PROPERTYLIST, {
        params: {
          user_id: foruserId,
        },
      })
      .then((res) => {
        console.log(res, "API-PROPERTYLIST");
        setPropertiesList(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  function makeTime(time) {
    let defaultTime = time;
    let edited = defaultTime.split(":");
    let final = `${edited[0]}:${edited[1] + " hrs"}`;
  }
  makeTime("11:32:00");

  const handleGuestFor = (e) => {
    setFormGuestFor(e);
    if (e == 3) {
      axios
        .get(`${PROPERTYLIST}`)
        .then((res) => {
          console.log(res, "API-MEMEBER-PROPERTYLIST");
          setPropertiesList(res?.data?.data);
        })
        .catch((err) => console.log(err));
    } else if (e == 2) {
      axios
        .get(`${CORPORATE}`)
        .then((res) => {
          console.log(res, "API-CORPORATE");
          setCorporateList(res?.data?.data);
        })
        .catch((err) => console.log(err));
    } else if (e == 4) {
      axios
        .get(`${NONBOOKINGPROPETIES}`)
        .then((res) => {
          console.log(res, "API-NONBOOKINGPROPERTYLIST");
          setNonBookingProperties(res?.data?.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = (data) => {
    const checkdate = new Date(formDate + " " + formTime);
    data.preventDefault();
    //create start date and end date

    //error handling
    let nameInfo = makeErrorGuestName(formGuestName);
    let mailInfo = makeErrorGuestMail(formGuestMail);
    // let phoneInfo = makeErrorGuestPhone(formGuestPhone);
    let phoneInfo = formGuestPhone;

    let visitinginfo = validateVisitingHour(
      formVisitingHour,
      formVisitingMinutes
    );
    let oneDigit = Number(formVisitingHour);
    let twoDigits = Number(formVisitingMinutes) / 100;
    let temp = (oneDigit + twoDigits).toFixed(2);
    let startDateTime = new Date(`${formDate},${formTime}`);
    let endDateTime = new Date(startDateTime);
    endDateTime.setHours(endDateTime.getHours() + Number(formVisitingHour)); // Adding Hours
    endDateTime.setMinutes(
      endDateTime.getMinutes() + Number(formVisitingMinutes)
    ); // Adding Minutes
    endDateTime = new Date(endDateTime);
    setInputTime(temp);
    if (temp < 0.15) {
      setErrorVisitingHours(true);
    } else {
      setErrorVisitingHours(false);
    }
    let user = JSON.parse(localStorage.getItem("user"));
    // console.log(user)

    const formData = {
      booking_type_id: formGuestFor,
      user_id: formUserMail,
      booking_id: bookingId,
      corporate_id: corporateName,
      // "admin_id": 2,
      user_name: formDataUserName,
      user_email: formDataUserMail,
      guest_name: formGuestName,
      guest_email: formGuestMail,
      guest_phone: formGuestPhone,
      invited_property_id: propertyId,
      invited_date: formDate,
      invited_time: formTime,
      visiting_hours: temp,
      invited_purpose: formVisitingPurpose,
      start_date: startDateTime,
      end_date: endDateTime,
      status: 1,
      guest_added_by: user?.id,
    };
    console.log(formData.guest_added_by, "formdata", user.id);
    if (phoneInfo && nameInfo && mailInfo && temp > 0.14 && visitinginfo) {
      // return console.log('CALL API', "*********************");
      axios
        .post(INVITEGUEST, formData)
        .then((res) => {
          if (res?.data?.status) {
            // toast(`${res?.data?.message}!`, {
            //   time: 2000,
            //   position: "top-right",
            //   clickable: true,
            //   clickClosable: true,
            //   className: "success",
            // });
            Swal.fire({
              html: `<i>${res?.data?.message}</i>`,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            setloadingTime(true);
          } else {
            // toast("something went wrong. Please Try Again!", {
            //   time: 1000,
            //   position: "top-right",
            //   clickable: true,
            //   clickClosable: true,
            //   className: "error",
            // });

            Swal.fire({
              html: `<i>something went wrong. Please Try Again!</i>`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          console.log(res, "API-INVITEGUEST");
        })
        .catch((error) => console.log(error));
      // console.log(formData);
    }
    // console.log(formData);
  };
  const validateVisitingHour = (hours, minutes) => {
    if (hours == 4) {
      minutes = 0;
    }
    let tempHours = hours;
    let tempMinutes = minutes;
    setFormVisitingMinutes(tempMinutes);
    let maxDateTime = new Date(`${formDate},${maxTime}`); //ok
    let choosedTime = new Date(`${formDate},${formTime}`);
    let futureDateTime = new Date(choosedTime);
    futureDateTime.setHours(
      futureDateTime.getHours() + Number(formVisitingHour)
    ); // Adding Hours
    futureDateTime.setMinutes(
      futureDateTime.getMinutes() + Number(formVisitingMinutes)
    ); //adding minutes
    if (futureDateTime > maxDateTime) {
      setErrorMaxTime(true);
      return false;
    } else {
      setErrorMaxTime(false);
      return true;
    }
  };
  function findName() {
    return forUserMail?.map((data) => {
      if (data?.email === formUserMail) {
        setFormUserName(data?.name);
        return data?.name;
      }
    });
  }
  function findUserId() {
    return forUserMail?.map((data) => {
      if (data?.email === formUserMail) {
        setForUserId(data?.id);
        return data?.id;
      }
    });
  }

  function FindPropertyId() {
    return propertiesList?.map((data) => {
      if (data?.property_name === formGuestInviteTo) {
        setPropertyId(data?.id);
        // let tempStartDate = new Date(data?.start_date);
        // let startDate = tempStartDate.toDateString();
        // let tempMinDate = moment(startDate).format("YYYY-MM-DD");
        // setMinDate(tempMinDate);
        // let tempEndDate = new Date(data?.end_date);
        // let endDate = tempEndDate.toDateString();
        // let tempMaxDate = moment(endDate).format("YYYY-MM-DD");
        // setMaxDate(tempMaxDate);
        // let startTime = tempStartDate.toTimeString();
        // let splittedStartTime = startTime.split(":");
        // let minTimeHour = splittedStartTime[0];
        // let minTimeMinutes = splittedStartTime[1];
        // setMinTime(`${minTimeHour}:${minTimeMinutes}`);
        // let endTime = tempEndDate.toTimeString();
        // let splittedEndTime = endTime.split(":");
        // let maxTimeHour = splittedEndTime[0];
        // let maxTimeMinutes = splittedEndTime[1];
        // setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
      }
    });
  }
  function makeErrorGuestName(data) {
    let guestName = data;
    let pattern = /^[A-Za-z ]+$/;
    let error = guestName.match(pattern);

    if (!error) {
      setErrorGuestName(true);
      return false;
    } else {
      setErrorGuestName(false);
      return true;
    }
  }
  function makeErrorGuestPhone(data) {
    let guestName = data;
    let pattern = /^\d{10}$/;
    let error = guestName.match(pattern);

    if (!error) {
      setErrorGuestPhone(true);
      return false;
    } else {
      setErrorGuestPhone(false);
      return true;
    }
  }
  function makeErrorGuestMail(data) {
    let guestMail = data;
    let pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let result = pattern.test(guestMail);
    if (!result) {
      setErrorGuestMail(true);
      return false;
    } else {
      setErrorGuestMail(false);
      return true;
    }
  }

  function makeEmptyValue() {
    setForUserId("");
    setFormUserMail("");
    setFormGuestName("");
    setFormGuestMail("");
    setFormGuestPhone("");
    setFormUserName("");
    setFormUserMail("");
    setFormGuestInviteTo("");
    setFormDate("");
    setFormTime("");
    setFormVisitingHour("");
    setFormVisitingMinutes("");
    setFormVisitingPurpose("");
    handleGuestFor(1);
  }
  //new functions
  const findUserMailAndName = (data) => {
    if (formGuestFor == 3) {
      axios
        .get(`${USERMAIL}/${data}`)
        .then((res) => {
          if (res?.data?.status) {
            setUserNameMail(res.data.data);
          }
          console.log(res, "API-USERMAIL");
        })
        .catch((err) => console.log(err));
    } else if (formGuestFor == 2) {
      let formData = new FormData();
      formData.append("corporate_id", corporateName);
      formData.append("property_id", formGuestInviteTo);
      axios
        .post(`${GETEMPLOYEE}`, formData)
        .then((res) => {
          if (res?.data?.status) {
            setUserNameMail(res.data.data);
          }
          console.log(res, "API-USERMAIL");
        })
        .catch((err) => console.log(err));
    }
  };
  const userNameMailMap = userNameMail?.map((userName, index) => {
    return (
      <option value={userName?.id} key={index}>
        {`${userName?.name}  - ${userName?.email}`}
      </option>
    );
  });
  const setFormUserNameMail = () => {
    if (formUserMail) {
      userNameMail.map((user, index) => {
        if (user?.id == formUserMail) {
          setFormDataUserName(user.name);
          setFormDataUserMail(user.email);
        }
      });
    }
  };
  const findBookings = (data) => {
    axios
      .get(`${BOOKINGS}/${data}`)
      .then((res) => {
        if (res?.data?.status) {
          setBookings(res.data.data);
        }
        console.log(res, "API-BOOKINGS");
      })
      .catch((err) => console.log(err));
  };
  const bookingsMap = bookings?.map((booking, index) => {
    console.log(
      moment.utc(booking?.end_date).format("hh:mm:a"),
      booking?.end_date
    );
    return (
      <option
        value={booking?.id}
        key={index}
        style={{ textTransform: "uppercase" }}>
        {`${booking.booking_id} | ${moment(booking?.start_date).format(
          "DD-MMM-YYYY"
        )}  - ${moment(booking?.end_date).format("DD-MMM-YYYY")} | ${moment(
          booking?.start_date
        ).format("hh:mm:a")}  - ${moment(booking?.end_date).format("hh:mm:a")}`}
      </option>
    );
  });
  const getCorporateProperties = () => {
    axios
      .get(`${CORPORATEPROPERTYLIST}/${corporateName}`)
      .then((res) => {
        setPropertiesList(res?.data?.data);
        console.log(res, "API-CORPORATEPROPERTYLIST");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (corporateName) {
      getCorporateProperties();
    }
  }, [corporateName]);
  useEffect(() => {
    if (formGuestInviteTo) {
      FindPropertyId();
      findUserMailAndName(formGuestInviteTo);
    }
  }, [formGuestInviteTo]);
  useEffect(() => {
    if (formUserMail) {
      // findName();
      // findUserId();
      findBookings(formUserMail);
    }
  }, [formUserMail]);
  function FindTimeDate() {
    return bookings?.map((data) => {
      let currentDate = new Date().toDateString();
      if (data?.id == bookingId) {
        // console.log('im running');
        let tempStartDate = new Date(data?.start_date);
        let startDate = tempStartDate.toDateString();
        let tempMinDate = moment(startDate).format("YYYY-MM-DD");
        // console.log(moment(new Date()).format("YYYY-MM-DD"), tempMinDate);
        // --after corrections
        if (moment(new Date()).format("YYYY-MM-DD") < tempMinDate) {
          //5-5-23 < 15-5-23
          // console.log("greater cd");
          console.log(tempMinDate, "gee");
          setMinDate(tempMinDate);
        } else {
          // console.log("lesser cd");
          // console.log(tempMinDate);

          setMinDate(moment(currentDate).format("YYYY-MM-DD"));
        }
        // --after corrections

        let tempEndDate = new Date(data?.end_date);
        let endDate = tempEndDate.toDateString();
        let tempMaxDate = moment(endDate).format("YYYY-MM-DD");
        setMaxDate(tempMaxDate);
        let startTime = tempStartDate.toTimeString();
        let splittedStartTime = startTime.split(":");
        let minTimeHour = splittedStartTime[0];
        let minTimeMinutes = splittedStartTime[1];
        if (
          moment(formDate).format("YYYY-MM-DD") ==
          moment(currentDate).format("YYYY-MM-DD")
        ) {
          // console.log("today date");
          let tempCurrentTime = new Date().toTimeString();
          let splittedStartTime = tempCurrentTime.split(":");
          let minTimeHour = splittedStartTime[0];
          let minTimeMinutes = splittedStartTime[1];
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
          console.log(setMinTime, "setMinTime");
        } else {
          // console.log('not today ', minTimeHour, minTimeMinutes);
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
          console.log(setMinTime, "setMinTime");
        }
        let endTime = tempEndDate.toTimeString();
        let splittedEndTime = endTime.split(":");
        let maxTimeHour = splittedEndTime[0];
        let maxTimeMinutes = splittedEndTime[1];
        if (maxTimeHour == 0 && maxTimeMinutes == 0) {
          setMaxTime(`23:59`);
        } else {
          setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
        }

        // if(maxTimeHour == 0 && maxTimeMinutes=0 )
      }
    });
  }
  function corporateFindDateAndTime() {
    console.log("corp function");
    let currentDate = new Date().toDateString();
    propertiesList.map((data) => {
      if (data.id == formGuestInviteTo) {
        let startTime = data.start_at;
        let splittedStartTime = startTime.split(":");
        let minTimeHour = splittedStartTime[0];
        let minTimeMinutes = splittedStartTime[1];
        if (
          moment(formDate).format("YYYY-MM-DD") ==
          moment(currentDate).format("YYYY-MM-DD")
        ) {
          let tempCurrentTime = new Date().toTimeString();
          let splittedStartTime = tempCurrentTime.split(":");
          let minTimeHour = splittedStartTime[0];
          let minTimeMinutes = splittedStartTime[1];
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
          console.log(`${minTimeHour}:${minTimeMinutes}`, "setMinTime");
        } else {
          // console.log('not today ', minTimeHour, minTimeMinutes);
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
          console.log(`${minTimeHour}:${minTimeMinutes}`, "setMinTime");
        }
        let endTime = data.end_at;
        let splittedEndTime = endTime.split(":");
        let maxTimeHour = splittedEndTime[0];
        let maxTimeMinutes = splittedEndTime[1];
        if (maxTimeHour == 0 && maxTimeMinutes == 0) {
          setMaxTime(`23:59`);
        } else {
          setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
        }
      }
    });
    return corporateList?.map((data) => {
      console.log(data.id, corporateName);
      let currentDate = new Date().toDateString();
      if (data?.id == corporateName) {
        console.log("im running");
        let tempStartDate = new Date(data?.start_date);
        let startDate = tempStartDate.toDateString();
        let tempMinDate = moment(startDate).format("YYYY-MM-DD");
        // console.log(moment(new Date()).format("YYYY-MM-DD"), tempMinDate);
        // --after corrections
        if (moment(new Date()).format("YYYY-MM-DD") < tempMinDate) {
          //5-5-23 < 15-5-23
          // console.log("greater cd");
          setMinDate(tempMinDate);
        } else {
          // console.log("lesser cd");
          // console.log(tempMinDate);
          setMinDate(moment(currentDate).format("YYYY-MM-DD"));
        }
        // --after corrections

        let tempEndDate = new Date(data?.end_date);
        let endDate = tempEndDate.toDateString();
        let tempMaxDate = moment(endDate).format("YYYY-MM-DD");
        setMaxDate(tempMaxDate);
        return console.log("date validations");
        let startTime = tempStartDate.toTimeString();
        let splittedStartTime = startTime.split(":");
        let minTimeHour = splittedStartTime[0];
        let minTimeMinutes = splittedStartTime[1];
        if (
          moment(formDate).format("YYYY-MM-DD") ==
          moment(currentDate).format("YYYY-MM-DD")
        ) {
          // console.log("today date");
          let tempCurrentTime = new Date().toTimeString();
          let splittedStartTime = tempCurrentTime.split(":");
          let minTimeHour = splittedStartTime[0];
          let minTimeMinutes = splittedStartTime[1];
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
        } else {
          // console.log('not today ', minTimeHour, minTimeMinutes);
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
        }
        let endTime = tempEndDate.toTimeString();
        // return console.log(endTime);
        let splittedEndTime = endTime.split(":");
        let maxTimeHour = splittedEndTime[0];
        let maxTimeMinutes = splittedEndTime[1];
        if (maxTimeHour == 11 && maxTimeMinutes == 59) {
          setMaxTime(`23:59`);
        } else {
          setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
        }
        // setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);

        // if(maxTimeHour == 0 && maxTimeMinutes=0 )
      }
    });
  }
  function nonBookingTimeDate() {
    if (formGuestFor == 4) {
      return nonBookingProperties?.map((data) => {
        // console.log('non-booking')
        if (data.id == formGuestInviteTo) {
          // console.log(data.id);
          let currentDate = new Date().toDateString();
          setMinDate(moment(currentDate).format("YYYY-MM-DD"));
          let tempFutureDate = new Date();
          tempFutureDate.setDate(tempFutureDate.getDate() + 30);
          setMaxDate(moment(tempFutureDate).format("YYYY-MM-DD"));
          let startTime = data.start_at;
          let splittedStartTime = startTime.split(":");
          let minTimeHour = splittedStartTime[0];
          let minTimeMinutes = splittedStartTime[1];
          if (
            moment(formDate).format("YYYY-MM-DD") ==
            moment(currentDate).format("YYYY-MM-DD")
          ) {
            let tempCurrentTime = new Date().toTimeString();
            let splittedStartTime = tempCurrentTime.split(":");
            let minTimeHour = splittedStartTime[0];
            let minTimeMinutes = splittedStartTime[1];
            setMinTime(`${minTimeHour}:${minTimeMinutes}`);
            console.log(`${minTimeHour}:${minTimeMinutes}`, "setMinTime");
          } else {
            // console.log('not today ', minTimeHour, minTimeMinutes);
            setMinTime(`${minTimeHour}:${minTimeMinutes}`);
            console.log(`${minTimeHour}:${minTimeMinutes}`, "setMinTime");
          }
          let endTime = data.end_at;
          let splittedEndTime = endTime.split(":");
          let maxTimeHour = splittedEndTime[0];
          let maxTimeMinutes = splittedEndTime[1];
          if (maxTimeHour == 0 && maxTimeMinutes == 0) {
            setMaxTime(`23:59`);
          } else {
            setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
          }
        }
      });
    }
  }
  // date and time validations for non booking
  useEffect(() => {
    if (formGuestInviteTo) {
      nonBookingTimeDate();
    }
  }, [formGuestInviteTo, formDate]);
  //date and time validations for member
  useEffect(() => {
    if (bookingId) {
      FindTimeDate();
    }
  }, [bookingId, formDate]);
  //date and time validations for corporate
  useEffect(() => {
    if (formGuestInviteTo && formGuestFor == 2) {
      corporateFindDateAndTime();
    }
  }, [formGuestInviteTo, formDate]);
  useEffect(() => {
    if (formVisitingHour == 4) {
      setFormVisitingMinutes(0);
    }
  }, [formVisitingHour]);
  useEffect(() => {
    if (formUserMail) {
      setFormUserNameMail();
    }
  }, [formUserMail]);
  //new functions

  // ----------->USEEFFECTS -------------->

  // useEffect(() => {
  //   if (foruserId) {
  //     getPropertyList();
  //   }
  // }, [foruserId]);

  useEffect(
    (name) => {
      setFormUserName("");
      setFormUserMail("");
    },
    [formGuestFor]
  );
  useEffect(() => {
    let interval;
    if (loadingTime) {
      interval = setInterval(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loadingTime]);
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="inviteGuestModal">
        <form action="" className="inviteGuestForm" onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Invite Guest
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="row">
                {/* --invite guest for */}
                <div className="col-md-7">
                  <label
                    htmlFor="inviteGuestFor"
                    className="visitors_modal_lable">
                    Invite Guest For<span className="text-danger">*</span>
                  </label>
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <input
                        type="radio"
                        id="indivitualUser"
                        name="inviteGuestFor"
                        value="3"
                        onChange={(e) => {
                          handleGuestFor(e.target.value);
                        }}
                        required
                      />
                      <label
                        htmlFor="indivitualUser"
                        className="visitors_modal_lable_radio">
                        Member
                      </label>
                    </div>
                    <div className="">
                      <input
                        type="radio"
                        id="corporateUser"
                        name="inviteGuestFor"
                        value="2"
                        onChange={(e) => {
                          handleGuestFor(e.target.value);
                        }}
                        required
                      />
                      <label
                        htmlFor="corporateUser"
                        className="visitors_modal_lable_radio">
                        Corporate
                      </label>
                    </div>
                    <div className="">
                      <input
                        type="radio"
                        id="nonBooking"
                        name="inviteGuestFor"
                        value="4"
                        onChange={(e) => {
                          handleGuestFor(e.target.value);
                        }}
                        required
                      />
                      <label
                        htmlFor="nonBooking"
                        className="visitors_modal_lable_radio">
                        Guest
                      </label>
                    </div>
                  </div>

                  {/* <input
                    type="radio"
                    id="corporateEmployee"
                    name="inviteGuestFor"
                    value="6"
                    onChange={(e) => {
                      handleGuestFor(e.target.value);
                    }}
                    required
                  />
                  <label
                    htmlFor="corporateEmployee"
                    className="visitors_modal_lable_radio"
                  >
                    Corporate Employee
                  </label> */}
                </div>
                <div className="col-md-5"></div>
                {/* --corporate name */}
                <div
                  className="col-md-7"
                  style={{ display: formGuestFor == 2 ? "block" : "none" }}>
                  <label
                    htmlFor="corporateName"
                    className="visitors_modal_lable">
                    Corporate Name
                  </label>
                  <select
                    type="text"
                    onChange={(e) => setCorporateName(e.target.value)}
                    className="visitors_modal_input ">
                    <option value="" selected style={{ display: "none" }}>
                      CORPORATE NAME
                    </option>
                    {corporateNameMap}
                  </select>
                </div>
                {/* -- property list */}
                <div className={formGuestFor == 2 ? "col-md-5" : "col-md-7"}>
                  <label htmlFor="inviteTo" className="visitors_modal_lable">
                    Inviting To<span className="text-danger">*</span>
                  </label>
                  <select
                    name="inviteTo"
                    id=""
                    className="visitors_modal_input "
                    value={formGuestInviteTo}
                    onChange={(e) => {
                      setFormGuestInviteTo(e.target.value);
                      setPropertyId(e.target.value);
                    }}
                    required
                    placeholder="PROPERTY NAME">
                    {/* {propertiesList ? <><option selected="true" >PROPERTY NAME - PROPERTY CODE</option></> : <></>} */}
                    {/* <option value="" selected style={{ display: "none" }}>
                      PROPERTY NAME - PROPERTY CODE
                    </option> */}
                    <option value="" selected style={{ display: "none" }}>
                      PROPERTY NAME
                    </option>
                    {/* {propertyMap} */}
                    {formGuestFor == 2 || formGuestFor == 3
                      ? propertyMap
                      : formGuestFor == 4
                      ? nonBookingPropertyMap
                      : ""}
                    <option
                      value=""
                      disabled
                      style={{ display: formGuestFor ? "none" : "block" }}>
                      please select invite guest for Field
                    </option>
                  </select>
                </div>
                {/* --user email */}
                <div
                  className={formGuestFor == 2 ? "col-md-7" : "col-md-5"}
                  style={{
                    display: formGuestFor == "4" ? "block" : "block",
                  }}>
                  <label htmlFor="userEmail" className="visitors_modal_lable">
                    Invited By - Email ID<span className="text-danger">*</span>
                  </label>
                  <select
                    name="userEmail"
                    id=""
                    className="visitors_modal_input"
                    value={formUserMail}
                    onChange={(e) => setFormUserMail(e.target.value)}
                    disabled={formGuestFor === "4" ? true : false}
                    required={formGuestFor === "4" ? "" : "required"}>
                    <option value="" selected style={{ display: "none" }}>
                      USER NAME - USER MAIL
                    </option>
                    <option
                      value=""
                      disabled
                      style={{ display: formGuestInviteTo ? "none" : "block" }}>
                      Please select Inviting to Field
                    </option>
                    {userNameMailMap}
                  </select>
                </div>
                {/* --bookings list */}
                <div
                  className="col-md-7"
                  style={{
                    display:
                      formGuestFor == 4 || formGuestFor == 2 ? "none" : "block",
                  }}>
                  <label htmlFor="booking_id" className="visitors_modal_lable">
                    Bookings
                  </label>
                  <select
                    type="text"
                    className="visitors_modal_input"
                    onChange={(e) => {
                      setBookingId(e.target.value);
                    }}
                    style={{
                      textTransform: bookingId ? "uppercase" : "",
                      fontWeight: bookingId ? "700" : "",
                    }}>
                    <option value="" selected style={{ display: "none" }}>
                      BOOKING ID - START DATE - END DATE
                    </option>
                    <option
                      value=""
                      disabled
                      style={{ display: formUserMail ? "none" : "block" }}>
                      please select User Name Field
                    </option>
                    {bookingsMap}
                  </select>
                </div>
                {/* <div
                  className="col-md-6"
                  style={{
                    display: formGuestFor == "4" ? "none" : "block",
                  }}
                >
                  <label htmlFor="userName" className="visitors_modal_lable">
                    User Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    name="userName"
                    value={formuserName}
                    readOnly={true}
                    onChange={(e) => setFormUserName(e.target.value)}
                    required
                  />
                </div> */}
                {/* -- guest name */}
                <div className="col-md-5">
                  <label htmlFor="guestName" className="visitors_modal_lable">
                    Guest Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="visitors_modal_input"
                    name="guestName"
                    value={formGuestName}
                    onChange={(e) => {
                      setFormGuestName(e.target.value);
                    }}
                    required
                  />
                  <p
                    style={{ display: errorGuestName ? "block" : "none" }}
                    className="inputError">
                    Enter Valid Name
                  </p>
                </div>
                {/* -- guest mail */}
                <div className="col-md-7">
                  <label htmlFor="guestMail" className="visitors_modal_lable">
                    Guest Mail ID<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="visitors_modal_input"
                    name="guestMail"
                    value={formGuestMail}
                    onChange={(e) => setFormGuestMail(e.target.value)}
                    required
                  />
                  <p
                    style={{ display: errorGuestMail ? "block" : "none" }}
                    className="inputError">
                    Enter Valid Email
                  </p>
                </div>
                <div className="col-md-5 date_error">
                  <label htmlFor="date" className="visitors_modal_lable">
                    Phone<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="visitors_modal_input"
                    value={formGuestPhone}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (/^\d{0,10}$/.test(newValue)) {
                        setFormGuestPhone(newValue);
                      }
                    }}
                    required
                  />
                  <p
                    style={{
                      display: errorGuestPhone ? "block" : "none",
                    }}
                    className="inputError">
                    Enter Valid Phone
                  </p>

                  <label htmlFor="date" className="inputError"></label>
                </div>
                {/* -- inviting date */}
                <div className="col-md-4 date_error">
                  <label htmlFor="date" className="visitors_modal_lable">
                    date <span className="text-danger">*</span>
                  </label>

                  <input
                    type="date"
                    name="date"
                    id=""
                    className="visitors_modal_input"
                    value={formDate}
                    min={minDate}
                    // max={maxDate}
                    onChange={(e) => setFormDate(e.target.value)}
                    style={{
                      color: formDate ? "#111" : "#000",
                    }}
                    required
                  />
                  <label htmlFor="date" className="inputError"></label>
                </div>
                {/* -- inviting time */}
                <div className="col-md-4 time_error">
                  <label htmlFor="time" className="visitors_modal_lable">
                    Time <span className="text-danger">*</span>
                    <span
                      style={{
                        display: formDate ? "inline" : "none",
                        marginLeft: "1rem",
                      }}
                      className="text-muted"
                      // {`${minTime}:${maxTime}`}
                    >
                      {moment(`${formDate},${minTime}`).format("hh : mm : A")}{" "}
                      to{" "}
                      {moment(`${formDate},${maxTime}`).format("hh : mm : A")}
                    </span>
                  </label>
                  <input
                    type="time"
                    name="time"
                    id=""
                    className="visitors_modal_input"
                    min={minTime}
                    max={maxTime}
                    value={formTime}
                    onChange={(e) => setFormTime(e.target.value)}
                    style={{
                      color: formTime ? "#111" : "#000",
                    }}
                    required
                  />
                  {/* <DatePicker
                    selected={formTime}
                    onChange={(date) => setFormTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    minTime={minTime}
                    maxTime={maxTime}
                    className="visitors_modal_input"
                    required
                    style={{
                      color: formTime ? "#111" : "#000",
                    }}
                  /> */}

                  <label for="time" className="inputError"></label>
                </div>
                {/* -- viting hour */}
                <div className="col-md-4">
                  <label
                    htmlFor="visitingHour"
                    className="visitors_modal_lable">
                    Visiting Hours<span className="text-danger">*</span>
                  </label>

                  <div
                    className="visitors_modal_input_flex"
                    style={{ position: "relative" }}>
                    <div className="selectParent">
                      <span className="visitor_select_hour">hours</span>
                      <select
                        name="visitingHour"
                        id=""
                        className="visitors_modal_input input-container"
                        onChange={(e) => setFormVisitingHour(e.target.value)}
                        required>
                        <option value="" disabled></option>
                        {hours.map((hour, index) => {
                          return (
                            <option
                              value={hour.toFixed(2)}
                              key={index}
                              className="">
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="selectParent">
                      <span className="visitor_select_minute">minutes</span>
                      <select
                        name=""
                        className="visitors_modal_input"
                        id=""
                        value={formVisitingMinutes}
                        onChange={(e) => setFormVisitingMinutes(e.target.value)}
                        required
                        disabled={formVisitingHour == 4 ? true : false}>
                        {formVisitingHour === "0.00"
                          ? minutes
                              .filter((time) => time !== 15)
                              .map((time, i) => (
                                <option
                                  value={formVisitingHour === "4" ? 0 : time}
                                  key={i}>
                                  {time}
                                </option>
                              ))
                          : minutes.map((minute, index) => (
                              <option
                                value={
                                  formVisitingHour === "4"
                                    ? 0
                                    : formVisitingHour === "0.00"
                                    ? minutes.filter((time) => time !== 15)
                                    : minute
                                }
                                key={index}>
                                {minute}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <p
                    style={{ display: errorvisitingHours ? "block" : "none" }}
                    className="inputError">
                    It should be above 15 mins
                  </p>
                  <p
                    style={{ display: errorMaxTime ? "block" : "none" }}
                    className="inputError">
                    Visting Hours should be less than booking Hours
                  </p>
                </div>
                {/* -- inviting purpose */}
                <div className="col-md-12">
                  <label
                    htmlFor="purposeVisit"
                    className="visitors_modal_lable">
                    Purpose of Visit<span className="text-danger">*</span>
                  </label>
                  <textarea
                    name="purposeVisit"
                    id=""
                    cols="24"
                    rows="3"
                    className="visitors_modal_textarea"
                    value={formVisitingPurpose}
                    onChange={(e) => setFormVisitingPurpose(e.target.value)}
                    required></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn visitor_button"
              onClick={() => {
                props.onHide();
                makeEmptyValue();
              }}>
              Cancel
            </button>
            <button className="btn visitor_button" type="submit">
              Send Invite
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default InviteGuest;
