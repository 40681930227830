import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import Navbar from "../HeaderSection/Navbar";
import styles from "./reports.module.css";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  Stack,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "react-spinners/RingLoader";

import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";
import CategoryIcon from "@mui/icons-material/Category";
// import CityIcon from "@mui/icons-material/Public";
import CityIcon from "@mui/icons-material/Language";
import PropertyIcon from "@mui/icons-material/Apartment";
import MonthIcon from "@mui/icons-material/CalendarMonthOutlined";
import SummaryIcon from "@mui/icons-material/ShortText";
import DetailedIcon from "@mui/icons-material/Subject";

import { DateInputBRH } from "../forminputs/DateInputBRH";
import { AutoCompleteInputBRH } from "../forminputs/AutoCompleteInputBRH";
import { NormalTable } from "../../tables/NormalTable";
import { calculateActualTimeDifference } from "../TablesSections/TableComponent";
import { CheckBoxInputBRH } from "../forminputs/CheckBoxInputBRH";
import TextDivider from "../../text-divider/TextDivider";
import SummaryTable from "./SummaryTable";
import * as API from "../../API_SERVICES/ApiServices";
import {
  CITYLIST,
  ISSUE_CATEGORY_LIST,
  PROPERTY_LIST,
  STATELIST,
  USER_LIST,
} from "../../API_SERVICES/ApiNames";
import GetAppIcon from "@mui/icons-material/GetApp";

const DATE_TYPE_MONTH = 1;
const DATE_TYPE_CALENDAR = 2;
const DATE_TYPE_YEAR = 3;
// const DATE_TYPE_YEAR = 3;
export const ISSUE_UNASSIGNED = 1;
export const ISSUE_ASSIGNED = 2;
export const ISSUE_WORK_STARTED = 3;
export const ISSUE_RESOLVED = 4;
export const ISSUE_REOPEN = 5;
export const ISSUE_CLOSED = 6;
export const ISSUE_DELAYED = 10;
const ICON_SIZE = "large";

const issueActionStatusName = (statusId) => {
  switch (statusId) {
    case 1:
      return "Unassigned Tickets";
    case 2:
      return "Assigned To Staff";
    case 3:
      return "Work Started";
    case 4:
      return "Resolved";
    case 5:
      return "Re-Open";
    case 6:
      return "Closed";

    default:
      break;
  }
};
const userTypeOptions = [
  { name: "MG-Employee", value: 10 },
  { name: "Member", value: 5 },
  { name: "Corporate", value: 6 },
  { name: "Employee", value: 7 },
];
const statusOptions = [
  { name: "Unassigned Tickets", value: ISSUE_UNASSIGNED },
  { name: "Assigned To Staff", value: ISSUE_ASSIGNED },
  { name: "Work Started", value: ISSUE_WORK_STARTED },
  { name: "Resolved", value: ISSUE_RESOLVED },
  { name: "Re-Open", value: ISSUE_REOPEN },
  { name: "Closed", value: ISSUE_CLOSED },
  { name: "Delayed", value: ISSUE_DELAYED },
];
const initialState = {
  isLoading: false,
  isSelectLoading: true,
  // DATE-RANGE
  startDate: null,
  endDate: null,
  dateType: DATE_TYPE_MONTH,
  // REPORT BY
  isReportByMonth: false,
  isReportByProperty: false,
  isReportByCity: false,
  isReportByIssuecategory: false,
  // REPORT TYPE
  showSummaryReport: true,
  showDetailReport: false,
  // FILTERS
  stateObj: null,
  stateOptions: [],
  city: [],
  cityOptions: [],
  property: [],
  propertyOptions: [],
  person: null,
  personOptions: [],
  category: null,
  categoryOptions: [],
  userType: null,
  status: null,

  isStateSelected: false,
  isCitySelected: false,
  isPropertySelected: false,
  isPersonSelected: false,
  iscategorySelected: false,

  cityTableData: [],
  propertyTabledata: [],
  personTableData: [],
  categorytableData: [],
  // MASTER
  allIssuesList: [],
  filteredIssues: [],
  ReFilteredIssues: [],
  monthTableData: { header: "", data: [] },
  stateList: [],
  cityList: [],
  propertyList: [],
  userList: [],
  categoryList: [],
  userTypOptions: [],
  // FILTER-REPORT
  filteredIssuesbyMonths: [],
  filteredIssuesbyProperty: [],
  filteredIssuesbyCategory: [],
  filteredIssuesbyCity: [],
};
// RESETTINF FILTER VALUES WHEN DATE RANGE CHANGE
const resetFilterValues = {
  stateObj: null,
  city: [],
  property: [],
  person: null,
  category: null,
  userType: null,
  status: null,
  // RESET SUMMARY/DETAIL REPORT
  showSummaryReport: false,
  showDetailReport: false,
};
function reducer(state, action) {
  switch (action.type) {
    case "fetchData":
      return { ...state, isLoading: true };
    case "selectLoading":
      return { ...state, isSelectLoading: action.payload };
    case "form/startDate":
      // return {...state,startDate: action.payload} };
      return { ...state, ...resetFilterValues, startDate: action.payload };
    case "form/endDate":
      return {
        ...state,
        ...resetFilterValues,
        endDate: action.payload,
        showSummaryReport: true,
      };
    case "form/dateType":
      return {
        ...state,
        ...resetFilterValues,
        dateType: action.payload,
        startDate: null,
        endDate: null,
        monthTableData: {
          header: "",
          data: [],
        },
        isReportByMonth: false,
        isReportByCity: false,
        isReportByIssuecategory: false,
        isReportByProperty: false,
        // filteredIssuesbyMonths: {},
      };
    case "form/Reset":
      return {
        ...state,
        monthTableData: { header: "", data: action.payload },
      };
    case "form/reportBy":
      return {
        ...state,
        [action.name]: action.payload,
      };
    case "form/reportType":
      return {
        ...state,
        [action.name]: action.payload,
      };
    // case ''
    case "filterIssue/tableData":
      // let issuesList = [];
      // if (action.purpose === "total") {
      //   issuesList = action.payload;
      // }
      // if (action.purpose === "unassigned") {
      //   issuesList = action.payload;
      // } else if (action.purpose === "assigned") {
      //   issuesList = action.payload;
      // } else if (action.purpose === "started") {
      //   issuesList = action.payload;
      // } else if (action.purpose === "resolved") {
      //   issuesList = action.payload;
      // } else if (action.purpose === "closed") {
      //   issuesList = action.payload;
      // } else if (action.purpose === "delayed") {
      //   issuesList = action.payload;
      // }
      // return { ...state, monthTableData: issuesList };
      return { ...state, monthTableData: action.payload };
    case "filterIssue/summaryReport":
      if (action.purpose === "month") {
        return { ...state, filteredIssuesbyMonths: action.payload };
      } else if (action.purpose === "property") {
        return { ...state, filteredIssuesbyProperty: action.payload };
      } else if (action.purpose === "category") {
        return { ...state, filteredIssuesbyCategory: action.payload };
      } else if (action.purpose === "city") {
        return { ...state, filteredIssuesbyCity: action.payload };
      }
      break;
    case "filterIssue/Refilter":
      return { ...state, ReFilteredIssues: action.payload };
    case "filter/property":
      return {
        ...state,
        property: action.payload,
      };
    case "filter/city":
      return {
        ...state,
        city: action.payload,
      };
    case "filter/state":
      return {
        ...state,
        stateObj: action.payload,
        city: [],
      };
    case "filter/reportedPerson":
      return { ...state, person: action.payload };
    case "filter/category":
      return { ...state, category: action.payload };
    case "filter/userType":
      return { ...state, userType: action.payload };
    case "filter/status":
      return { ...state, status: action.payload };
    case "filterOption/state":
      return { ...state, stateOptions: action.payload };
    case "filterOption/city":
      return { ...state, cityOptions: action.payload };
    case "filterOption/property":
      return { ...state, propertyOptions: action.payload };
    case "filterOption/user":
      return { ...state, personOptions: action.payload };
    case "master/allIssue":
      return { ...state, allIssuesList: action.payload };
    case "master/filterIssue/date":
      return {
        ...state,
        filteredIssues: action.payload,
        monthTableData: {
          header: `${moment(state.startDate).format("DD-MMM-YYYY")}-${moment(
            state.endDate
          ).format("DD-MMM-YYYY")}`,
          data: action.payload,
        },
      };
    case "master/property":
      return {
        ...state,
        propertyList: action.payload,
        propertyOptions: action.payload,
      };
    case "master/state":
      return {
        ...state,
        stateList: action.payload,
        stateOptions: action.payload,
      };
    case "master/city":
      return {
        ...state,
        cityList: action.payload,
        cityOptions: action.payload,
      };
    case "master/category":
      return {
        ...state,
        categoryList: action.payload,
        categoryOptions: action.payload,
      };
    case "master/user":
      return {
        ...state,
        userList: action.payload,
        personOptions: action.payload,
      };
    case "error":
      return { ...state, isLoading: false };
    default:
      throw new Error("unknown action type");
  }
}
export default function Reports() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  });
  const navigate = useNavigate();
  const targetElementRef = useRef(null);
  const totalIssues = useSelector((state) => state?.modal?.issuesMasterData);
  // const [filteredIssues, setFilteredIssue] = useState([]);
  function scrollToTarget() {
    const element = document.getElementById("targetElement");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const {
    isLoading,
    isSelectLoading,
    // DATE-RANGE
    startDate,
    endDate,
    dateType,
    // REPORT BY
    isReportByMonth,
    isReportByProperty,
    isReportByCity,
    isReportByIssuecategory,
    // REPORT TYPE
    showSummaryReport,
    showDetailReport,
    // FILTERS

    stateObj,
    stateOptions,
    city,
    cityOptions,
    property,
    propertyOptions,
    person,
    personOptions,
    category,
    // categoryOptions,
    userType,
    // userTypOptions,
    status,

    // isStateSelected,
    // isCitySelected,
    // isPropertySelected,
    // isPersonSelected,
    // iscategorySelected,

    // cityTableData,
    // propertyTabledata,
    // personTableData,
    // categorytableData,
    // MASTER
    allIssuesList,
    filteredIssues,
    ReFilteredIssues,
    monthTableData,
    propertyList,
    stateList,
    categoryList,
    userList,
    cityList,
    // FILTER-REPORT
    filteredIssuesbyMonths,
    filteredIssuesbyProperty,
    filteredIssuesbyCategory,
    filteredIssuesbyCity,
  } = state;
  const modifiedarr = monthTableData?.data?.map((item) => {
    const assigendArray = item.issue_action.filter(
      (issue) => issue.issue_status === 2
    );
    let timeString = calculateActualTimeDifference(
      new Date(item?.created_at),
      new Date(assigendArray[0]?.created_at),
      item?.issue_category?.mg_start_time,
      item?.issue_category?.mg_end_time
    );
    timeString = assigendArray[1]
      ? calculateActualTimeDifference(
          new Date(item?.created_at),
          new Date(assigendArray[0]?.created_at),
          item?.issue_category?.mg_start_time,
          item?.issue_category?.mg_end_time
        )
      : timeString;
    const [actualDays, actualHours, actualMins] = timeString.split(":");
    const { isItDelay, delayTime } = item?.timeStringResponse;
    const [days, hours, minutes] = delayTime.split(":");
    const res =
      item.responseSLAStatic.isHours === 1
        ? `${
            item?.responseSLAStatic?.hours == 0
              ? ""
              : `${item?.responseSLAStatic?.hours} ${
                  item?.responseSLAStatic?.hours > 1 ? "Hrs" : "Hr"
                }`
          } ${
            item?.responseSLAStatic?.minutes == 0
              ? ""
              : `${item?.responseSLAStatic?.minutes} ${
                  item?.responseSLAStatic?.minutes > 1 ? "mins" : "min"
                }`
          }`
        : `${
            item?.responseSLAStatic?.period == 0
              ? ""
              : `${item?.responseSLAStatic?.period} ${
                  item?.responseSLAStatic?.period > 1 ? "Days" : "Days"
                }`
          }`;
    const responsetime =
      assigendArray.length > 0
        ? `${
            actualDays == 0
              ? ""
              : ` ${actualDays} ${actualDays > 1 ? "Days" : "Day"}`
          } ${
            actualHours == 0
              ? ""
              : `${actualHours} ${actualHours > 1 ? "Hrs " : "Hr "}`
          }${actualMins} ${actualMins > 1 ? "Mins" : "Min"} (${res})`
        : `${days == 0 ? "" : ` ${days} ${days > 1 ? "Days" : "Day"}`} ${
            actualHours == 0 ? "" : `${hours} ${hours > 1 ? "Hrs " : "Hr "}`
          }${minutes} ${minutes > 1 ? "Mins" : "Min"} (${res})`;
    ///////////////////////////////////////////////////////////////////////////////////////////
    // Resolution
    const responsedArray = item.issue_action.filter(
      (issue) => issue.issue_status === 4
    );
    let timeStringres = calculateActualTimeDifference(
      new Date(item?.created_at),
      new Date(responsedArray[0]?.created_at),
      item?.issue_category?.mg_start_time,
      item?.issue_category?.mg_end_time
    );
    timeStringres = responsedArray[1]
      ? calculateActualTimeDifference(
          new Date(item?.created_at),
          new Date(responsedArray[0]?.created_at),
          item?.issue_category?.mg_start_time,
          item?.issue_category?.mg_end_time
        )
      : timeStringres;
    //  const [actualDays, actualHours, actualMins] = timeString.split(":");
    //  const { isItDelay, delayTime } = item?.timeStringResponse;
    //  const [days, hours, minutes] = delayTime.split(":");
    const [AD, AH, AM] = timeStringres.split(":");

    const delayandtime = item?.timeStringResolution;
    const [d, h, m] = delayandtime?.delayTime.split(":");

    const resolve =
      item.resolutionSLAStatic.isHours === 1
        ? `${
            item?.resolutionSLAStatic?.hours == 0
              ? ""
              : `${item?.resolutionSLAStatic?.hours} ${
                  item?.resolutionSLAStatic?.hours > 1 ? "Hrs" : "Hr"
                }`
          } ${
            item?.resolutionSLAStatic?.minutes == 0
              ? ""
              : `${item?.resolutionSLAStatic?.minutes} ${
                  item?.resolutionSLAStatic?.minutes > 1 ? "mins" : "min"
                }`
          }`
        : `${
            item?.resolutionSLAStatic?.period == 0
              ? ""
              : `${item?.resolutionSLAStatic?.period} ${
                  item?.resolutionSLAStatic?.period > 1 ? "Days" : "Days"
                }`
          }`;
    const resolvetime = item?.isIssueAssigned
      ? responsedArray.length > 0
        ? `${AD == 0 ? "" : ` ${AD} ${AD > 1 ? "Days" : "Day"}`} ${
            AH == 0 ? "" : `${AH} ${AH > 1 ? "Hrs " : "Hr "}`
          }${AM} ${AM > 1 ? "Mins" : "Min"} (${resolve})`
        : `${d == 0 ? "" : ` ${d} ${d > 1 ? "Days" : "Day"}`} ${
            h == 0 ? "" : `${h} ${h > 1 ? "Hrs " : "Hr "}`
          }${m} ${m > 1 ? "Mins" : "Min"} (${resolve})`
      : "NA";

    return {
      TicketNo: item.issue_code,
      ResponseTime: responsetime,
      ResolutionTime: resolvetime,
      ReportDate: moment(new Date(item?.created_at)).format("DD-MMM-YYYY"),
      ReportedBy: item.user ? item.user.name : null,
      center: item?.property.property_name,
      IssueReported: item?.issue_title,
      IssueCategory: item?.issue_category?.issue_category_name,
      Status: issueActionStatusName?.(item?.status),
      UpdatedDate: moment(new Date(item?.updated_at)).format("DD-MMM-YYYY"),
      Assigned: item?.property?.contact_person,
    };
  });
  const KEYS = Object.keys?.(filteredIssuesbyMonths);
  const VALUES = Object.values?.(filteredIssuesbyMonths);
  const KEYSCity = Object.keys?.(filteredIssuesbyCity);
  const VALUESCity = Object.values?.(filteredIssuesbyCity);
  const KEYSProperty = Object.keys?.(filteredIssuesbyProperty);
  const VALUESProperty = Object.values?.(filteredIssuesbyProperty);
  const KEYSCategory = Object.keys?.(filteredIssuesbyCategory);
  const VALUESCategory = Object.values?.(filteredIssuesbyCategory);
  console.log(KEYSCity, "keycity");
  const obj = VALUES?.map((filteredIssues, i) => {
    return {
      Month: moment(KEYS[i]).format("MMMM-YYYY"),
      "No of issues raised": filteredIssues?.length,
      Open: filteredIssues.filter((issue) => issue.status === ISSUE_UNASSIGNED)
        ?.length,
      "Assign Staff": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_ASSIGNED
      )?.length,
      "Work Started": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_WORK_STARTED
      )?.length,
      Resolved: filteredIssues.filter(
        (issue) => issue.status === ISSUE_RESOLVED
      )?.length,
      Closed: filteredIssues.filter((issue) => issue.status === ISSUE_CLOSED)
        ?.length,
      Delayed: filteredIssues.filter((issue) => issue.isDelayed === true)
        ?.length,
    };
  });
  const objCity = VALUESCity?.map((filteredIssues, i) => {
    return {
      City: KEYSCity[i],
      "No of issues raised": filteredIssues?.length,
      Open: filteredIssues.filter((issue) => issue.status === ISSUE_UNASSIGNED)
        ?.length,
      "Assign Staff": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_ASSIGNED
      )?.length,
      "Work Started": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_WORK_STARTED
      )?.length,
      Resolved: filteredIssues.filter(
        (issue) => issue.status === ISSUE_RESOLVED
      )?.length,
      Closed: filteredIssues.filter((issue) => issue.status === ISSUE_CLOSED)
        ?.length,
      Delayed: filteredIssues.filter((issue) => issue.isDelayed === true)
        ?.length,
    };
  });
  const objProperty = VALUESProperty?.map((filteredIssues, i) => {
    return {
      Property: KEYSProperty[i],
      "No of issues raised": filteredIssues?.length,
      Open: filteredIssues.filter((issue) => issue.status === ISSUE_UNASSIGNED)
        ?.length,
      "Assign Staff": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_ASSIGNED
      )?.length,
      "Work Started": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_WORK_STARTED
      )?.length,
      Resolved: filteredIssues.filter(
        (issue) => issue.status === ISSUE_RESOLVED
      )?.length,
      Closed: filteredIssues.filter((issue) => issue.status === ISSUE_CLOSED)
        ?.length,
      Delayed: filteredIssues.filter((issue) => issue.isDelayed === true)
        ?.length,
    };
  });
  const objCategory = VALUESCategory?.map((filteredIssues, i) => {
    return {
      Category: KEYSCategory[i],
      "No of issues raised": filteredIssues?.length,
      Open: filteredIssues.filter((issue) => issue.status === ISSUE_UNASSIGNED)
        ?.length,
      "Assign Staff": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_ASSIGNED
      )?.length,
      "Work Started": filteredIssues?.filter(
        (issue) => issue.status === ISSUE_WORK_STARTED
      )?.length,
      Resolved: filteredIssues.filter(
        (issue) => issue.status === ISSUE_RESOLVED
      )?.length,
      Closed: filteredIssues.filter((issue) => issue.status === ISSUE_CLOSED)
        ?.length,
      Delayed: filteredIssues.filter((issue) => issue.isDelayed === true)
        ?.length,
    };
  });

  // const [monthlyData, setMonthlyData] = useState({});
  function handlePropertyChange(event, newValue) {
    const id = newValue.id;
    const tempIssueList = filteredIssues?.filter(
      (issue) => issue.property_id === id
    );
    dispatch({
      type: "filter/property",
      payload: newValue,
    });
  }
  function handleCityChange(event, newValue) {
    const id = newValue.id;
    dispatch({
      type: "filter/city",
      payload: newValue,
    });
  }
  function handleStateChange(event, newValue) {
    // if (!newValue) return;
    // const [name] = event.target.id?.split("-");
    // const value = newValue[name];
    const id = newValue?.id;
    if (id) {
      dispatch({ type: "selectLoading", payload: false });
      const tempIssueList = filteredIssues?.filter(
        (issue) => issue.property.state_id === id
      );
      API.getMethod(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            const cityList = res.data.data;

            // filter with issues cities only
            const uniqueCityIds = [];
            const filteredCity = filteredIssues.filter((issue) => {
              if (!uniqueCityIds.includes(issue.property.city_id)) {
                uniqueCityIds.push(issue.property.city_id);
                return true;
              }
              return false;
            });
            const filteredCities = cityList.filter((cityEle) =>
              uniqueCityIds.includes(cityEle.id)
            );
            dispatch({ type: "selectLoading", payload: true });
            dispatch({
              type: "filter/state",
              payload: newValue,
            });
            dispatch({ type: "master/city", payload: filteredCities });
          }
        })
        .catch((err) => console.error(err));
    } else {
      dispatch({
        type: "filter/state",
        payload: newValue,
      });
      dispatch({ type: "filter/city", payload: [] });
      dispatch({ type: "master/city", payload: [] });
    }
    //   if (name === "name") {
    // }
  }
  // useEffect(() => {
  //   // Parse the date strings and group by month
  //   console.log("runneed");
  //   const groupedData = filteredIssues.reduce((acc, item) => {
  //     const date = new Date(item.created_at);
  //     const year = date.getFullYear();
  //     const month = date.getMonth() + 1;
  //     const key = `${year}-${date.toLocaleString("en-US", { month: "short" })}`;

  //     if (!acc[key]) {
  //       acc[key] = [];
  //     }

  //     acc[key].push(item);

  //     return acc;
  //   }, {});

  //   setMonthlyData(groupedData);
  //   dispatch({ type: "filterMonthsData", payload: groupedData });
  // }, [filteredIssues]);
  console.log(objCity, "onjciorty");
  const downloadasxl = () => {
    // Create a worksheet
    const keys = Object.keys(modifiedarr[0]);

    const workSheet = XLSX.utils.json_to_sheet(modifiedarr, {
      header: keys,
      origin: "A3",
    });
    const monthsheet = XLSX.utils.json_to_sheet(obj);
    const citySheet = XLSX.utils.json_to_sheet(objCity);
    const propertySheet = XLSX.utils.json_to_sheet(objProperty);
    const categorySheet = XLSX.utils.json_to_sheet(objCategory);
    // console.log(keys);
    // workSheet["!ref"] = "A3:" + workSheet["!ref"].split(":")[1];
    // console.log(workSheet["!ref"]);
    const workBook = XLSX.utils.book_new();
    isReportByMonth &&
      XLSX.utils.book_append_sheet(
        workBook,
        monthsheet,
        "SummaryReport(Month)"
      );
    isReportByCity &&
      XLSX.utils.book_append_sheet(
        workBook,
        citySheet,
        // "SummaryData(ReportByCity)"
        "SummaryReport(City)"
      );
    isReportByProperty &&
      XLSX.utils.book_append_sheet(
        workBook,
        propertySheet,
        "SummaryReport(Property)"
      );
    isReportByIssuecategory &&
      XLSX.utils.book_append_sheet(
        workBook,
        categorySheet,
        "SummaryReport(Category)"
      );
    const headerText = [
      // ["This is some introductory text."],
      [`${monthTableData.header}`],
      [], // Add an empty row for spacing
    ];
    XLSX.utils.sheet_add_aoa(workSheet, headerText, {
      origin: `A1`,
    });

    XLSX.utils.book_append_sheet(workBook, workSheet, "Detail Report");
    //buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //binary
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //download
    XLSX.writeFile(
      workBook,
      `Report(${moment(startDate).format("DD-MMM-YYYY")} - ${moment(
        endDate
      ).format("DD-MMM-YYYY")}).xlsx`
    );
  };
  const downloadasxlforsummary = () => {
    const monthsheet = XLSX.utils.json_to_sheet(obj);
    const citySheet = XLSX.utils.json_to_sheet(objCity);
    const propertySheet = XLSX.utils.json_to_sheet(objProperty);
    const categorySheet = XLSX.utils.json_to_sheet(objCategory);

    const workBook = XLSX.utils.book_new();
    isReportByMonth &&
      XLSX.utils.book_append_sheet(
        workBook,
        monthsheet,
        "SummaryReport(Month)"
      );
    isReportByCity &&
      XLSX.utils.book_append_sheet(
        workBook,
        citySheet,
        // "SummaryData(ReportByCity)"
        "SummaryReport(City)"
      );
    isReportByProperty &&
      XLSX.utils.book_append_sheet(
        workBook,
        propertySheet,
        "SummaryReport(Property)"
      );
    isReportByIssuecategory &&
      XLSX.utils.book_append_sheet(
        workBook,
        categorySheet,
        "SummaryReport(Category)"
      );

    //buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //binary
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //download
    XLSX.writeFile(
      workBook,
      `Report(${moment(startDate).format("DD-MMM-YYYY")} - ${moment(
        endDate
      ).format("DD-MMM-YYYY")}).xlsx`
    );
  };
  const ResetData = () => {
    if (startDate) {
      // TRY TO OVERWRITE ENDDATE FOR FILTERING INCLUDES SELECTED END DATE ALSO
      const date = new Date(endDate);
      const nextDay = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1,
        0,
        0,
        0
      );
      const filteredIssue = allIssuesList.filter(
        (issue) =>
          new Date(issue.created_at) >= new Date(startDate) &&
          new Date(issue.created_at) < nextDay
      );
      dispatch({ type: "form/Reset", payload: filteredIssue });
    }
  };
  // SET ALL ISSUES IN STATE
  useEffect(function () {
    let propertyData = [];
    let stateData = [];
    let categoryData = [];
    let userData = [];

    dispatch({ type: "master/allIssue", payload: totalIssues });
    dispatch({ type: "fetchData" });
    const controller = new AbortController();
    Promise.allSettled([
      API.getMethod(PROPERTY_LIST, controller.signal),
      API.getMethod(`${STATELIST}/101`, controller.signal),
      API.getMethod(ISSUE_CATEGORY_LIST, controller.signal),
      API.getMethod(USER_LIST, controller.signal),
    ])
      .then((res) => {
        res.forEach((ele, i, arr) => {
          const { status, value } = ele;
          if (i === 0) {
            // PROPERTY_LIST
            if (value.data.status) {
              // propertyData = value.data.data;
              propertyData = [
                ...value.data.data,
                { property_name: "select", id: null },
              ];
            }
          } else if (i === 1) {
            // STATELIST
            if (value.data.status) {
              stateData = value.data.data;
            }
          } else if (i === 2) {
            // ISSUE_CATEGORY_LIST
            if (value.data.status) {
              // console.log('category',value.data.data);
              // const tempCategoryData = value.data.data.map((cat) => ({id:cat.id,name:cat.issue_category_name}));
              // categoryData = tempCategoryData;
              categoryData = value.data.data;
            }
          } else if (i === 3) {
            // USER_LIST
            if (value.data.status) {
              userData = value.data.data;
            }
          } else {
            throw new Error(`i`);
          }
          // END OF FOR-EACH LOOP
        });
        // propertyData.append({property_name:'select',id:'BRHDUMMY'});
        dispatch({ type: "master/property", payload: propertyData });
        dispatch({ type: "master/state", payload: stateData });
        dispatch({ type: "master/category", payload: categoryData });
        dispatch({ type: "master/user", payload: userData });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(dispatch({ type: "error" }));
    return () => {
      controller.abort();
    };
  }, []);
  // FILTER ISSUES BASED ON STARTDATE AND DATE
  useEffect(
    function () {
      if (startDate && endDate) {
        // TRY TO OVERWRITE ENDDATE FOR FILTERING INCLUDES SELECTED END DATE ALSO
        const date = new Date(endDate);
        const nextDay =
          dateType === DATE_TYPE_MONTH
            ? new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0)
            : new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() + 1,
                0,
                0,
                0
              );
        const filteredIssue = allIssuesList.filter(
          (issue) =>
            new Date(issue.created_at) >= new Date(startDate) &&
            new Date(issue.created_at) < nextDay
        );
        // console.log("property-1", propertyList);
        // console.log("state-1",stateList);
        // console.log('user',userList);
        // console.log('city',cityList);

        const uniquePropertyIds = [];
        const uniqueStateIds = [];
        const uniqueUserIds = [];
        const uniqueCityIds = [];
        // Filter issues to get unique properties
        const filteredProperty = filteredIssue.filter((issue) => {
          if (!uniquePropertyIds.includes(issue.property_id)) {
            uniquePropertyIds.push(issue.property_id);
            return true;
          }
          return false;
        });
        // Filter issues to get unique states
        const filteredState = filteredIssue.filter((issue) => {
          if (!uniqueStateIds.includes(issue.property.state_id)) {
            uniqueStateIds.push(issue.property.state_id);
            return true;
          }
          return false;
        });
        // Filter issues to get unique user
        const filteredUser = filteredIssue.filter((issue) => {
          if (!uniqueUserIds.includes(issue.user_id)) {
            uniqueUserIds.push(issue.user_id);
            return true;
          }
          return false;
        });
        // Filter issues to get unique city
        const filteredCity = filteredIssue.filter((issue) => {
          if (!uniqueCityIds.includes(issue.property.city_id)) {
            uniqueCityIds.push(issue.property.city_id);
            return true;
          }
          return false;
        });

        // console.log("filtered-property", filteredProperty);
        // console.log("filtered-state", filteredState);
        // console.log("filtered-user", filteredUser);

        // Filter properties based on unique property IDs
        const filteredProperties = propertyList.filter((propEle) =>
          uniquePropertyIds.includes(propEle.id)
        );
        // Filter properties based on unique state IDs
        const filteredStates = stateList.filter((stateEle) =>
          uniqueStateIds.includes(stateEle.id)
        );
        // Filter properties based on unique user IDs
        const filteredUsers = userList.filter((userEle) =>
          uniqueUserIds.includes(userEle.id)
        );
        // Filter properties based on unique city IDs
        const filteredCities = cityList.filter((cityEle) =>
          uniqueCityIds.includes(cityEle.id)
        );

        // console.log("property-2", filteredProperties);
        // console.log("state-2", filteredStates);
        // console.log("user-2", filteredUsers);
        // console.log("city-2", filteredCities);

        dispatch({ type: "master/filterIssue/date", payload: filteredIssue });
        dispatch({ type: "filterOption/state", payload: filteredStates });
        dispatch({ type: "filterOption/city", payload: filteredCities });
        dispatch({
          type: "filterOption/property",
          payload: filteredProperties,
        });
        dispatch({ type: "filterOption/user", payload: filteredUsers });
      }
    },
    [startDate, endDate, allIssuesList, dateType]
  );
  console.log(filteredIssuesbyCity, "city");
  // MAKING MONTH/PROPERTY/CITY/CATEGORY WISE ARRAY FROM FILTERED ISSUES
  useEffect(() => {
    if (!startDate && !endDate) return;
    // Parse the date strings and group by month
    // const groupedData = allIssuesList.reduce((acc, item) => {
    // RESET DATE WISE FILTER ISSUES TO SECTION WISE FILTERED ISSUES
    const filteredIssues = ReFilteredIssues;
    const monthData = filteredIssues.reduce((acc, item) => {
      const date = new Date(item.created_at);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      // const key = `${year}-${month}`;
      const key = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
    // setMonthlyData(monthData);
    // CREATING PROPERTY WISE REPORT
    const propertyData = filteredIssues.reduce((propertyArr, issue) => {
      const propertyID = issue?.property?.property_name;
      if (!propertyArr[propertyID]) {
        propertyArr[propertyID] = [];
      }
      propertyArr[propertyID].push(issue);
      return propertyArr;
    }, {});
    // CREATING CATEGORY WISE REPORT
    const categoryData = filteredIssues.reduce((categoryArr, issue) => {
      const categoryID = issue?.issue_category?.issue_category_name;
      if (!categoryArr[categoryID]) {
        categoryArr[categoryID] = [];
      }
      categoryArr[categoryID].push(issue);
      return categoryArr;
    }, {});
    // CREATING CITY WISE REPORT
    const cityData = filteredIssues.reduce((cityArr, issue) => {
      console.log(issue?.property, cityArr, "property");
      const cityID = issue?.property?.city?.name;
      if (!cityArr[cityID]) {
        cityArr[cityID] = [];
      }
      cityArr[cityID].push(issue);
      return cityArr;
    }, {});
    console.log(cityData, "cityDat");
    dispatch({
      type: "filterIssue/summaryReport",
      payload: monthData,
      purpose: "month",
    });
    dispatch({
      type: "filterIssue/summaryReport",
      payload: propertyData,
      purpose: "property",
    });
    dispatch({
      type: "filterIssue/summaryReport",
      payload: categoryData,
      purpose: "category",
    });
    dispatch({
      type: "filterIssue/summaryReport",
      payload: cityData,
      purpose: "city",
    });
    dispatch({
      type: "form/reportBy",
      payload: true,
      name: "isReportByMonth",
    });
    dispatch({
      type: "filterIssue/tableData",
      payload: {
        header: `${moment(startDate).format("DD-MMM-YYYY")} - ${moment(
          endDate
        ).format("DD-MMM-YYYY")}`,
        data: filteredIssues,
      },
    });
  }, [ReFilteredIssues, startDate, endDate]);
  // FILTERING ISSUES
  useEffect(
    function () {
      if (filteredIssues) {
        let tempFilteredIssues = filteredIssues;
        if (stateObj) {
          let stateId = stateObj.id;
          // console.log("state-1", stateObj, filteredIssues);
          tempFilteredIssues = filteredIssues.filter(
            (issue) => issue.property.state_id === stateId
          );
          // console.log("state-2", tempFilteredIssues);
        }
        if (property.length > 0) {
          const propertyIdList = property.map((prop) => prop.id);
          const isPropertyIncluded = (id) =>
            propertyIdList.some((propId) => propId === id);
          // console.log("property-1", property, tempFilteredIssues);
          const propertyFiltered = tempFilteredIssues.flatMap((issue) =>
            isPropertyIncluded(issue.property_id) ? issue : []
          );
          tempFilteredIssues = propertyFiltered;
          // console.log("property-2", tempFilteredIssues);
        }
        if (city.length > 0) {
          const cityIdList = city.map((city) => city.id);
          const isCityIncluded = (id) =>
            cityIdList.some((cityId) => cityId === id);
          // console.log("city-1", city, tempFilteredIssues);
          const cityFiltered = tempFilteredIssues.flatMap((issue) =>
            isCityIncluded(issue.property.city_id) ? issue : []
          );
          tempFilteredIssues = cityFiltered;
          // console.log("city-2", tempFilteredIssues);
        }
        if (person) {
          // console.log('user',userList);
          // console.log("person-1", person, tempFilteredIssues);
          tempFilteredIssues = tempFilteredIssues.filter(
            (issue) => issue.user_id === person.id
          );
          // console.log("person-2", tempFilteredIssues);
        }
        if (category) {
          // console.log("category-1", category, tempFilteredIssues);
          tempFilteredIssues = tempFilteredIssues.filter(
            (issue) => issue.issue_category_id === category.id
          );
          // console.log("category-2", tempFilteredIssues);
        }
        if (userType) {
          // console.log("userType-1", userType, tempFilteredIssues);
          tempFilteredIssues = tempFilteredIssues.filter(
            (issue) => issue.role_id === userType.value
          );
          // console.log("userType-2", tempFilteredIssues);
        }
        if (status) {
          // console.log("status-1", status, tempFilteredIssues);
          if (status.value !== 10) {
            tempFilteredIssues = tempFilteredIssues.filter(
              (issue) => issue.status === status.value
            );
          } else {
            tempFilteredIssues = tempFilteredIssues.filter(
              (issue) => issue.isDelayed
            );
          }
          // console.log("status-2", tempFilteredIssues);
        }
        // console.log("ref", tempFilteredIssues);
        dispatch({ type: "filterIssue/Refilter", payload: tempFilteredIssues });
      }
    },
    [
      filteredIssues,
      stateObj,
      property,
      city,
      person,
      category,
      userType,
      status,
    ]
  );
  const sortedIssuesbymonths = Object.keys(filteredIssuesbyMonths);
  const columns = useMemo(
    () => [
      //ID
      // {
      //   accessorKey: "isDelayed",
      //   header: "",
      //   size: "10",
      //   enableColumnFilter: false,
      //   Cell: ({ cell }) => {
      //     return (
      //       <>
      //         <BsCircleFill
      //           size="1.5em"
      //           style={{
      //             color: cell?.row?.original?.isDelayed
      //               ? "red"
      //               : Number(cell?.row?.original?.status) === 1 ||
      //                 Number(cell?.row?.original?.status) === 5
      //               ? "dodgerblue"
      //               : Number(cell?.row?.original?.status) === 2 ||
      //                 Number(cell?.row?.original?.status) === 3
      //               ? "orange"
      //               : Number(cell?.row?.original?.status) === 4 ||
      //                 Number(cell?.row?.original?.status) === 6
      //               ? "green"
      //               : cell?.row?.original?.isDelayed ?? "red",
      //           }}
      //         />
      //       </>
      //     );
      //   },
      // },
      //ISSUE CODE
      {
        accessorKey: "issue_code",
        header: "Ticket No",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      //SLA
      {
        accessorKey: "issue_category_id",
        header: "Response Time",
        enableColumnFilter: false,

        size: "10",

        Cell: ({ cell }) => {
          // let { delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes } = cell?.row?.original.delayResponse;
          // console.log(delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes);
          const rowData = cell?.row?.original;
          const assigendArray = rowData.issue_action.filter(
            (issue) => issue.issue_status === 2
          );
          let timeString = calculateActualTimeDifference(
            new Date(rowData?.created_at),
            new Date(assigendArray[0]?.created_at),
            rowData?.issue_category?.mg_start_time,
            rowData?.issue_category?.mg_end_time
          );
          timeString = assigendArray[1]
            ? calculateActualTimeDifference(
                new Date(rowData?.created_at),
                new Date(assigendArray[0]?.created_at),
                rowData?.issue_category?.mg_start_time,
                rowData?.issue_category?.mg_end_time
              )
            : timeString;
          const [actualDays, actualHours, actualMins] = timeString.split(":");
          const { isItDelay, delayTime } = rowData?.timeStringResponse;
          const [days, hours, minutes] = delayTime.split(":");
          return (
            <>
              {assigendArray?.length > 0 ? (
                <>
                  <div>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        <span
                          style={{
                            display: actualDays == 0 ? "none" : "inline-block",
                          }}
                        >
                          {actualDays} {actualDays > 1 ? "Days" : "Day"}
                        </span>
                        <span
                          style={{
                            display: actualHours == 0 ? "none" : "block",
                          }}
                        >
                          {actualHours} {actualHours > 1 ? "Hrs" : "Hr"}
                        </span>{" "}
                        <span>
                          {/* style={{ display: minutes == 0 ? "none" : "block" }} */}
                          {actualMins} {actualMins > 1 ? "Mins" : "Min"}
                        </span>
                      </span>
                      {/* <span style={{ display: "inline-block" }}>
                        <FlagIcon color="info" />
                      </span> */}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <span style={{ color: isItDelay ? "red" : "green" }}>
                    <span style={{ display: days == 0 ? "none" : "block" }}>
                      {days} {days > 1 ? "Days" : "Day"}
                    </span>
                    <span style={{ display: hours == 0 ? "none" : "block" }}>
                      {hours} {hours > 1 ? "Hrs" : "Hr"}
                    </span>{" "}
                    <span>
                      {/* style={{ display: minutes == 0 ? "none" : "block" }} */}
                      {minutes} {minutes > 1 ? "Mins" : "Min"}
                    </span>
                  </span>
                </>
              )}

              {rowData.responseSLAStatic.isHours === 1 ? (
                <>
                  <span style={{ display: "block", color: "orange" }}>
                    (
                    <span
                      style={{
                        display:
                          rowData?.responseSLAStatic?.hours == 0
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {rowData?.responseSLAStatic?.hours}
                      {rowData?.responseSLAStatic?.hours > 1
                        ? "Hrs"
                        : "Hr"}{" "}
                    </span>{" "}
                    <span
                      style={{
                        display:
                          rowData?.responseSLAStatic?.minutes == 0
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {rowData?.responseSLAStatic?.minutes}
                      {rowData?.responseSLAStatic?.minutes > 1
                        ? "Mins"
                        : "Min"}{" "}
                    </span>
                    )
                  </span>
                </>
              ) : (
                <>
                  <span style={{ display: "block", color: "orange" }}>
                    (
                    <span
                      style={{
                        display:
                          rowData?.responseSLAStatic?.period == 0
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {rowData?.responseSLAStatic?.period}
                      {rowData?.responseSLAStatic?.period > 1
                        ? "Days"
                        : "Day"}{" "}
                    </span>{" "}
                    )
                  </span>
                </>
              )}
            </>
          );
        },
      },
      //REMAININMG TIME
      {
        accessorKey: "resolved_by",
        header: "Resolution Time",
        size: "10",
        enableColumnFilter: false,

        Cell: ({ cell }) => {
          // let { delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes } = cell?.row?.original.delayResponse;
          // console.log(delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes);
          const rowData = cell?.row?.original;
          const responsedArray = rowData.issue_action.filter(
            (issue) => issue.issue_status === 4
          );
          let timeString = calculateActualTimeDifference(
            new Date(rowData?.created_at),
            new Date(responsedArray[0]?.created_at),
            rowData?.issue_category?.mg_start_time,
            rowData?.issue_category?.mg_end_time
          );
          timeString = responsedArray[1]
            ? calculateActualTimeDifference(
                new Date(rowData?.created_at),
                new Date(responsedArray[0]?.created_at),
                rowData?.issue_category?.mg_start_time,
                rowData?.issue_category?.mg_end_time
              )
            : timeString;
          const [actualDays, actualHours, actualMins] = timeString.split(":");
          const { isItDelay, delayTime } = rowData?.timeStringResolution;
          const [days, hours, minutes] = delayTime.split(":");
          return (
            <>
              <span>
                {rowData?.isIssueAssigned === true ? (
                  <>
                    {responsedArray.length > 0 ? (
                      <>
                        <div>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>
                              <span
                                style={{
                                  display:
                                    actualDays == 0 ? "none" : "inline-block",
                                }}
                              >
                                {actualDays} {actualDays > 1 ? "Days" : "Day"}
                              </span>
                              <span
                                style={{
                                  display: actualHours == 0 ? "none" : "block",
                                }}
                              >
                                {actualHours} {actualHours > 1 ? "Hrs" : "Hr"}
                              </span>{" "}
                              <span>
                                {/* style={{ display: minutes == 0 ? "none" : "block" }} */}
                                {actualMins} {actualMins > 1 ? "Mins" : "Min"}
                              </span>
                            </span>
                            {/* <span style={{ display: "inline-block" }}>
                              <FlagIcon color="info" />
                            </span> */}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <span style={{ color: isItDelay ? "red" : "green" }}>
                          <span
                            style={{ display: days == 0 ? "none" : "block" }}
                          >
                            {days} {days > 1 ? "Days" : "Day"}
                          </span>
                          <span
                            style={{ display: hours == 0 ? "none" : "block" }}
                          >
                            {hours} {hours > 1 ? "Hrs" : "Hr"}
                          </span>{" "}
                          <span>
                            {minutes} {minutes > 1 ? "Mins" : "Min"}
                          </span>
                        </span>
                      </>
                    )}

                    {rowData.resolutionSLAStatic.isHours === 1 ? (
                      <>
                        <span style={{ display: "block", color: "orange" }}>
                          (
                          <span
                            style={{
                              display:
                                rowData?.resolutionSLAStatic?.hours == 0
                                  ? "none"
                                  : "inline-block",
                            }}
                          >
                            {rowData?.resolutionSLAStatic?.hours}
                            {rowData?.resolutionSLAStatic?.hours > 1
                              ? "Hrs"
                              : "Hr"}{" "}
                          </span>{" "}
                          <span
                            style={{
                              display:
                                rowData?.resolutionSLAStatic?.minutes == 0
                                  ? "none"
                                  : "inline-block",
                            }}
                          >
                            {rowData?.resolutionSLAStatic?.minutes}
                            {rowData?.resolutionSLAStatic?.minutes > 1
                              ? "Mins"
                              : "Min"}{" "}
                          </span>
                          )
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ display: "block", color: "orange" }}>
                          (
                          <span
                            style={{
                              display:
                                rowData?.resolutionSLAStatic?.period == 0
                                  ? "none"
                                  : "inline-block",
                            }}
                          >
                            {rowData?.resolutionSLAStatic?.period}
                            {rowData?.resolutionSLAStatic?.period > 1
                              ? "Days"
                              : "Day"}{" "}
                          </span>{" "}
                          )
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <span>NA</span>
                  </>
                )}
              </span>
            </>
          );
        },
      },
      // Reported By
      {
        accessorKey: "user.name ",
        header: "Reported By",
        // enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          const corporate = cell?.row?.original?.corporate;
          const user = cell?.row?.original?.user;
          const roleId = cell?.row?.original?.role_id;
          return (
            <>
              <span className="text-wrap">
                {roleId == 6 && user === null ? corporate?.name : user?.name}
              </span>
              <br />
              <span style={{ fontSize: "8px", color: "orange" }}>
                {roleId == 5
                  ? "(Member)"
                  : roleId == 10
                  ? "(MG-Employee)"
                  : roleId == 6
                  ? "(Corporate)"
                  : "(Employee)"}
              </span>
            </>
          );
        },
      },
      // Report Date
      {
        accessorKey: "created_at",
        header: "Report Date",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return (
            <>
              <span>{moment(cell.getValue()).format("DD-MMM-YYYY")}</span>
              <br />
              <span>{moment(cell.getValue()).format("hh:mm A")}</span>
            </>
          );
        },
      },
      // Center
      {
        accessorKey: "property.property_name",
        header: "Center",
        // enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
      // Issue Reported
      {
        accessorKey: "issue_title",
        header: "Issue Reported",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
      // Issue Category
      {
        accessorKey: "issue_category.issue_category_name",
        header: "Issue Category",
        size: "30",
        // filterVariant: "select",
        // filterSelectOptions: issueCategoryList,
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
      // status
      {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return (
            <span key={cell?.row?.original?.id} className="text-wrap">
              {issueActionStatusName(cell.getValue())}
            </span>
          );
        },
      },
      // Updated Date
      {
        accessorKey: "updated_at",
        header: "Updated Date",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return (
            <>
              <span>{moment(cell.getValue()).format("DD-MMM-YYYY")}</span>
              <br />
              <span>{moment(cell.getValue()).format("hh:mm A")}</span>
            </>
          );
        },
      },
      // Assigned
      {
        accessorKey: "property.contact_person",
        header: "Assigned",
        enableColumnFilter: false,

        size: "30",
        fontSize: "11px",
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
    ],
    []
  );
  const cityColumns = useMemo(
    () => [
      {
        accessorKey: "city",
        header: "city",
        size: 100,
      },
      {
        accessorKey: "No of issues raised",
        header: "No of issues raised",
        size: 100,
      },
      {
        accessorKey: "open",
        header: "open",
        size: 100,
      },
      {
        accessorKey: "Assign staff",
        header: "Assign staff",
        size: 50,
      },
      {
        accessorKey: "work started",
        header: "work started",
        size: 50,
      },
      {
        accessorKey: "resolved",
        header: "resolved",
        size: 50,
      },
      {
        accessorKey: "closed",
        header: "closed",
        size: 50,
      },

      {
        accessorKey: "delayed",
        header: "delayed",
        size: 50,
      },
    ],
    []
  );

  const shouldDisableDate = (date) => {
    if (startDate) {
      return date < startDate;
    }
    return false; // All dates are enabled if no start date is selected
  };

  // RETURN TO HOMEPAGE WHEN ISSUESLIST IS EMPTY NOTE:IT WILL HAPPEN WHEN DIRECTLY NAVIGATE TO REPORTS
  if (totalIssues?.length === 0) return navigate("/");

  if (isLoading)
    return (
      <Container>
        <Stack
          justifyContent="center"
          alignItems="center"
          // sx={{ margin: "20% 0" }}
          minHeight="500px"
        >
          <Loader color="#fab005" size={150} />
        </Stack>
      </Container>
    );

  return (
    <>
      <Navbar />
      <Paper className={styles.reportsPage}>
        <Container>
          <Box sx={{ margin: "16px 0", padding: "16px " }}>
            <Grid
              container
              alignItems="center"
              rowSpacing={2.5}
              columnSpacing={{ xs: 2, sm: 2, md: 2 }}
            >
              <Grid item sm={12} md={3}>
                {/* <span>M | C | Y</span> */}
                <Grid item xs={12} md={12}>
                  <FormControl>
                    {/* <FormLabel id="date-radio-buttons-group">Date</FormLabel> */}
                    <RadioGroup
                      row
                      aria-labelledby="date-radio-buttons-group"
                      name="dataType"
                      value={dateType}
                      onChange={(e) =>
                        dispatch({
                          type: "form/dateType",
                          payload: Number(e.target.value),
                        })
                      }
                    >
                      <FormControlLabel
                        value={DATE_TYPE_MONTH}
                        control={<Radio />}
                        label="Month"
                      />
                      <FormControlLabel
                        value={DATE_TYPE_CALENDAR}
                        control={<Radio />}
                        label="Calendar"
                      />
                      <FormControlLabel
                        value={DATE_TYPE_YEAR}
                        control={<Radio />}
                        label="Year"
                      />
                      {/* <FormControlLabel
                        value={DATE_TYPE_YEAR}
                        control={<Radio />}
                        label="Year"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {/* startDate */}
              <Grid item sm={12} md={3}>
                <DateInputBRH
                  label={`${
                    dateType === DATE_TYPE_MONTH
                      ? "Select Month"
                      : dateType === DATE_TYPE_YEAR
                      ? "Select Year"
                      : "Start Date"
                  }`}
                  views={
                    dateType === DATE_TYPE_MONTH
                      ? ["month", "year"]
                      : dateType === DATE_TYPE_YEAR
                      ? ["year"]
                      : ["year", "month", "day"]
                  }
                  format={
                    dateType === DATE_TYPE_MONTH
                      ? "MMM - yyyy"
                      : dateType === DATE_TYPE_YEAR
                      ? "yyyy"
                      : "dd - MM - yyyy"
                  }
                  name={"startDate"}
                  key={new Date().getTime()}
                  value={startDate}
                  handleChange={(value) => {
                    const tempDate = new Date(value);
                    const startYear = new Date(value);

                    const prevDay = new Date(
                      tempDate.getFullYear(),
                      tempDate.getMonth() + 1,
                      0,
                      0,
                      0,
                      0
                    );

                    const lastDayofYear = (year) => {
                      const nextYear = year + 1;
                      const endOfYear = new Date(nextYear, 0, 1, 0, 0, 0);

                      endOfYear.setDate(endOfYear.getDate() - 1);

                      return endOfYear;
                    };

                    if (dateType === DATE_TYPE_MONTH) {
                      dispatch({
                        type: "form/startDate",
                        payload: new Date(
                          tempDate.getFullYear(),
                          tempDate.getMonth(),
                          1,
                          0,
                          0,
                          0
                        ),
                      });
                      dispatch({
                        type: "form/endDate",
                        payload: new Date(prevDay),
                        // payload:value
                      });
                    }
                    if (dateType === DATE_TYPE_YEAR) {
                      dispatch({
                        type: "form/startDate",
                        payload: new Date(
                          startYear.getFullYear(),
                          0,
                          1,
                          0,
                          0,
                          0
                        ),
                      });
                      dispatch({
                        type: "form/endDate",
                        payload: lastDayofYear(startYear.getFullYear()),
                        // payload:value
                      });
                    }
                    if (dateType === DATE_TYPE_CALENDAR) {
                      dispatch({
                        type: "form/startDate",
                        payload: new Date(value),
                      });
                    }
                  }}
                />
              </Grid>
              {/* endDate */}
              <Grid
                item
                sm={12}
                md={3}
                sx={{
                  display:
                    dateType === DATE_TYPE_MONTH || dateType === DATE_TYPE_YEAR
                      ? "none"
                      : "block",
                }}
              >
                <DateInputBRH
                  label={`End  ${
                    dateType === DATE_TYPE_MONTH ? "Month" : "Date"
                  }`}
                  views={
                    dateType === DATE_TYPE_MONTH
                      ? ["month", "year"]
                      : ["year", "month", "day"]
                  }
                  format={
                    dateType === DATE_TYPE_MONTH
                      ? "MMM - yyyy"
                      : "dd - MM - yyyy"
                  }
                  name={"endDate"}
                  key={new Date().getTime()}
                  value={endDate}
                  // handleChange={(value) => {
                  //   const tempDate = new Date(value);
                  //   const nextDay = new Date(
                  //     tempDate.getFullYear(),
                  //     tempDate.getMonth() + 1,
                  //     1,
                  //     0,
                  //     0,
                  //     0
                  //   );
                  //   dispatch({
                  //     type: "form/endDate",
                  //     // payload: nextDay,
                  //     payload: value,
                  //   });
                  // }}
                  handleChange={(value) => {
                    const tempDate = new Date(value);

                    // Ensure that the selected end date does not exceed 12 years from the start date
                    const maxEndDate = new Date(
                      startDate.getFullYear(),
                      startDate.getMonth() + 12,
                      1,
                      0,
                      0,
                      0
                    );

                    if (tempDate > maxEndDate) {
                      dispatch({
                        type: "form/endDate",
                        payload: maxEndDate,
                      });
                    } else {
                      dispatch({
                        type: "form/endDate",
                        payload: tempDate,
                      });
                    }
                  }}
                  shouldDisableDate={shouldDisableDate}
                />
              </Grid>
              {/* divider-REPORT TYPE */}
              {/* <Grid item sm={12} md={12}>
                <TextDivider>Report By</TextDivider>
              </Grid> */}
              {/* <Grid item sm={6} md={3}>
                <CheckBoxInputBRH
                  label="Summary Report"
                  value={showSummaryReport}
                  name="showSummaryReport"
                  handleChange={(e) =>
                    dispatch({
                      type: "form/reportType",
                      name: e.target.name,
                      payload: e.target.checked,
                    })
                  }
                  size="large"
                />
              </Grid>
              <Grid item sm={6} md={3}>
                <CheckBoxInputBRH
                  label="Detail Report"
                  value={showDetailReport}
                  name="showDetailReport"
                  handleChange={(e) =>
                    dispatch({
                      type: "form/reportType",
                      name: e.target.name,
                      payload: e.target.checked,
                    })
                  }
                  size="large"
                />
              </Grid> */}
              {startDate && endDate && filteredIssues.length > 0 && (
                <>
                  {/* divider-filter  */}
                  <Grid item sm={12} md={12}>
                    <TextDivider>Filters</TextDivider>
                  </Grid>
                  {/* location */}
                  {/* <Grid item sm={12} md={3}/>
                <Typography>location</Typography>
              </Grid> */}
                  <Grid item sm={12} md={2}>
                    <Typography>Location</Typography>
                  </Grid>
                  {/* city */}
                  {/* <Grid item sm={12} md={1}>
                <CheckBoxInputBRH
                  label="City"
                  value={isCitySelected}
                  name="isCitySelected"
                  size="large"
                />
              </Grid> */}
                  <Grid item sm={12} md={5}>
                    <AutoCompleteInputBRH
                      label="State"
                      options={stateOptions}
                      value={stateObj}
                      name="name"
                      handleChange={handleStateChange}
                      isSelectDataloaded={isSelectLoading}
                    />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <AutoCompleteInputBRH
                      label="City"
                      options={cityOptions}
                      value={city}
                      name="name"
                      handleChange={handleCityChange}
                      multiple
                      filterSelectedOptions
                    />
                  </Grid>
                  {/* property */}
                  {/* <Grid item sm={12} md={1}>
                <CheckBoxInputBRH
                  label="Property"
                  value={isPropertySelected}
                  name="isPropertySelected"
                  size="large"
                  multiple
                />
              </Grid> */}
                  <Grid item sm={12} md={6}>
                    <AutoCompleteInputBRH
                      label="Property"
                      options={propertyOptions}
                      value={property}
                      name="property_name"
                      handleChange={handlePropertyChange}
                      multiple
                      filterSelectedOptions
                    />
                  </Grid>
                  {/* person */}
                  {/* <Grid item sm={12} md={1}>
                <Typography>Reported By</Typography>
              </Grid> */}
                  {/* <Grid item sm={12} md={1}>
                <CheckBoxInputBRH
                  label="Person"
                  value={isPersonSelected}
                  name="isPersonSelected"
                  size="large"
                />
              </Grid> */}
                  <Grid item sm={12} md={6}>
                    <AutoCompleteInputBRH
                      label="Reported Person"
                      options={personOptions}
                      value={person}
                      name="name"
                      handleChange={(e, newValue) =>
                        dispatch({
                          type: "filter/reportedPerson",
                          payload: newValue,
                        })
                      }
                    />
                  </Grid>
                  {/* category */}
                  {/* <Grid item sm={12} md={1}>
                <Typography>Issue Category</Typography>
              </Grid> */}
                  {/* <Grid item sm={12} md={1}>
                <CheckBoxInputBRH
                  label="Category"
                  value={iscategorySelected}
                  name="iscategorySelected"
                  size="large"
                />
              </Grid> */}
                  <Grid item sm={12} md={4}>
                    <AutoCompleteInputBRH
                      label="Issue Category"
                      options={categoryList}
                      value={category}
                      name="issue_category_name"
                      handleChange={(e, newValue) =>
                        dispatch({ type: "filter/category", payload: newValue })
                      }
                    />
                  </Grid>
                  {/* user Type */}
                  {/* <Grid item sm={12} md={1}>
                <Typography>User Type</Typography>
              </Grid> */}
                  <Grid item sm={12} md={4}>
                    <AutoCompleteInputBRH
                      label="User Type"
                      options={userTypeOptions}
                      value={userType}
                      name="name"
                      handleChange={(e, newValue) =>
                        dispatch({ type: "filter/userType", payload: newValue })
                      }
                    />
                  </Grid>
                  {/* status */}
                  {/* <Grid item sm={12} md={1}>
                <Typography>status</Typography>
              </Grid> */}
                  <Grid item sm={12} md={4}>
                    <AutoCompleteInputBRH
                      label="Status"
                      options={statusOptions}
                      value={status}
                      name="name"
                      handleChange={(e, newValue) =>
                        dispatch({ type: "filter/status", payload: newValue })
                      }
                    />
                  </Grid>
                </>
              )}

              {showSummaryReport && (
                <>
                  {/* divider-summary */}
                  <Grid item sm={12} md={12}>
                    <TextDivider>Summary Report</TextDivider>
                  </Grid>
                  {/* MONTHLY REPORT */}
                  <Grid item sm={12} md={12}>
                    <div className=" d-flex  align-items-center justify-content-end mb-4">
                      <Button
                        variant="contained"
                        style={{
                          width: "200px",
                          fontSize: "15px",
                          // marginRight: "30px",
                        }}
                        onClick={downloadasxlforsummary}
                        endIcon={<GetAppIcon />}
                      >
                        Export
                      </Button>
                    </div>
                    {isReportByMonth ? (
                      <div className={styles.tableContainer}>
                        <div className={styles.titleContainer}>
                          <Grid container>
                            <Grid item sm={12} md={8}>
                              <Typography variant="h5">
                                <span>
                                  {" "}
                                  {startDate &&
                                    moment(startDate).format(
                                      "DD-MMM-YYYY"
                                    )}{" "}
                                </span>
                                <span style={{ color: "#faa619" }}>
                                  {startDate && "to"}
                                </span>
                                <span>
                                  {" "}
                                  {endDate &&
                                    moment(endDate).format("DD-MMM-YYYY")}
                                </span>
                              </Typography>
                            </Grid>

                            <Grid item sm={12} md={4}>
                              <Typography variant="h5" textAlign="end">
                                By Month
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        {isReportByMonth && (
                          <SummaryTable
                            filteredIssuesMonth={filteredIssuesbyMonths}
                            dispatch={dispatch}
                            scrollAnimation={scrollToTarget}
                            startDate={startDate}
                            endDate={endDate}
                            // key={}
                          />
                        )}
                        {/* <table className={styles.table}>
                      <thead className={styles.thead}>
                        <tr>
                          <th>Month</th>
                          <th>No of issues raised</th>
                          <th>Open</th>
                          <th>Assign Staff</th>
                          <th>Work Started</th>
                          <th>Resolved</th>
                          <th>Closed</th>
                          <th>Delayed</th>
                        </tr>
                      </thead>
                      <tbody className={styles.tbody}>
                        <tr>
                          <td>{moment(startDate).format("MMMM")}</td>
                          <td>
                            <button
                              onClick={() =>
                                dispatch({
                                  type: "filterDataTable",
                                  payload: "total",
                                })
                              }
                            >
                              {filteredIssues.length}
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                dispatch({
                                  type: "filterDataTable",
                                  payload: "unassigned",
                                })
                              }
                            >
                              {
                                filteredIssues.filter(
                                  (issue) =>
                                    issue.status === ISSUE_UNASSIGNED ||
                                    issue.status === ISSUE_REOPEN
                                )?.length
                              }
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                dispatch({
                                  type: "filterDataTable",
                                  payload: "assigned",
                                })
                              }
                            >
                              {
                                filteredIssues.filter(
                                  (issue) => issue.status === ISSUE_ASSIGNED
                                )?.length
                              }
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                dispatch({
                                  type: "filterDataTable",
                                  payload: "started",
                                })
                              }
                            >
                              {
                                filteredIssues.filter(
                                  (issue) => issue.status === ISSUE_WORK_STARTED
                                )?.length
                              }
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                dispatch({
                                  type: "filterDataTable",
                                  payload: "resolved",
                                })
                              }
                            >
                              {
                                filteredIssues.filter(
                                  (issue) => issue.status === ISSUE_RESOLVED
                                )?.length
                              }
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                dispatch({
                                  type: "filterDataTable",
                                  payload: "closed",
                                })
                              }
                            >
                              {
                                filteredIssues.filter(
                                  (issue) => issue.status === ISSUE_CLOSED
                                )?.length
                              }
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                dispatch({
                                  type: "filterDataTable",
                                  payload: "delayed",
                                })
                              }
                            >
                              {
                                filteredIssues.filter(
                                  (issue) => issue.isDelayed === true
                                )?.length
                              }
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                      </div>
                    ) : (
                      <Typography variant="h6" textAlign="center">
                        {dateType === DATE_TYPE_MONTH
                          ? "Please select month"
                          : dateType === DATE_TYPE_CALENDAR
                          ? "Please select the date range"
                          : "Please select the Year"}
                      </Typography>
                    )}
                  </Grid>
                  {/* PROPERTY REPORT */}
                  <Grid item sm={12} md={12}>
                    {isReportByProperty && (
                      <div className={styles.tableContainer}>
                        <div className={styles.titleContainer}>
                          <Grid container>
                            <Grid item sm={12} md={8}>
                              <Typography variant="h5">
                                <span>
                                  {" "}
                                  {startDate &&
                                    moment(startDate).format(
                                      "DD-MMM-YYYY"
                                    )}{" "}
                                </span>
                                <span style={{ color: "#faa619" }}>
                                  {startDate && "to"}
                                </span>
                                <span>
                                  {" "}
                                  {endDate &&
                                    moment(endDate).format("DD-MMM-YYYY")}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Typography variant="h5" textAlign="end">
                                By Property
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <SummaryTable
                          filteredIssuesMonth={filteredIssuesbyProperty}
                          dispatch={dispatch}
                          scrollAnimation={scrollToTarget}
                          type="property"
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    )}
                  </Grid>
                  {/* CITY REPORT */}
                  <Grid item sm={12} md={12}>
                    {isReportByCity && (
                      <div className={styles.tableContainer}>
                        <div className={styles.titleContainer}>
                          <Grid container>
                            <Grid item sm={12} md={8}>
                              <Typography variant="h5">
                                <span>
                                  {" "}
                                  {startDate &&
                                    moment(startDate).format(
                                      "DD-MMM-YYYY"
                                    )}{" "}
                                </span>
                                <span style={{ color: "#faa619" }}>
                                  {startDate && "to"}
                                </span>
                                <span>
                                  {" "}
                                  {endDate &&
                                    moment(endDate).format("DD-MMM-YYYY")}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Typography variant="h5" textAlign="end">
                                By city
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <SummaryTable
                          filteredIssuesMonth={filteredIssuesbyCity}
                          dispatch={dispatch}
                          scrollAnimation={scrollToTarget}
                          type="city"
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    )}
                  </Grid>
                  {/* CATEGORY REPORT */}
                  <Grid item sm={12} md={12}>
                    {isReportByIssuecategory && (
                      <div className={styles.tableContainer}>
                        <div className={styles.titleContainer}>
                          <Grid container>
                            <Grid item sm={12} md={8}>
                              <Typography variant="h5">
                                <span>
                                  {" "}
                                  {startDate &&
                                    moment(startDate).format(
                                      "DD-MMM-YYYY"
                                    )}{" "}
                                </span>
                                <span style={{ color: "#faa619" }}>
                                  {startDate && "to"}
                                </span>
                                <span>
                                  {" "}
                                  {endDate &&
                                    moment(endDate).format("DD-MMM-YYYY")}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Typography variant="h5" textAlign="end">
                                By Category
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <SummaryTable
                          filteredIssuesMonth={filteredIssuesbyCategory}
                          dispatch={dispatch}
                          scrollAnimation={scrollToTarget}
                          type="category"
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    )}
                  </Grid>
                </>
              )}
              {showDetailReport && (
                <>
                  {/* divider-detailed */}
                  <Grid item sm={12} md={12}>
                    <TextDivider>Detailed Report</TextDivider>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <div className=" d-flex  align-items-center justify-content-end mb-4">
                      <Button
                        variant="contained"
                        style={{
                          width: "200px",
                          fontSize: "15px",
                          marginRight: "30px",
                        }}
                        onClick={downloadasxl}
                        endIcon={<GetAppIcon />}
                      >
                        Export
                      </Button>
                      <Button
                        startIcon={<ReplaySharpIcon fontSize="inherit" />}
                        variant="outlined"
                        className="mr-3"
                        style={{
                          width: "100px",
                          fontSize: "15px",
                        }}
                        onClick={ResetData}
                      >
                        Reset
                      </Button>
                    </div>
                    <div id="targetElement">
                      <NormalTable
                        columns={columns}
                        data={monthTableData.data} // NEED TO MAINTAIN EMPTY ARRAY WHEN HEADER IS NOT UPDATED
                        enableTopToolbar={false}
                        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                        state={{ pagination }}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Container>
        <div
          className={styles.floatContainer}
          style={{ display: startDate && endDate ? "block" : "none" }}
          // sx={{ maxWidth: "100px", border: "1px solid #ccc" }}
        >
          <Stack>
            <Box
              className={`${styles.floatBox} ${
                showSummaryReport ? styles.flotBoxActive : ""
              }`}
            >
              <Tooltip title="Summary" placement="right">
                <IconButton
                  onClick={(e) =>
                    dispatch({
                      type: "form/reportType",
                      name: "showSummaryReport",
                      payload: !showSummaryReport,
                    })
                  }
                >
                  <SummaryIcon
                    fontSize={ICON_SIZE}
                    color={showSummaryReport ? "primary" : "disabled"}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              className={`${styles.floatBox} ${
                showDetailReport ? styles.flotBoxActive : ""
              }`}
            >
              <Tooltip title="Detail" placement="right">
                <IconButton
                  onClick={(e) =>
                    dispatch({
                      type: "form/reportType",
                      name: "showDetailReport",
                      payload: !showDetailReport,
                    })
                  }
                >
                  <DetailedIcon
                    fontSize={ICON_SIZE}
                    color={showDetailReport ? "primary" : "disabled"}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ borderTop: "1px solid #e67700" }}></Box>
            <Box
              className={`${styles.floatBox} ${
                isReportByMonth ? styles.flotBoxActive : ""
              }`}
            >
              <Tooltip title="Month" placement="right">
                <IconButton
                  onClick={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: "isReportByMonth",
                      payload: !isReportByMonth,
                    })
                  }
                >
                  <MonthIcon
                    fontSize={ICON_SIZE}
                    color={isReportByMonth ? "primary" : "disabled"}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              className={`${styles.floatBox} ${
                isReportByProperty ? styles.flotBoxActive : ""
              }`}
            >
              <Tooltip title="Property" placement="right">
                <IconButton
                  onClick={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: "isReportByProperty",
                      payload: !isReportByProperty,
                    })
                  }
                >
                  <PropertyIcon
                    fontSize={ICON_SIZE}
                    color={isReportByProperty ? "primary" : "disabled"}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              className={`${styles.floatBox} ${
                isReportByCity ? styles.flotBoxActive : ""
              }`}
            >
              <Tooltip title="City" placement="right">
                <IconButton
                  onClick={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: "isReportByCity",
                      payload: !isReportByCity,
                    })
                  }
                >
                  <CityIcon
                    fontSize={ICON_SIZE}
                    color={isReportByCity ? "primary" : "disabled"}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              className={`${styles.floatBox} ${
                isReportByIssuecategory ? styles.flotBoxActive : ""
              }`}
            >
              <Tooltip title="Category" placement="right">
                <IconButton
                  onClick={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: "isReportByIssuecategory",
                      payload: !isReportByIssuecategory,
                    })
                  }
                >
                  <CategoryIcon
                    fontSize={ICON_SIZE}
                    color={isReportByIssuecategory ? "primary" : "disabled"}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          {/* <Stack>
            <Box className={styles.floatBox}>
              <CheckBoxInputBRH
                label="Month"
                value={isReportByMonth}
                name="isReportByMonth"
                handleChange={(e) =>
                  dispatch({
                    type: "form/reportBy",
                    name: e.target.name,
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            </Box>
            <Box className={styles.floatBox}>
              <CheckBoxInputBRH
                label="Property"
                value={isReportByProperty}
                name="isReportByProperty"
                handleChange={(e) =>
                  dispatch({
                    type: "form/reportBy",
                    name: e.target.name,
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            </Box>
            <Box className={styles.floatBox}>
              <CheckBoxInputBRH
                label="City"
                value={isReportByCity}
                name="isReportByCity"
                handleChange={(e) =>
                  dispatch({
                    type: "form/reportBy",
                    name: e.target.name,
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            </Box>
            <Box className={styles.floatBox}>
              <CheckBoxInputBRH
                label="Category"
                value={isReportByIssuecategory}
                name="isReportByIssuecategory"
                handleChange={(e) =>
                  dispatch({
                    type: "form/reportBy",
                    name: e.target.name,
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            </Box>
          </Stack> */}
        </div>
      </Paper>
    </>
  );
}
{
  /* divider-report by  */
}
{
  /* <Grid item sm={12} md={12}>
                <TextDivider>Report By</TextDivider>
              </Grid>
              <Grid item sm={6} md={3}>
                <CheckBoxInputBRH
                  label="Month"
                  value={isReportByMonth}
                  name="isReportByMonth"
                  handleChange={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: e.target.name,
                      payload: e.target.checked,
                    })
                  }
                  size="large"
                />
              </Grid>
              <Grid item sm={6} md={3}>
                <CheckBoxInputBRH
                  label="Property"
                  value={isReportByProperty}
                  name="isReportByProperty"
                  handleChange={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: e.target.name,
                      payload: e.target.checked,
                    })
                  }
                  size="large"
                />
              </Grid>
              <Grid item sm={6} md={3}>
                <CheckBoxInputBRH
                  label="City"
                  value={isReportByCity}
                  name="isReportByCity"
                  handleChange={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: e.target.name,
                      payload: e.target.checked,
                    })
                  }
                  size="large"
                />
              </Grid>
              <Grid item sm={6} md={3}>
                <CheckBoxInputBRH
                  label="Category"
                  value={isReportByIssuecategory}
                  name="isReportByIssuecategory"
                  handleChange={(e) =>
                    dispatch({
                      type: "form/reportBy",
                      name: e.target.name,
                      payload: e.target.checked,
                    })
                  }
                  size="large"
                />
              </Grid> */
}
