import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import toast from "react-simple-toasts";
import ExtendTime from "./ExtendTime";
import { date, number } from "joi";
import { BOOKINGS, INVITEGUEST, PROPERTYLIST } from "./apiConfig";
import ConfirmModal from "../common/ConfirmModal";
import Swal from "sweetalert2";

function UpdateGuestModal(props) {
  // ----------->VARIABLES -------------->
  // --new
  const [updatedProps, setUpdatedProps] = useState();
  const [updatedUserName, setUpdatedUserName] = useState();
  const [updatedUserMail, setUpdateduserMail] = useState();
  const [updatedGuestName, setUpdatedGuestName] = useState();
  const [updatedGuestMail, setUpdatedGuestMail] = useState();
  const [updatedGuestPhone, setUpdatedGuestPhone] = useState();
  const [updatedInvitedTo, setUpadtedInvitedTo] = useState();
  const [updatedInvitedDate, setUpdatedInvitedDate] = useState();
  const [updatedInvitedTime, setUpdatedInvitedTime] = useState();
  const [updatedVisitingHours, setUpdatedVisitingHours] = useState();
  const [updatedInvitedpurpose, setUpdatedInvitedPurpose] = useState();
  const [updatedCheckedInStatus, setUpdatedCheckedInStatus] = useState();
  const [updateduserId, setUpdatedUserId] = useState();
  const [updatedBookingStart, setUpdatedBookingStart] = useState();
  const [updatedBookingEnd, setUpdatedBookingEnd] = useState();
  // --new

  const [propertyId, setPropertyId] = useState();
  // const [updatedId, setUpdatedId] = useState();
  const [updatedEnddate, setUpdatedEndDate] = useState();
  const [modalExtendTime, setModalExtendTime] = useState(false);
  const [canExtend, setCanExtend] = useState(true);

  //constants
  const [apiList, setApiList] = useState();
  const [propertiesList, setPropertiesList] = useState();
  const [visitingTimeHours, setVisitingTimeHours] = useState();
  const [visitingTimeMinutes, setVisitingTimeMinutes] = useState();
  const hours = [0, 1, 2, 3, 4];
  // const minutes = [
  //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  //   21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  //   40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
  //   59, 60,
  // ];
  const minutes = [0, 15, 30, 45];
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  const [loadingTime, setloadingTime] = useState(false);
  const [extendTime, setExtendTime] = useState(0);
  const [initialVisitingTimeHours, setInitialVisitingTimeHours] = useState();
  const [initialVisitingTimeMinutes, setInitialVisitingTimeMinutes] =
    useState();

  //errors
  const [change,setchange]=useState(true);

  const [errorGuestName, setErrorGuestName] = useState(false);
  const [errorGuestMail, setErrorGuestMail] = useState(false);
  const [errorGuestPhone, setErrorGuestPhone] = useState(false);
  const [errorvisitingHours, setErrorVisitingHours] = useState(false);
  const [errorMaxTime, setErrorMaxTime] = useState(false);
  const [propertyname, setPropertyName] = useState();
  //for modal confirm
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  // const [canProceed, setCanProceed] = useState(false);
  const [modalText, setModaltext] = useState();
  const [modalId, setModalId] = useState();
  const [modalStatus, setModalStatus] = useState();
  
  const [updStartTime, setupdStartTime] = useState();
  const [updendTime, setupdendTime] = useState();
  // ---------------------------------->new<----------------------------------------------
  //USEEFFECTS
  useEffect(() => {
    setUpdatedProps(props?.propsList);
    setUpdatedUserName(props?.propsList?.user_name);
    setUpdateduserMail(props?.propsList?.user_email);
    setUpdatedGuestName(props?.propsList?.guest_name);
    setUpdatedGuestMail(props?.propsList?.guest_email);
    setUpdatedGuestPhone(props?.propsList?.guest_phone);
    setUpadtedInvitedTo(props.propsList?.invited_property_id);
    setUpdatedInvitedDate(props?.propsList?.invited_date);
    setUpdatedInvitedTime(props?.propsList?.invited_time);
    setUpdatedVisitingHours(props?.propsList?.visiting_hours);
    setUpdatedCheckedInStatus(props?.propsList?.status);
    setUpdatedUserId(props?.propsList?.user_id);
    setUpdatedInvitedPurpose(props?.propsList?.invited_purpose);
    setUpdatedBookingStart(props?.propsList?.bookings?.start_date);
    setUpdatedBookingEnd(props?.propsList?.bookings?.end_date);
    inputTime();
    
    setupdendTime(props?.propsList?.end_date_time);
    setupdStartTime(props?.propsList?.start_date_time);
  }, [props?.propsList]);

  console.log(updatedInvitedDate, "mytimings");
  useEffect(() => {
    if (
      updatedBookingStart ||
      updatedProps?.booking_type_id == 4 ||
      updatedInvitedDate
    ) {
      setDateAndTime();
    }
  }, [updatedBookingStart, updatedInvitedDate]);

  useEffect(() => {
    if (visitingTimeHours == 4) {
      setVisitingTimeMinutes(0);
    }
  }, [visitingTimeHours]);
  //FUNCTIONS
  const setDateAndTime = () => {
    if (updatedProps?.booking_type_id == 3) {
      let currentDate = new Date().toDateString();
      // console.log("setdateAndTime");
      // ----setting dates
      let tempStartDate = new Date(updatedBookingStart);
      let startDate = tempStartDate.toDateString();
      let tempMinDate = moment(startDate).format("YYYY-MM-DD");
      //before-corrections
      // if (currentDate > tempMinDate) {
      //   setMinDate(moment(currentDate).format("YYYY-MM-DD"));
      // } else {
      //   // console.log(tempMinDate);
      //   setMinDate(tempMinDate);
      // }
      //before-corrections
      // console.log(moment(new Date()).format("YYYY-MM-DD"), tempMinDate);
      // --after corrections
      if (moment(new Date()).format("YYYY-MM-DD") < tempMinDate) {
        //5-5-23 < 15-5-23
        // console.log("greater cd");
        setMinDate(tempMinDate);
      } else {
        // console.log("lesser cd");
        // console.log(tempMinDate);
        setMinDate(moment(currentDate).format("YYYY-MM-DD"));
      }
      // --after corrections
      let tempEndDate = new Date(updatedBookingEnd);
      let endDate = tempEndDate.toDateString();
      let tempMaxDate = moment(endDate).format("YYYY-MM-DD");
      setMaxDate(tempMaxDate);
      // ------setting times
      let startTime = tempStartDate.toTimeString();
      let splittedStartTime = startTime.split(":");
      let minTimeHour = splittedStartTime[0];
      let minTimeMinutes = splittedStartTime[1];
      if (
        moment(updatedInvitedDate).format("YYYY-MM-DD") ==
        moment(currentDate).format("YYYY-MM-DD")
      ) {
        // console.log("today date");
        let tempCurrentTime = new Date().toTimeString();
        let splittedStartTime = tempCurrentTime.split(":");
        let minTimeHour = splittedStartTime[0];
        let minTimeMinutes = splittedStartTime[1];
        setMinTime(`${minTimeHour}:${minTimeMinutes}`);
      } else {
        // console.log("not today ", minTimeHour, minTimeMinutes);
        setMinTime(`${minTimeHour}:${minTimeMinutes}`);
      }
      let endTime = tempEndDate.toTimeString();
      let splittedEndTime = endTime.split(":");
      let maxTimeHour = splittedEndTime[0];
      let maxTimeMinutes = splittedEndTime[1];
      setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
    } else if (updatedProps?.booking_type_id == 2) {
      console.log(updatedProps);
      console.log(
        moment(updatedProps?.corporate?.start_date).format("YYYY-MM-DD")
      );
      let currentDate = new Date();
      // let tempPastDate = new date();
      // let tempCurrentDate = new Date();
      // let tempFutureDate = tempCurrentDate.setDate(
      //   tempCurrentDate.getDate() + 30
      // );
      // let pastDate = moment(tempPastDate).format("YYYY-MM-DD");
      // let futureDate = moment(tempFutureDate).format("YYYY-MM-DD");
      // setMinDate(pastDate);
      // setMaxDate(futureDate);
      let tempStartDateValidate = new Date(updatedProps?.corporate?.start_date);
      let startDate = tempStartDateValidate.toDateString();
      let tempMinDate = moment(startDate).format("YYYY-MM-DD");
      // console.log(moment(new Date()).format("YYYY-MM-DD"), tempMinDate);
      // --after corrections
      if (moment(new Date()).format("YYYY-MM-DD") < tempMinDate) {
        //5-5-23 < 15-5-23
        // console.log("greater cd");
        setMinDate(tempMinDate);
      } else {
        // console.log("lesser cd");
        // console.log(tempMinDate);
        setMinDate(moment(currentDate).format("YYYY-MM-DD"));
      }
      // --after corrections

      let tempEndDateValidate = new Date(updatedProps?.corporate?.end_date);
      let endDate = tempEndDateValidate.toDateString();
      let tempMaxDate = moment(endDate).format("YYYY-MM-DD");
      setMaxDate(tempMaxDate);
      //timings
      let tempStartDate = new Date(
        `${updatedInvitedDate},${updatedProps?.property?.start_at}`
      );
      let tempEndDate = new Date(
        `${updatedInvitedDate},${updatedProps?.property?.end_at}`
      );
      // ------setting times
      let startTime = tempStartDate.toTimeString();
      let splittedStartTime = startTime.split(":");
      let minTimeHour = splittedStartTime[0];
      let minTimeMinutes = splittedStartTime[1];
      if (
        moment(updatedInvitedDate).format("YYYY-MM-DD") ==
        moment(currentDate).format("YYYY-MM-DD")
      ) {
        // console.log("today date");
        let tempCurrentTime = new Date().toTimeString();
        let splittedStartTime = tempCurrentTime.split(":");
        let minTimeHour = splittedStartTime[0];
        let minTimeMinutes = splittedStartTime[1];
        setMinTime(`${minTimeHour}:${minTimeMinutes}`);
      } else {
        // console.log("not today ", minTimeHour, minTimeMinutes);
        setMinTime(`${minTimeHour}:${minTimeMinutes}`);
      }
      let endTime = tempEndDate.toTimeString();
      let splittedEndTime = endTime.split(":");
      let maxTimeHour = splittedEndTime[0];
      let maxTimeMinutes = splittedEndTime[1];
      setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
    } else if (updatedProps?.booking_type_id == 4) {
      let currentDate = new Date();
      let tempPastDate = new date();
      let tempCurrentDate = new Date();
      let tempFutureDate = tempCurrentDate.setDate(
        tempCurrentDate.getDate() + 30
      );
      let pastDate = moment(tempPastDate).format("YYYY-MM-DD");
      let futureDate = moment(tempFutureDate).format("YYYY-MM-DD");
      setMinDate(pastDate);
      setMaxDate(futureDate);
      //timings
      let tempStartDate = new Date(
        `${updatedInvitedDate},${updatedProps?.property?.start_at}`
      );
      let tempEndDate = new Date(
        `${updatedInvitedDate},${updatedProps?.property?.end_at}`
      );
      // ------setting times
      let startTime = tempStartDate.toTimeString();
      let splittedStartTime = startTime.split(":");
      let minTimeHour = splittedStartTime[0];
      let minTimeMinutes = splittedStartTime[1];
      if (
        moment(updatedInvitedDate).format("YYYY-MM-DD") ==
        moment(currentDate).format("YYYY-MM-DD")
      ) {
        // console.log("today date");
        let tempCurrentTime = new Date().toTimeString();
        let splittedStartTime = tempCurrentTime.split(":");
        let minTimeHour = splittedStartTime[0];
        let minTimeMinutes = splittedStartTime[1];
        setMinTime(`${minTimeHour}:${minTimeMinutes}`);
      } else {
        // console.log("not today ", minTimeHour, minTimeMinutes);
        setMinTime(`${minTimeHour}:${minTimeMinutes}`);
      }
      let endTime = tempEndDate.toTimeString();
      let splittedEndTime = endTime.split(":");
      let maxTimeHour = splittedEndTime[0];
      let maxTimeMinutes = splittedEndTime[1];
      setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
    }
  };

  const inputTime = () => {
    const defalutTime = props.visitingHours;
    let temp = defalutTime?.split(".");
    if (temp) {
      setVisitingTimeHours(temp[0]);
      setInitialVisitingTimeHours(temp[0]);
      setVisitingTimeMinutes(temp[1]);
      setInitialVisitingTimeMinutes(temp[1]);
    }
  };

  const handleExtendSubmit = (data) => {
    setExtendTime(data);
    // toast(`${data} mins Extended !`, {
    //   time: 800,
    //   position: "top-right",
    //   clickable: true,
    //   clickClosable: true,
    //   className: "success",
    // });
    Swal.fire({
      html: `<i>${data} mins Extended !</i>`,
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
    // setTimeout(toastFunction, 1000);
    setCanExtend(false);
  };

  // ---------------------------------->new<----------------------------------------------

  // ----------->FUNCTIONS -------------->
  // const getPropertyList = () => {
  //   axios
  //     .get(PROPERTYBYUSERID, {
  //       params: {
  //         user_id: updateduserId,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res, "API-PROPERTYLIST");
  //       setPropertiesList(res?.data?.data);
  //     })
  //     .catch((err) => console.log(err));
  // };
  const propertyMap = propertiesList?.map((property, index) => {
    return (
      <option value={index} key={index}>
        {`${property?.property_name}  - ${moment(property.start_date).format(
          "DD-MM-YY"
        )} : ${moment(property.end_date).format("DD-MM-YY")}`}
      </option>
    );
  });

  const toastFunction = () => {
    props.setUpdateInviteModalShow(true);
    setModalExtendTime(false);
  };
  const cancelinvite = (data) => {
    data.preventDefault();
    setModalId(props.id);
    setModalStatus(4);
    setModaltext("Cancel");
    setConfirmModalShow(true);

    return console.log("lakshmanan");
    const formData = {
      status: 4,
    };
    axios
      .put(`${INVITEGUEST}/${props.id}`, formData)
      .then((res) => {
        if (res?.data?.status) {
          toast(`${res?.data?.message}!`, {
            time: 2000,
            position: "top-right",
            clickable: true,
            clickClosable: true,
            className: "success",
          });
          setloadingTime(true);
        } else {
          toast("something went wrong. Please Try Again!", {
            time: 1000,
            position: "top-right",
            clickable: true,
            clickClosable: true,
            className: "error",
          });
        }
        console.log(res, "API-INVITEGUEST");
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = (data) => {
    data.preventDefault();
    //error handling
    let nameInfo = makeErrorGuestName(updatedGuestName);
    let mailInfo = makeErrorGuestMail(updatedGuestMail);
    let phoneInfo = makeErrorGuestPhone(updatedGuestPhone);
    let visitinginfo = validateVisitingHour(
      visitingTimeHours,
      visitingTimeMinutes
    );
    let oneDigit = Number(visitingTimeHours);
    let twoDigits = Number(visitingTimeMinutes) / 100;
    let temp = (oneDigit + twoDigits).toFixed(2);
    let startDateTime = new Date(`${updatedInvitedDate},${updatedInvitedTime}`);
    let endDateTime = new Date(startDateTime);
    endDateTime.setHours(endDateTime.getHours() + Number(visitingTimeHours)); // Adding Hours
    endDateTime.setMinutes(
      endDateTime.getMinutes() + Number(visitingTimeMinutes)
    ); // Adding Minutes
    endDateTime = new Date(endDateTime);
    if (temp < 0.15) {
      setErrorVisitingHours(true);
    } else {
      setErrorVisitingHours(false);
    }
    const userData = JSON.parse(localStorage.getItem("user"));

    const formData = {
      guest_name: updatedGuestName,
      guest_email: updatedGuestMail,
      invited_date: updatedInvitedDate,
      invited_time: updatedInvitedTime,
      visiting_hours: temp,
      invited_purpose: updatedInvitedpurpose,
      
      start_date: change ? updStartTime :  startDateTime,
      end_date: change ? updendTime : endDateTime,
      // status: 1,
      guest_phone:updatedGuestPhone,
      guest_added_by:userData?.id
    };
    if (phoneInfo && nameInfo && mailInfo && temp > 0.14 && visitinginfo) {
      // console.log("CALL API");
      axios
        .put(`${INVITEGUEST}/${props.id}`, formData)
        .then((res) => {
          if (res?.data?.status) {
            Swal.fire({
              html: `<i>${res?.data?.message}</i>`,
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 2000,
            });
            setchange(true);


            setloadingTime(true);
          } else {
            // toast("something went wrong. Please Try Again!", {
            //   time: 1000,
            //   position: "top-right",
            //   clickable: true,
            //   clickClosable: true,
            //   className: "error",
            // });

            Swal.fire({
              html: `<i>something went wrong. Please Try Again!</i>`,
              icon: "warning",
              title: "Warning",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          console.log(res, "API-UPDATE");
        })
        .catch((err) => console.log(err));
      // console.log(formData);
    }
    // console.log({ ...formData });
  };
  //to add extended minutes to current visiting hours
  const makeExtendTime = () => {
    let formExtendtimeHour;
    let formExtendtimeMinute;
    //new
    let initialHour = Number(initialVisitingTimeHours);
    let initialMinute = Number(initialVisitingTimeMinutes);
    let extendMinute = Number(extendTime);
    let addedMinutes = initialMinute + extendMinute;
    // console.log(addedMinutes);
    if (addedMinutes == 60) {
      initialHour = initialHour + 1;
      formExtendtimeHour = initialHour;
      formExtendtimeMinute = 0;
    } else if (addedMinutes > 60) {
      initialHour = initialHour + 1;
      let corectedMinutes = addedMinutes - 60;
      formExtendtimeHour = initialHour;
      formExtendtimeMinute = corectedMinutes;
    } else {
      formExtendtimeHour = initialHour;
      formExtendtimeMinute = addedMinutes;
    }
    // console.log(formExtendtimeHour, formExtendtimeMinute);
    setVisitingTimeHours(formExtendtimeHour);
    setVisitingTimeMinutes(formExtendtimeMinute);
    //new

    //old

    // if (extendTime < 60) {
    //   let oneDigit = Number(initialVisitingTimeHours);
    //   let twoDigits = Number(initialVisitingTimeMinutes) / 100;
    //   let tempHour = (oneDigit + twoDigits).toFixed(2);
    //   let splittedTempHour = tempHour.split(".");
    //   formExtendtimeHour = splittedTempHour[0];
    //   // console.log(formExtendtimeHour);
    //   // console.log("lesser");
    //   let tempMinute = (extendTime / 100).toFixed(2);
    //   // console.log(tempMinute, "less");
    //   let splittedTempMinute = tempMinute.split(".");
    //   let tempMinutes = splittedTempMinute[1];
    //   formExtendtimeMinute = tempMinutes;
    // } else if (extendTime == 60) {
    //   let oneDigit = Number(initialVisitingTimeHours) + 1;
    //   let twoDigits = Number(initialVisitingTimeMinutes) / 100;
    //   let tempHour = (oneDigit + twoDigits).toFixed(2);
    //   let splittedTempHour = tempHour.split(".");
    //   formExtendtimeHour = splittedTempHour[0];
    //   // console.log(formExtendtimeHour);
    //   // console.log("lesser");
    //   let tempMinute = (extendTime / 100).toFixed(2);
    //   // console.log(tempMinute, "less");
    //   let splittedTempMinute = tempMinute.split(".");
    //   let tempMinutes = splittedTempMinute[1];
    //   formExtendtimeMinute = tempMinutes;
    // }

    // console.log(formExtendtimeHour, formExtendtimeMinute);

    //old
    //  else if (extendTime >= 60) {
    //   console.log("greater");
    //   let temp = extendTime / 60;
    //   let tempSecondDigit = extendTime - 60;
    //   let firstDigit = Math.trunc(temp);
    //   let secondDigit = Math.trunc((temp - firstDigit) * 60) / 100;
    //   let final = (firstDigit + secondDigit).toFixed(2);
    //   console.log(final);
    //   formExtendtime = final;
    // }

    // let addedTime = (parseFloat(temp) + parseFloat(formExtendtime)).toFixed(2);
    // let addedtimeFirstDigit = Math.trunc(addedTime);
    // let addedTimeSecondDigit = Number(addedTime - addedtimeFirstDigit);
    // if (addedTimeSecondDigit > 0.59) {
    //   let change = (addedTimeSecondDigit - parseFloat(0.5)).toFixed(2);
    //   addedtimeFirstDigit = addedtimeFirstDigit + 1;
    //   setVisitingTimeHours(addedtimeFirstDigit);
    //   setVisitingTimeMinutes(change * 100);
    // } else {
    //   console.log(addedtimeFirstDigit, addedTimeSecondDigit);
    //   let roundedFirstDigit = Math.round(addedtimeFirstDigit);
    //   console.log(roundedFirstDigit, "round");
    //   setVisitingTimeHours(addedtimeFirstDigit);
    //   let change = Math.trunc(parseFloat(addedTimeSecondDigit) * 100);
    //   let roundedSecondDigit = Math.round(change);
    //   console.log(roundedSecondDigit, "round");
    //   setVisitingTimeMinutes(change);
    //   console.log(addedtimeFirstDigit, change);
    // }
  };

  function makeErrorGuestName(data) {
    let guestName = data;
    let pattern = /^[A-Za-z ]+$/;
    let error = guestName.match(pattern);
    if (!error) {
      setErrorGuestName(true);
      return false;
    } else {
      setErrorGuestName(false);
      return true;
    }
  }
  function makeErrorGuestMail(data) {
    let guestMail = data;
    let pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let result = pattern.test(guestMail);
    if (!result) {
      setErrorGuestMail(true);
      return false;
    } else {
      setErrorGuestMail(false);
      return true;
    }
  }
  function makeErrorGuestPhone(data) {
    let guestPhone = data;
    let pattern = /^\d{10}$/;
    let error = guestPhone.match(pattern);

    if (!error) {
      setErrorGuestPhone(true);
      return false;
    } else {
      setErrorGuestPhone(false);
      return true;
    }
  }
  function makeDatesforinput() {
    let date = new Date();
    let currentDate = date.toDateString();
    let minDate = moment(currentDate).format("YYYY-MM-DD");
    setMinDate(minDate);
    let maxDate = date.setMonth(date.getMonth() + 1);
    setMaxDate(moment(maxDate).format("YYYY-MM-DD"));
  }
  function FindPropertyId() {
    return propertiesList?.map((data, index) => {
      let currentDate = new Date().toDateString();
      if (index == updatedInvitedTo) {
        // console.log("im running");
        setPropertyId(data?.id);
        let tempStartDate = new Date(data?.start_date);
        let startDate = tempStartDate.toDateString();
        let tempMinDate = moment(startDate).format("YYYY-MM-DD");
        if (currentDate > tempMinDate) {
          setMinDate(moment(currentDate).format("YYYY-MM-DD"));
        } else {
          // console.log(tempMinDate);
          setMinDate(tempMinDate);
        }
        let tempEndDate = new Date(data?.end_date);
        let endDate = tempEndDate.toDateString();
        let tempMaxDate = moment(endDate).format("YYYY-MM-DD");
        setMaxDate(tempMaxDate);
        let startTime = tempStartDate.toTimeString();
        let splittedStartTime = startTime.split(":");
        let minTimeHour = splittedStartTime[0];
        let minTimeMinutes = splittedStartTime[1];
        if (
          moment(updatedInvitedDate).format("YYYY-MM-DD") ==
          moment(currentDate).format("YYYY-MM-DD")
        ) {
          // console.log("today date");
          let tempCurrentTime = new Date().toTimeString();
          let splittedStartTime = tempCurrentTime.split(":");
          let minTimeHour = splittedStartTime[0];
          let minTimeMinutes = splittedStartTime[1];
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
        } else {
          // console.log("not today ", minTimeHour, minTimeMinutes);
          setMinTime(`${minTimeHour}:${minTimeMinutes}`);
        }
        let endTime = tempEndDate.toTimeString();
        let splittedEndTime = endTime.split(":");
        let maxTimeHour = splittedEndTime[0];
        let maxTimeMinutes = splittedEndTime[1];
        setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
        // let tempStartDate = new Date(data?.start_date);
        // let startDate = tempStartDate.toDateString();
        // let tempMinDate = moment(startDate).format("YYYY-MM-DD");
        // setMinDate(tempMinDate);
        // let tempEndDate = new Date(data?.end_date);
        // let endDate = tempEndDate.toDateString();
        // let tempMaxDate = moment(endDate).format("YYYY-MM-DD");
        // setMaxDate(tempMaxDate);
        // let startTime = tempStartDate.toTimeString();
        // let splittedStartTime = startTime.split(":");
        // let minTimeHour = splittedStartTime[0];
        // let minTimeMinutes = splittedStartTime[1];
        // setMinTime(`${minTimeHour}:${minTimeMinutes}`);
        // let endTime = tempEndDate.toTimeString();
        // let splittedEndTime = endTime.split(":");
        // let maxTimeHour = splittedEndTime[0];
        // let maxTimeMinutes = splittedEndTime[1];
        // setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
      }
    });
  }
  const validateVisitingHour = (hours, minutes) => {
    if (hours == 4) {
      minutes = 0;
    }
    let tempHours = hours;
    let tempMinutes = minutes;
    setVisitingTimeHours(tempHours);
    let maxDateTime = new Date(`${updatedInvitedDate},${maxTime}`); //ok
    let choosedTime = new Date(`${updatedInvitedDate},${updatedInvitedTime}`);
    let futureDateTime = new Date(choosedTime);
    futureDateTime.setHours(
      futureDateTime.getHours() + Number(visitingTimeHours)
    ); // Adding Hours
    futureDateTime.setMinutes(
      futureDateTime.getMinutes() + Number(visitingTimeMinutes)
    ); //adding minutes
    if (futureDateTime > maxDateTime) {
      setErrorMaxTime(true);
      return false;
    } else {
      setErrorMaxTime(false);
      return true;
    }
  };
  //new-functions
  const findProperties = () => {
    axios
      .get(`${BOOKINGS}${updateduserId}`)
      .then((res) => {
        console.log(res, "API-BOOKINGS");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const findPropertName = () => {};
  // useEffect(() => {
  //   if (updateduserId) {
  //     findProperties();
  //   }
  // }, [updateduserId]);
  //new-functions

  // ----------->USEEFFECTS -------------->
  // useEffect(() => {
  //   setApiList(props?.propsList);
  //   setUpdatedUserName(props.userName);
  //   setUpdateduserMail(props.userEmail);
  //   setUpdatedUserId(props.userId);
  //   setUpdatedGuestName(props.guestName);
  //   setUpdatedGuestMail(props.guestEmail);
  //   setUpdatedInvitedDate(props.invitedDate);
  //   setUpdatedInvitedTime(props.invitedTime);
  //   setUpadtedInvitedTo(props.invitedTo);
  //   setUpdatedVisitingHours(props.visitingHours);
  //   setUpdatedCheckedInStatus(props.checkInStatus);
  //   setUpdatedInvitedPurpose(props.invitedPurpose);
  //   setUpdatedEndDate(props.end_date_time);
  //   inputTime();
  // }, [props?.end_date_time]);

  useEffect(() => {
    let interval;
    if (loadingTime) {
      interval = setInterval(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loadingTime]);

  // useEffect(() => {
  //   if (updateduserId) {
  //     getPropertyList();
  //   }
  // }, [updateduserId]);

  useEffect(() => {
    makeExtendTime();
  }, [modalExtendTime]);

  // useEffect(() => {
  //   FindPropertyId();
  // }, [updatedInvitedTo, updatedInvitedDate]);
  function propertyNameMap() {
    return propertiesList?.map((property) => {
      if (property?.property_id == updatedInvitedTo) {
        setPropertyName(property?.property_name);
        return property?.property_name;
      }
    });
  }
  useEffect(() => {
    if (updatedCheckedInStatus == 2) {
      let tempEndDate = new Date(updatedEnddate);
      let endTime = tempEndDate.toTimeString();
      let splittedEndTime = endTime.split(":");
      let maxTimeHour = splittedEndTime[0];
      let maxTimeMinutes = splittedEndTime[1];
      setMaxTime(`${maxTimeHour}:${maxTimeMinutes}`);
      propertyNameMap();
      // console.log("find it", updatedEnddate, updatedInvitedTo, propertyname);
    }
  }, [updatedEnddate, propertiesList]);
  // console.log(apiList);
  // console.log(props?.propsList?.property?.property_name)
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="updateGuestModal">
        <form className="updateGuestForm" onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Update Invite Guest
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="row">
                {/* usermail */}
                <div
                  className="col-6"
                  style={{
                    display:
                      updatedProps?.booking_type_id == 4 ? "none" : "block",
                  }}>
                  <label
                    htmlFor="updateUserMail"
                    className="visitors_modal_lable">
                    User Email
                  </label>
                  <input
                    type="text"
                    name="updateUserMail"
                    className="visitors_modal_input"
                    value={updatedUserMail}
                    disabled
                  />
                </div>
                {/* username */}
                <div
                  className="col-6"
                  style={{
                    display:
                      updatedProps?.booking_type_id == 4 ? "none" : "block",
                  }}>
                  <label
                    htmlFor="updateUserName"
                    className="visitors_modal_lable">
                    User Name
                  </label>
                  <input
                    type="text"
                    name="updateUserName"
                    className="visitors_modal_input"
                    value={updatedUserName}
                    disabled
                  />
                </div>
                {/* guestName */}
                <div className="col-6">
                  <label
                    htmlFor="updateGuestName"
                    className="visitors_modal_lable">
                    Guest Name
                  </label>
                  <input
                    type="text"
                    name="updateGuestName"
                    className="visitors_modal_input"
                    value={updatedGuestName}
                    onChange={(e) => setUpdatedGuestName(e.target.value)}
                    disabled={updatedCheckedInStatus == 2 ? true : false}
                  />
                  <p
                    style={{ display: errorGuestName ? "block" : "none" }}
                    className="inputError">
                    Enter Valid Name
                  </p>
                </div>
                {/* GuestMail */}
                <div className="col-6">
                  <label
                    htmlFor="updateGuestMail"
                    className="visitors_modal_lable">
                    Guest Email
                  </label>
                  <input
                    type="email"
                    name="updateGuestMail"
                    className="visitors_modal_input"
                    value={updatedGuestMail}
                    onChange={(e) => setUpdatedGuestMail(e.target.value)}
                    disabled={updatedCheckedInStatus == 2 ? true : false}
                  />
                  <p
                    style={{ display: errorGuestMail ? "block" : "none" }}
                    className="inputError">
                    Enter Valid Email
                  </p>
                </div>
                <div className="col-6 ">
                  <label htmlFor="date" className="visitors_modal_lable">
                    Phone<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="updateGuestPhone"
                    className="visitors_modal_input"
                    value={updatedGuestPhone}
                    // onChange={(e) => setUpdatedGuestPhone(e.target.value)}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (/^\d{0,10}$/.test(newValue)) {
                        setUpdatedGuestPhone(newValue);
                      }
                    }}
                    disabled={updatedCheckedInStatus == 2 ? true : false}
                  />
                  <p
                    style={{
                      display: errorGuestPhone ? "block" : "none",
                    }}
                    className="inputError">
                    Enter Valid Phone
                  </p>

                  <label htmlFor="date" className="inputError"></label>
                </div>
                {/* inviteTo */}
                <div className="col-6">
                  <label
                    htmlFor="updateInviteTo"
                    className="visitors_modal_lable">
                    Invited To
                  </label>
                  {/* <select
                    name="inviteTo"
                    id=""
                    className="visitors_modal_input"
                    value={updatedInvitedTo}
                    onChange={(e) => setUpadtedInvitedTo(e.target.value)}
                    required
                    disabled={updatedCheckedInStatus == 2 ? true : false}
                    style={{
                      display: updatedCheckedInStatus == 2 ? "none" : "block",
                    }}
                  >
                    <option value="" selected style={{ display: "none" }}>{props.propsList?.property?.property_name}</option>
                    {propertyMap}
                  </select>
                  <input
                    type="text"
                    value={propertyname}
                    className="visitors_modal_input"
                    style={{
                      display: updatedCheckedInStatus == 2 ? "block" : "none",
                    }}
                  /> */}
                  <input
                    type="text"
                    value={
                      // updatedProps?.booking_type_id == 2 ||
                      updatedProps?.booking_type_id == 3
                        ? `${
                            props.propsList?.property?.property_name
                          } | ${moment(updatedBookingStart).format(
                            "DD-MMM-YYYY"
                          )}  - ${moment(updatedBookingEnd).format(
                            "DD-MMM-YYYY"
                          )} |` // ${moment(updatedBookingStart?.start_date).format('HH-MM-A')}  - ${moment(updatedBookingEnd?.end_date).format('HH-MM-A')}
                        : updatedProps?.booking_type_id == 2
                        ? `${updatedProps?.property?.property_name} | ${updatedProps?.corporate?.start_date} | ${updatedProps?.corporate?.end_date}`
                        : `${updatedProps?.property?.property_name} | ${updatedProps?.property?.start_at} | ${updatedProps?.property?.end_at}`
                    }
                    disabled
                    className="visitors_modal_input"
                    style={{ fontWeight: "600", textTransform: "capitalize" }}
                  />
                </div>
                {/* date */}
                <div className="col-6">
                  <label
                    htmlFor="updateInviteDate"
                    className="visitors_modal_lable">
                    Date
                  </label>
                  <input
                    type="date"
                    name="updateInviteDate"
                    className="visitors_modal_input"
                    value={moment(updatedInvitedDate).format("YYYY-MM-DD")}
                    // min={minDate}
                    // max={maxDate}
                    // onChange={(e) => setUpdatedInvitedDate(e.target.value)}
                    onChange={(e) => {setUpdatedInvitedDate(e.target.value)
                      setchange(false); }
                      }
                    disabled={updatedCheckedInStatus == 2 ? true : false}
                  />
                </div>
                {/* time */}
                <div className="col-6 time_error">
                  <label
                    htmlFor="updateInviteTime"
                    className="visitors_modal_lable">
                    Time
                    <span
                      style={{
                        display:
                          updatedCheckedInStatus == 2 ? "inline-block" : "none",
                      }}
                      className="text-muted">
                      max time:{" "}
                      {moment(
                        `${updatedProps?.invited_date},${maxTime}`
                      ).format("hh:mm: A")}
                    </span>
                    <span
                      style={{
                        display:
                          (updatedProps?.booking_type_id == 2 &&
                            updatedProps?.status == 1) ||
                          (updatedProps?.booking_type_id == 3 &&
                            updatedProps?.status == 1) ||
                          (updatedProps?.booking_type_id == 4 &&
                            updatedProps?.status == 1)
                            ? "inline-block"
                            : "none",
                        paddingLeft: "5px",
                      }}
                      className="text-muted">
                      {`( ${moment(
                        `${updatedProps?.invited_date},${minTime}`
                      ).format("hh:mm: A")} to ${moment(
                        `${updatedProps?.invited_date},${maxTime}`
                      ).format("hh:mm: A")} )`}
                    </span>
                  </label>
                  <input
                    type="time"
                    name="updateInviteTime"
                    className="visitors_modal_input"
                    value={updatedInvitedTime}
                    min={minTime}
                    max={maxTime}
                    onChange={(e) => setUpdatedInvitedTime(e.target.value)}
                    disabled={updatedCheckedInStatus == 2 ? true : false}
                  />
                  <label htmlFor="time" className="inputError"></label>
                </div>
                {/* visitingHours */}
                <div className="col-6">
                  <label
                    htmlFor="updateInviteHour"
                    className="visitors_modal_lable">
                    Visiting Hours
                  </label>
                  <div
                    className="visitors_modal_input_flex"
                    style={{ position: "relative" }}>
                    {/* --visiting hour */}
                    <div className="selectParent">
                      <span className="visitor_select_hour">hours</span>
                      <select
                        name=""
                        className="visitors_modal_input"
                        id=""
                        value={visitingTimeHours}
                        onChange={(e) => setVisitingTimeHours(e.target.value)}
                        disabled={updatedCheckedInStatus == 2 ? true : false}>
                        <option value="" disabled></option>

                        {hours.map((hour, index) => {
                          return (
                            <option value={hour} key={index}>
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* --Visiting Minutes */}
                    <div className="selectParent">
                      <span className="visitor_select_minute">minutes</span>
                      <select
                        name=""
                        className="visitors_modal_input"
                        id=""
                        value={visitingTimeMinutes}
                        onChange={(e) => setVisitingTimeMinutes(e.target.value)}
                        disabled={
                          updatedCheckedInStatus == 2 || visitingTimeHours == 4
                            ? true
                            : false
                        }>
                        {minutes.map((minute, index) => {
                          console.log(visitingTimeHours, "formVisitingHour");
                          if (visitingTimeHours === "0.00" && minute === 15) {
                            return null;
                          }

                          return (
                            <option
                              value={visitingTimeHours === 4 ? 0 : minute}
                              key={index}>
                              {minute}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <p
                    style={{
                      display: errorvisitingHours ? "block" : "none",
                    }}
                    className="inputError">
                    It should be above 15 mins
                  </p>
                  <p
                    style={{ display: errorMaxTime ? "block" : "none" }}
                    className="inputError">
                    Visting Hours should be less than booking Hours
                  </p>
                </div>

                {/* visitingpurpose */}
                <div className="col-12">
                  <label
                    htmlFor="updateInviteHour"
                    className="visitors_modal_lable">
                    Purpose of Visit
                  </label>
                  <textarea
                    name="updateInviteHour"
                    id=""
                    cols="24"
                    rows="3"
                    className="visitors_modal_textarea"
                    value={updatedInvitedpurpose}
                    onChange={(e) => setUpdatedInvitedPurpose(e.target.value)}
                    disabled={
                      updatedCheckedInStatus == 2 ? true : false
                    }></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                backgroundColor: "dodgerblue",
                borderColor: "dodgerblue",
                marginRight: "auto",
                display:
                  updatedCheckedInStatus == 2 && canExtend ? "block" : "none",
              }}
              onClick={() => {
                setModalExtendTime(true);
                props.setUpdateInviteModalShow(false);
              }}>
              Extend Time
            </Button>
            <button
              className="btn visitor_button"
              onClick={(data) => {
                cancelinvite(data);
              }}>
              Cancel Invite
            </button>
            <button type="submit" className="btn visitor_button">
              Update Invite
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <ExtendTime
        show={modalExtendTime}
        onHide={() => setModalExtendTime(false)}
        setModalExtendTime={setModalExtendTime}
        setUpdateInviteModalShow={props.setUpdateInviteModalShow}
        extendTime={extendTime}
        setExtendTime={setExtendTime}
        handleExtendSubmit={handleExtendSubmit}
      />
      <div className="confirm_model_cancel">
        <ConfirmModal
          show={confirmModalShow}
          onHide={() => setConfirmModalShow(false)}
          modalText={modalText}
          modalId={modalId}
          modalStatus={modalStatus}
          setloadingTime={setloadingTime}
        />
      </div>
    </>
  );
}

export default UpdateGuestModal;
