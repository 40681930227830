const devUrl = `${process.env.REACT_APP_API_URL}visitor-management/api/v1/`; //https://dev.berighthere.com/visitor-management/api/v1/
// const USERMAIL = `${devUrl}get-invite-guest-users`;
// const PROPERTYLIST = `${devUrl}get-invite-guest-properties`;
// const localUrl = "https://127.0.0.1:8000/api/v1/";
const INVITEGUEST = `${devUrl}invite-guest`;
//new apis
// member
const PROPERTYLIST = `${devUrl}get-properties`; //
const USERMAIL = `${devUrl}get-property-bookings`;
const BOOKINGS = `${devUrl}get-user-bookings`;
//corporate
const CORPORATE = `${devUrl}get-corporates`;
const CORPORATEPROPERTYLIST = `${devUrl}get-corporate-properties`;
const GETEMPLOYEE = `${devUrl}get-corporate-property-employee`;
// const PROPERTYBYUSERID = `${devUrl}get-invite-guest-properties`;
//non-booking
const NONBOOKINGPROPETIES = `${devUrl}get-nonbooking-properties`;

export {
  PROPERTYLIST,
  USERMAIL,
  BOOKINGS,
  CORPORATE,
  CORPORATEPROPERTYLIST,
  GETEMPLOYEE,
  NONBOOKINGPROPETIES,
  INVITEGUEST,
};
