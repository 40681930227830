import { LinearProgress } from "@mui/material";

import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Reports from "./components/reports/Reports";
import { Navbar } from "react-bootstrap";
import VisitorManagement from "./components/Visitor/VisitorManagement";
import BookMeeting from "./components/Dashboards/BookMeeting";

const Login = lazy(() => import("./components/Shared/Login"));
const Dashboard = lazy(() => import("./components/Dashboards/Dashboard"));
const BookingGuest = lazy(() =>
  import("./components/Dashboards/Booking/BookingGuest")
);
const BookingDetails = lazy(() =>
  import("./components/Dashboards/Booking/BookingDetails")
);

const AppRouts = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="App">
      <Suspense fallback={<LinearProgress color="warning" />}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              user !== null ? (
                <Dashboard />
              ) : (
                <Navigate to="/dashboard/login" replace />
              )
            }
          />
          <Route
            exact
            path="/reports"
            element={
              user !== null ? (
                <Reports />
              ) : (
                <Navigate to="/dashboard/login" replace />
              )
            }
          />
          <Route
            exact
            path="/visitor-management"
            element={
              user !== null ? (
                <VisitorManagement />
              ) : (
                <Navigate to="/dashboard/login" replace />
              )
            }
          />
          <Route
            exact
            path="/book"
            element={
              user !== null ? (
                <BookMeeting />
              ) : (
                <Navigate to="/dashboard/book" replace />
              )
            }
          />
          <Route
            exact
            path="/discover/booking-details/:id"
            element={
              user !== null ? <BookingDetails /> : <Navigate to="/" replace />
            }
          />
          <Route
            exact
            path="/discover/booking-guest/:id"
            element={
              user !== null ? <BookingGuest /> : <Navigate to="/" replace />
            }
          />

          <Route
            path="/dashboard/login"
            element={user !== null ? <Navigate to="/" replace /> : <Login />}
          />
          <Route path="*" element={<h1>Page Not Found</h1>} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppRouts;
