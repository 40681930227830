import React, { useEffect } from "react";
import "./App.css";
// import "./App.scss";
import Dashboard from "./components/Dashboards/Dashboard";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AppRoutes from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ThemeProvider from "./theme";

const queryClient = new QueryClient();

function App() {
  const fullscreen = useFullScreenHandle();
  useEffect(() => {}, []);

  return (
    <QueryClientProvider client={queryClient}>
      <FullScreen handle={fullscreen}>
        <BrowserRouter>
          <ThemeProvider>
            <AppRoutes />
          </ThemeProvider>
        </BrowserRouter>
      </FullScreen>
    </QueryClientProvider>
  );
}

export default App;
