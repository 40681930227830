import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function ExtendTime(props) {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="extendTime"
      >
        <form>
          <Modal.Header style={{ display: "none" }}>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <label htmlFor="extendTime" className="visitors_modal_lable">
                Enter Time
              </label>
              <div className="selectParent">
                <span className="visitor_select_minute">minutes</span>
                {/* <input
                  type="number"
                  name="extendTime"
                  value={props.extendTime}
                  onChange={(e) => props.setExtendTime(e.target.value)}
                  className="visitors_modal_input"
                  min="0"
                  max="60"
                  required
                /> */}
                <select
                  name="extendTime"
                  id=""
                  value={props.extendTime}
                  onChange={(e) => props.setExtendTime(e.target.value)}
                  className="visitors_modal_input"
                >
                  <option
                    value=""
                    selected
                    style={{ display: "none" }}
                  ></option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  {/* <option value="60">60</option> */}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => props.handleExtendSubmit(props.extendTime)}>
              Submit
            </Button>
            <Button
              onClick={() => {
                props.setUpdateInviteModalShow(true);
                props.setModalExtendTime(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default ExtendTime;
