import React, { Component } from 'react';
import axios from 'axios';

export const API_URL =
  process.env.REACT_APP_API_URL || 'https://dev.berighthere.com/';

export const api = {
  corporate: API_URL + 'api/corporate',
};

const ServiceLayer = {
  async postMethod(URL, Method, Data) {
    let url = API_URL + URL;
    const token = localStorage.getItem('token');
    const token_type = localStorage.getItem('token_type')
      ? localStorage.getItem('token_type')
      : '';

    const response = await fetch(url, {
      method: Method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: token_type + ' ' + token,
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_type');

        window.location.href = '/user-pages/login';
      }
      return response;
    }
    return response;
  },

  async postMethodWithoutToken(URL, Method, Data) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: Method,
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_type');

        window.location.href = '/user-pages/login';
      }

      return response;
    }
    return response;
  },

  async postMethodNotoken(URL, Method, Data) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: Method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: '2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn',
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_type');

        window.location.href = '/user-pages/login';
      }
      return response;
    }
    return response;
  },

  async fileupload(URL, Data) {
    let url = API_URL + URL;
    const token = localStorage.getItem('token');
    const token_type = localStorage.getItem('token_type')
      ? localStorage.getItem('token_type')
      : '';

    const response = await axios.post(url, Data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: token_type + ' ' + token,
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_type');

        window.location.href = '/user-pages/login';
      }
      return response;
    }
    return response;
  },

  async getData(URL) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: '2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn',
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_type');

        window.location.href = '/user-pages/login';
      }
      return response;
    }
    return response;
  },

  getaxiosData(URL) {
    let url = API_URL + URL;

    return axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: '2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn',
      },
    });
  },
  async getaddress(URL) {
    let url = URL;

    const response = await fetch(url, {
      method: 'GET' 
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_type');

        window.location.href = '/user-pages/login';
      }
      return response;
    }
    return response;
  },
  loginAPI(URL, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data);
  },

  async getDatabyToken(URL) {
    let url = API_URL + URL;
    const token = localStorage.getItem('token');
    const token_type = localStorage.getItem('token_type')
      ? localStorage.getItem('token_type')
      : '';

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: token_type + ' ' + token,
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_type');

        window.location.href = '/user-pages/login';
      }
      return response;
    }
    return response;
  },

  fileupload(URL, Data) {
    let url = API_URL + URL;
    const token = localStorage.getItem('token');
    const token_type = localStorage.getItem('token_type')
      ? localStorage.getItem('token_type')
      : '';

    return axios.post(url, Data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        Authorization: token_type + ' ' + token,
      },
    });
  },
};

export default ServiceLayer;
