import React from "react";
import ChartComponent from "../common/Chart";
import { useSelector } from "react-redux";

export const options = {
  title: "Issues Data",
  is3D: true,
  legend: { position: "bottom" },

  chartArea: { width: "50%", innerHeight: "200px" },
  hAxis: {
    title: "Total Issues",
    minValue: 0,
  },
  vAxis: {
    title: "Types",
  },
};

const Charts = ({ type, data }) => {
  return <ChartComponent type={type} options={options} data={data} />;
};

export default Charts;
