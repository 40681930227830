import {
  Autocomplete,
  TextField,
  Dialog,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import React from "react";
import { SyncLoader } from "react-spinners";

export const AutoCompleteInputBRH = ({
  filterSelectedOptions,
  defaultValue,
  handleChange,
  isSelectDataloaded = true,
  key,
  label,
  multiple,
  name,
  options,
  readOnly = false,
  required,
  size,
  sx,
  value,
}) => {
  return (
    <>
      <Autocomplete
        // sx={{ display: isSelectDataloaded ? 'block' : 'none' }}
        onChange={handleChange}
        multiple={multiple}
        id={name}
        key={key}
        value={value}
        options={options}
        defaultValue={defaultValue}
        filterSelectedOptions={filterSelectedOptions}
        getOptionLabel={(option) => {
          return option[name];
        }}
        // renderTags={(tagValue, getTagProps) =>
        //   tagValue.map((option, index) => (
        //     <Chip
        //       label={option[name]}
        //       {...getTagProps({ index })}
        //       // disabled={options.indexOf(option) !== -1}
        //     />
        //   ))
        // }
        // disableClearable
        renderInput={(params) => (
          <TextField {...params} label={label} required={required} />
        )}
        size={size}
        readOnly={readOnly}
        sx={{ ...sx, backgroundColor: readOnly ? "#f8f9fa" : "inherit" }}
      />

      <Dialog open={!isSelectDataloaded}>
        <Box
          sx={{
            width: "150px",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          {" "}
          <Typography variant="overline">Loading</Typography>
          <SyncLoader color="#fc9200" />
        </Box>
      </Dialog>
    </>
  );
};
