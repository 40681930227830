import React from "react";
import Sections from "./Sections";
import HeaderSection from "../HeaderSection/HeaderSection";
import Navbar from "../HeaderSection/Navbar";

const Dashboard = () => {
  return (
    <div>
      <Navbar />
      <HeaderSection />
      <Sections />
    </div>
  );
};

export default Dashboard;
