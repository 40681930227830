import React, { Component } from "react";
import axios from "axios";

//api

//const API_URL = "https://uatnew.berighthere.com/";

const API_URL = process.env.REACT_APP_API_URL;

// const API_URL = "http://13.233.76.246/";

let token_type = localStorage.getItem("token_type")
  ? localStorage.getItem("token_type")
  : "";
let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";

const ServiceLayer = {
  async postMethod(URL, Method, Data) {
    let url = API_URL + URL;

    return await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
      body: JSON.stringify(Data),
    });
  },

  postaMethod(URL, Method, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });
  },

  fileupload(URL, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });
  },

  async postMethodNotoken(URL, Method, Data) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn",
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/login";
      }
      return response;
    }
    return response;
  },

  async getDatabyToken(URL) {
    let url = API_URL + URL;
    const token = localStorage.getItem("token");
    const token_type = localStorage.getItem("token_type")
      ? localStorage.getItem("token_type")
      : "";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/login";
      }
      return response;
    }
    return response;
  },
  loginAPI(URL, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data);
  },

  fileputupload(URL, Data) {
    let url = API_URL + URL;

    return axios.put(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });
  },

  editData(URL, Data) {
    let url = API_URL + URL;

    return axios.put(url, Data, {
      headers: {
        Authorization: token_type + " " + token,
      },
    });
  },

  getData(URL) {
    let url = API_URL + URL;
    return fetch(url, {
      headers: {
        Authorization: token_type + " " + token,
      },
    });
  },
  getDatabyBody(URL, data) {
    let url = API_URL + URL;
    console.log(JSON.stringify(data), "datapayload");
    // return fetch(url, {
    //   headers: {
    //     Authorization: token_type + " " + token,
    //   },
    //   body: data,
    // });
    return axios.get(url, {
      headers: {
        Authorization: token_type + " " + token,
      },
      params: data,
    });
  },
  getDataToken(URL) {
    let url = API_URL + URL;
    return fetch(url, {
      headers: {
        Authorization: "2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn",
      },
    });
  },
  deleteData(URL) {
    let url = API_URL + URL;
    return axios.delete(url, {
      headers: {
        Authorization: token_type + " " + token,
      },
    });
  },
};

export default ServiceLayer;
