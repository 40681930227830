export const CORPORATE_LIST = "api/allcorporate?limit=1000";

export const ISSUE_CATEGORY_LIST = "issue-management/api/v1/issue-category";
export const PROPERTY_LIST = "issue-management/api/v1/property-list";

export const USER_LIST = "user-management/api/v1/user";
export const STATELIST = "user-management/api/v1/state";
export const CITYLIST = "user-management/api/v1/city";

export const CORPORATE_PROPERTY_LIST =
  "visitor-management/api/v1/get-corporate-properties";
export const CORPORATE_PROPERTY_EMPLOYEE =
  "visitor-management/api/v1/get-corporate-property-employee";
