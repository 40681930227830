import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

let token_type = localStorage.getItem("token_type")
  ? localStorage.getItem("token_type")
  : "";
let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";

export const getData = async (URL) => {
  let url = API_URL + URL;

  console.log("url = API_URL + URL", url);

  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
        Authorization: token_type + " " + token,
      },
    });

    console.log("Res1", response);

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("token_type");

      window.location.href = "/dashboard/login";
    }

    throw error;
  }
};

export const getMethod = (URL, signal) => {
  let url = API_URL + URL;

  return axios
    .get(url, {
      signal: signal,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/dashboard/login";
      }

      throw error;
    });
};

export const postMethod = (URL, Data) => {
  let url = API_URL + URL;

  return axios
    .post(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
        Authorization: token_type + " " + token,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/dashboard/login";
      }

      throw error;
    });
};

export const updateMethod = async (URL, Data) => {
  let url = API_URL + URL;

  try {
    const response = await axios.put(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
        Authorization: token_type + " " + token,
      },
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("token_type");

      window.location.href = "/dashboard/login";
    }

    throw error;
  }
};

export const fileupload = async (URL, Data) => {
  let url = API_URL + URL;

  try {
    const response = await axios.post(url, Data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });

    if (response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token_type");

      window.location.href = "/dashboard/login";
    }

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token_type");

      window.location.href = "/dashboard/login";
    }

    throw error;
  }
};
