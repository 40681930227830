import * as React from "react";

import CreateTicket from "./CreateTicketForm";
import { useDispatch, useSelector } from "react-redux";
import { closeEditModal, closeModal } from "./ModalSlice";
import { Modal } from "react-bootstrap";

export default function ModalComponent(props) {
  const modalClicked = useSelector((state) => state?.modal?.isModalClicked);
  const modalEditClicked = useSelector(
    (state) => state?.modal?.isEditModalClicked
  );

  const dispatch = useDispatch();

  return (
    <Modal
      show={modalClicked}
      onHide={() => dispatch(closeModal())}
      backdrop="static"
      keyboard={false}
      size="fullscreen"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Ticket</Modal.Title>
      </Modal.Header>

      <CreateTicket />
    </Modal>
  );
}
