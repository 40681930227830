import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";

export const DateInputBRH = ({
  name,
  label,
  value,
  handleChange,
  views,
  shouldDisableDate,
  key,
  format,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        format={format}
        label={label}
        name={name}
        value={value}
        views={views}
        // views={
        //   name === "monthAndYear" ? ["month", "year"] : ["year", "month", "day"]
        // }
        onChange={handleChange}
        shouldDisableDate={shouldDisableDate}
        key={key}
        // format="MMM-yyyy"
      />
    </LocalizationProvider>
  );
};
