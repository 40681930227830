import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import axios from "axios";
import CheckInModal from "./CheckInModal";
import InviteGuest from "./InviteGuest";
import moment from "moment";

import toast from "react-simple-toasts";
import UpdateGuestModal from "./UpdateGuestModal";
import { INVITEGUEST } from "./apiConfig";
import { Button } from "react-bootstrap";
import ConfirmModal from "../common/ConfirmModal";
import NavbarContainer from "../HeaderSection/Navbar";

const VisitorManagement = (props) => {
  // ----------->VARIABLES -------------->
  // const [isVisitorHistoryShow, setIsVisitorHistoryShow] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [activeVisitors, setActiveVisitors] = useState();
  const [visitors, setVisitors] = useState();
  const [upcomingVisitors, setUpcomingVisitors] = useState();
  const [checkInModalShow, setCheckInModalShow] = useState(false);
  const [inviteGuestodalShow, setInviteGuestModalShow] = useState(false);
  const [updateInviteModalShow, setUpdateInviteModalShow] = useState(false);
  const [formModalData, setFormModalData] = useState();
  const [updatedvisitors, setUpdatedVisitors] = useState();
  const [loadingTime, setloadingTime] = useState(false);
  const [propsList, setPropsList] = useState();
  const [accessDataSubmenu, setAccessDataSubmenu] = useState([]);
  const [accessDataFunctionality, setAccessDataFunctionality] = useState([]);
  //for modal confirm
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  // const [canProceed, setCanProceed] = useState(false);
  const [modalText, setModaltext] = useState();
  const [modalId, setModalId] = useState();
  const [modalStatus, setModalStatus] = useState();

  // ----------->FUNCTIONS -------------->
  // ----------->FUNCTIONS -------------->
  function formatTime(timeStr) {
    // Split the input time string by colon
    let [hours, minutes] = timeStr.split(':');

    // Pad single digit minutes with a zero
    minutes = minutes.padStart(2, '0');   
    let formattedTime = `${hours}:${minutes}`;
    
    // Parse and format the time using Moment.js
    return moment(formattedTime, 'HH:mm').format('h:mm A');
}

  const getAllGuests = () => {
    axios
      .get(INVITEGUEST)
      .then((res) => {
        console.log(res,"myres")
        if (res?.data?.status) {
          let filteredData = res?.data?.data;
          console.log(filteredData,"mydatas")
          filteredData.forEach((ele) => {
            if (
              ele.status === 1 &&
              ele.check_in === null &&
              ele.check_out === null
            ) {
             
              let tempFinalTime = new Date(ele.end_date_time);
              tempFinalTime.setMinutes(tempFinalTime.getMinutes() - 15);
              if (new Date(tempFinalTime) > new Date()) {
                ele.status = 1;
              } else if (new Date(tempFinalTime) < new Date()) {
                ele.status = 6;
              }
              //new-version
            } else if (
              ele.status === 2 &&
              ele.check_in !== null &&
              ele.check_out === null
            ) {
             
              if (
                ele.status === 2 &&
                new Date(ele.end_date_time).toDateString() ==
                  new Date().toDateString()
              ) {
                // console.log("hi", ele);
                if (new Date(ele.end_date_time) > new Date()) {
                  ele.status = 2;
                } else if (new Date(ele.end_date_time) < new Date()) {
                  ele.status = 3;
                }
              } else if (new Date(ele.end_date_time) > new Date()) {
                // console.log("greater", ele);
                ele.status = 2;
              } else if (new Date(ele.end_date_time) < new Date()) {
                // console.log("lesser", ele);
                if (ele.booking_type_id == 2 || ele.booking_type_id == 3) {
                  ele.check_out = new Date(
                    ele?.bookings?.end_date
                  ).toTimeString();
                  // console.log(
                  //   new Date(ele?.bookings?.end_date).toTimeString(),
                  //   "**"
                  // );
                } else if (ele.booking_type_id == 4) {
                  ele.check_out = ele?.property?.end_at;
                }
                ele.status = 7;
              }
            }
          });
          const resultActive = filteredData.filter(
            (data) => data?.status == 2 || data?.status == 8
           
          );
          resultActive.sort(function (a, b) {
            return new Date(a.invited_date) - new Date(b.invited_date);
          });
          resultActive.sort(function (a, b) {
            return (
              new Date(`${a.invited_date},${a.invited_time}`) -
              new Date(`${b.invited_date},${b.invited_time}`)
            );
          });
          console.log(resultActive,"resultActive");
          setActiveVisitors(resultActive);
          const resultUpcoming = filteredData.filter(
            (data) => data?.status < 2
          );
          resultUpcoming.sort(function (a, b) {
            return new Date(a.invited_date) - new Date(b.invited_date);
          });
          resultUpcoming.sort(function (a, b) {
            return (
              new Date(`${a.invited_date},${a.invited_time}`) -
              new Date(`${b.invited_date},${b.invited_time}`)
            );
          });
          console.log(resultUpcoming,"resultUpcoming");
          setUpcomingVisitors(resultUpcoming);
          const resultHistory = filteredData.filter(
            (data) => data?.status > 2 && data?.status < 8
          );

          resultHistory.sort(function (a, b) {
            return new Date(b.invited_date) - new Date(a.invited_date);
          });
          resultHistory.sort(function (a, b) {
            return (
              new Date(`${b.invited_date},${b.invited_time}`) -
              new Date(`${a.invited_date},${a.invited_time}`)
            );
          });
          console.log(resultHistory,"resultHistory");
          
          setVisitors(resultHistory);
        }
        console.log(res, "API-ALLGUESTS");
      })
      .catch((err) => console.log(err));
  };
  
  const makeCheckOut = (data, status) => {
    // return console.log(data,status);
    // setConfirmModalShow(true);
    // setModaltext('Check Out')
    // if (canProceed) {
    //   console.log("CALL API");
    // }

    return console.log("lakshmanan");

    let hours = new Date().getHours();
    let minutes = new Date().getMinutes();
    let temp = `${hours}:${minutes}`;
    // console.log(temp);
    const formdata = {
      check_out: temp,
      status: status,
    };
    axios
      .put(`${INVITEGUEST}/${data}`, formdata)
      .then((res) => {
        if (res?.data?.status) {
          toast(`${res?.data?.message}!`, {
            time: 2000,
            position: "top-right",
            clickable: true,
            clickClosable: true,
            className: "success",
          });
          setloadingTime(true);
          // window.location.reload();
        } else {
          toast("something went wrong. Please Try Again!", {
            time: 1000,
            position: "top-right",
            clickable: true,
            clickClosable: true,
            className: "error",
          });
        }
        console.log(res, "API-CHECKOUT");
      })
      .catch((err) => console.log(err));
  };
  // handle check in and checkout function
 // handle check in and checkout function
 const handleModal = (data) => {
  // console.log('laksh-handleModal',data);
  // console.log(data.checked_in_status);
  let currentDate = new Date().toDateString();
  let currentTime = new Date();
  let pastTime = new Date(`${new Date(new Date(data.invited_date).toDateString()).toDateString()},${data.invited_time}`);
  pastTime.setMinutes(pastTime.getMinutes() - 15);
  //old
  // let futureTime = new Date(`${data.invited_date},${data.invited_time}`);
  // futureTime.setMinutes(futureTime.getMinutes() + 15);
  //old
  //new
  let futureTime = new Date(`${data.end_date_time}`);
  futureTime.setMinutes(futureTime.getMinutes() - 15);
  console.log('laksh-1',currentDate,currentTime,pastTime,futureTime);
  //new
  if (
    data.checked_in_status == 1 &&
    moment(data.invited_date).format("YYYY-MM-DD") ==
      moment(currentDate).format("YYYY-MM-DD")
  ) {
    if (
      currentTime.toTimeString() > pastTime.toTimeString() &&
      currentTime.toTimeString() < futureTime.toTimeString()
    ) {
      // console.log(
      //   currentTime.toTimeString(),
      //   pastTime.toTimeString(),
      //   futureTime.toTimeString()
      // );
      // console.log(data);
      setCheckInModalShow(true);
      return setFormModalData(data);
    } else {
      toast("check in should be before 15mins only");
      // console.log("not aloow");
    }
  } else if (
    data.checked_in_status == 1 &&
    moment(data.invited_date).format("YYYY-MM-DD") >
      moment(currentDate).format("YYYY-MM-DD")
  ) {
    toast("check in should be done on that day only");
    // console.log("not aloowsss");
  } else if (data.checked_in_status == 2 || data.checked_in_status == 8) {
    // console.log(data.id, "--------------------->");
    let status = data.checked_in_status == 2 ? 3 : 7;
    // console.log(status);
    setModalId(data.id);
    setModalStatus(status);
    setModaltext("Check Out");
    return setConfirmModalShow(true);
    //  makeCheckOut(data.id, status);
    // console.log('log-out');
  }
};
  function makeTime(time) {
    let defaultTime = time;
    let edited = defaultTime.split(":");
    let final = `${edited[0]}:${edited[1]} Hrs`;
    return final;
  }
  function makeTimeVisiting(time) {
    let defaultTime = time;
    let edited = defaultTime.split(":");
    let final = `${edited[0]} Hours`;
    return final;
  }

  function guestForName(name) {
    if (name == 3) {
      return "Member";
    } else if (name == 2) {
      return "Corporate";
    } else if (name == 6) {
      return "Employee";
    } else if (name == 4) {
      return "Non - Booking";
    }
  }
  //it sets props to update guest modal
  const setPropsToId = (id) => {
    axios
      .get(`${INVITEGUEST}/${id}`)
      .then((res) => {
        if (res?.data?.status) {
          setPropsList(res?.data?.data);
        }
        console.log(res, "API-INVITEGUESTSHOW");
      })
      .catch((err) => console.log(err));
  };


  function formatTime(timeStr) {
    // Split the input time string by colon
    let [hours, minutes] = timeStr.split(':');

    // Pad single digit minutes with a zero
    minutes = minutes.padStart(2, '0');   
    let formattedTime = `${hours}:${minutes}`;
    
    // Parse and format the time using Moment.js
    return moment(formattedTime, 'HH:mm').format('h:mm A');
}

  // ----------->USEEFFECTS -------------->
  // useEffect(()=>{
  //   if(canProceed){
  //     handleModal()
  //   }
  // },[canProceed])
  useEffect(() => {
    getAllGuests();
    const data = props?.history?.location.state;
    console.log(data, "data");
    const userData = JSON.parse(localStorage.getItem("user"));
  }, []);
  useEffect(() => {
    let interval;
    if (loadingTime) {
      interval = setInterval(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loadingTime]);

  return (
    <>
      <NavbarContainer />
      <div className="searchStyle" style={{ padding: "20px" }}>
        <h4 className="breadcrumps_bg">Visitor List</h4>
        <div className="mb-2 d-flex justify-content-between">
          <div className="visitorpage">
            <Button
              className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
              onClick={() => {
                setActiveTab(1);
                getAllGuests();
              }}
              style={{
                backgroundColor: activeTab == 1 ? "#fff" : "#fc9200",
                color: activeTab == 1 ? "#fc9200" : "#fff",
                border: "1px solid rgb(252, 146, 0)",
                marginRight: "10px",
              }}>
              Upcoming Visitors
            </Button>
            <Button
              className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
              style={{
                backgroundColor: activeTab == 2 ? "#fff" : "#fc9200",
                color: activeTab == 2 ? "#fc9200" : "#fff",
                border: "1px solid rgb(252, 146, 0)",
                marginRight: "10px",
              }}
              onClick={() => {
                setActiveTab(2);
                getAllGuests();
              }}>
              Active Visitors
            </Button>
            <Button
              className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
              onClick={() => {
                setActiveTab(3);
                getAllGuests();
              }}
              style={{
                color: activeTab == 3 ? "#fc9200" : "#fff",
                backgroundColor: activeTab == 3 ? "#fff" : "#fc9200",
                border: "1px solid rgb(252, 146, 0)",
                marginRight: "10px",
              }}>
              Visitor History
            </Button>
          </div>
          <div className="">
            {accessDataFunctionality?.find(
              (i) => i.functionalty.func_name === "invite guest"
            )?.add_access === 1 ||
            accessDataFunctionality?.find(
              (i) => i.functionalty.func_name === "invite guest"
            )?.edit_access === 1 ? (
              <Button
                style={{
                  backgroundColor: "#fc9200",
                  border: "1px solid rgb(252, 146, 0)",
                }}
                className="btn btn-sm mb-2 mt-2  text-uppercase"
                onClick={() => setInviteGuestModalShow(true)}>
                <span>Add Visitor</span>
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#fc9200",
                  border: "1px solid rgb(252, 146, 0)",
                }}
                className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
                onClick={() => setInviteGuestModalShow(true)}
                // disabled={true}
              >
                <span>Add Visitor</span>
              </Button>
            )}
          </div>
        </div>
        {activeTab == 1 ? (
          <div className="mytbl">
            {/* {upcomingVisitors ? ( */}
             
                <MaterialTable
                  title=                 {<div style={{ color:"#21201e" , fontWeight:"bold" }} >Upcoming Visitors</div>}
                  className="vistorManagementTable"
                  columns={[
                    {
                      title: "Guest Name",
                      field:
                        "guest_name" +
                        "id" +
                        "user_id" +
                        "end_date_time" +
                        "start_date_time",
                      render: (rowData) => (
                        <>
                          <button
                            style={{ color: "dodgerblue" }}
                            className="visitor_row_button"
                            onClick={() => {
                              // console.log(rowData);
                              setUpdateInviteModalShow(true);
                              setUpdatedVisitors(rowData);
                              setPropsToId(rowData.id);
                            }}
                          >
                            {rowData.guest_name}
                            <br />
                            {/* <p style={{ display: "none" }}> {rowData.id}</p> */}
                          </button>
                          <UpdateGuestModal
                            propsList={propsList}
                            show={updateInviteModalShow}
                            setUpdateInviteModalShow={setUpdateInviteModalShow}
                            onHide={() => setUpdateInviteModalShow(false)}
                            id={updatedvisitors?.id}
                            userId={updatedvisitors?.user_id}
                            userEmail={updatedvisitors?.user_email_id}
                            userName={updatedvisitors?.invited_by}
                            guestName={updatedvisitors?.guest_name}
                            guestEmail={updatedvisitors?.guest_email}
                            invitedTo={updatedvisitors?.invited_to}
                            invitedDate={updatedvisitors?.invited_date}
                            invitedTime={updatedvisitors?.invited_time}
                            visitingHours={updatedvisitors?.visiting_hours}
                            checkInStatus={updatedvisitors?.checked_in_status}
                            invitedPurpose={updatedvisitors?.invited_purpose}
                            start_date_time={updatedvisitors?.start_date_time}
                            end_date_time={updatedvisitors?.end_date_time}
                          />
                        </>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Guest Email",
                      field: "guest_email",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited By",
                      field: "invited_by" + "guest_for",
                      render: (rowData) => (
                        <div>
                          {rowData.invited_by ? rowData.invited_by : "NA"}
                          <br />({guestForName(rowData.guest_for)})
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited By User's Email ID",
                      field: "user_email_id",
                      render: (rowdata) => (
                        <div>
                          {rowdata.user_email_id ? rowdata.user_email_id : "NA"}
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited To",
                      field: "invited_to" + "row_property_name",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                      render: (rowData) => (
                        <div>
                          {rowData.row_property_name.property_name}
                        </div>
                      ),
                    },
                    {
                      title: "Invited Date & Time",
                      field: "invited_date" + "invited_time",
                      width: 150,
                      render: (rowData) => (
                        <div>
                          {moment(rowData.invited_date).format("Do MMM YYYY")}
                          <br />
                           
                          {/* {moment(rowData.invited_time).format('hh:mm:a')} */}
                          {formatTime(rowData.invited_time)}

                          
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Visiting Hours",
                      field: "visiting_hours",
                      render: (rowData) => (
                        <div>
                          {makeTimeVisiting(rowData.visiting_hours)}
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited purpose",
                      field: "invited_purpose",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },

                    {
                      title: "Check-In Time",
                      field: "checked_in_time",
                      render: (rowdata) => (
                        <div>
                      
                      {rowdata.checked_in_time
                            ?formatTime( rowdata.checked_in_time) : "NA" }
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Check-Out Time",
                      field: "checked_out_time",
                      render: (rowdata) => (
                        <div>
                       
                       {rowdata.checked_out_time
                            ?formatTime( rowdata.checked_out_time) : "NA" }
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Check-In/Out Status",
                      field: "checked_in_status",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                      render: (row) => (
                        <>
                          {" "}
                          <button
                            onClick={() => {
                              // console.log(row.checked_in_status);
                              handleModal(row);
                            }}
                            className="visitor_row_button"
                            style={{
                              cursor:
                                row.checked_in_status == 3 ||
                                row.checked_in_status == 4 ||
                                row.checked_in_status == 5
                                  ? "default"
                                  : "pointer",
                              color:
                                row.checked_in_status == 3
                                  ? "#07bc0c"
                                  : row.checked_in_status == 4
                                  ? "#e74c3c"
                                  : row.checked_in_status == 5
                                  ? "#e74c3c"
                                  : "dodgerblue",
                              borderBottom:
                                row.checked_in_status == 4 ||
                                row.checked_in_status == 3 ||
                                row.checked_in_status == 5
                                  ? "none"
                                  : "1px solid dodgerblue",
                            }}
                          >
                            {/* {row.checked_in_status ? "Check Out" : "check In"} */}
                            {row.checked_in_status == 1
                              ? "Check In"
                              : row.checked_in_status == 2
                              ? "Check Out"
                              : "test"}
                          </button>
                          <CheckInModal
                            show={checkInModalShow}
                            onHide={() => setCheckInModalShow(false)}
                            id={formModalData?.id}
                            guest_name={formModalData?.guest_name}
                            guest_email={formModalData?.guest_email}
                            invited_by={formModalData?.invited_by}
                            user_email_id={formModalData?.user_email_id}
                            invited_to={formModalData?.invited_to}
                            invited_date={formModalData?.invited_date}
                            invited_time={formModalData?.invited_time}
                            visiting_hours={formModalData?.visiting_hours}
                            invited_purpose={formModalData?.invited_purpose}
                            checked_in_time={formModalData?.check_in}
                            checked_out_time={formModalData?.check_out}
                            Checked_in_status={formModalData?.status}
                            end_date_time={formModalData?.end_date_time}
                            start_date_time={formModalData?.start_date_time}
                            property_name={
                              formModalData?.row_property_name?.property_name
                            }
                          />
                        </>
                      ),
                    },
                  ]}
                  data={upcomingVisitors?.map((visitor) => {
                    return {
                      id: visitor.id,
                      user_id: visitor.user_id,
                      guest_for: visitor.booking_type_id,
                      guest_name: visitor.guest_name,
                      guest_email: visitor.guest_email,
                      invited_by: visitor.user_name,
                      user_email_id: visitor.user_email,
                      invited_to: visitor.invited_property_id,
                      invited_date: visitor.invited_date,
                      invited_time: visitor.invited_time,
                      visiting_hours: visitor.visiting_hours,
                      invited_purpose: visitor.invited_purpose,
                      checked_in_time: visitor.check_in,
                      checked_out_time: visitor.check_out,
                      checked_in_status: visitor.status,
                      start_date_time: visitor.start_date_time,
                      end_date_time: visitor.end_date_time,
                      row_property_name: visitor.property,
                    };
                  })}
                  options={{
                    search: true,
                    addRowPosition: "first",
                  }}
                />
              
           
          </div>
        ) : activeTab == 2 ? (
        
          <div className="mytbl">
                <MaterialTable
                  title={<div style={{ color:"#21201e" , fontWeight:"bold" }} >Active Visitor </div>}
                  className="vistorManagementTable"
                  columns={[
                    {
                      title: "Guest Name",
                      field:
                        "guest_name" +
                        "id" +
                        "user_id" +
                        "end_date_time" +
                        "start_date_time",
                      render: (rowData) => (
                        <>
                          <button
                            style={{ color: "dodgerblue" }}
                            className="visitor_row_button"
                            onClick={() => {
                              // console.log(rowData);
                              setUpdateInviteModalShow(true);
                              setUpdatedVisitors(rowData);
                              setPropsToId(rowData.id);
                            }}
                          >
                            {rowData.guest_name}
                            <br />
                            {/* <p style={{ display: "none" }}> {rowData.id}</p> */}
                          </button>
                          <UpdateGuestModal
                            propsList={propsList}
                            show={updateInviteModalShow}
                            setUpdateInviteModalShow={setUpdateInviteModalShow}
                            onHide={() => setUpdateInviteModalShow(false)}
                            id={updatedvisitors?.id}
                            userId={updatedvisitors?.user_id}
                            userEmail={updatedvisitors?.user_email_id}
                            userName={updatedvisitors?.invited_by}
                            guestName={updatedvisitors?.guest_name}
                            guestEmail={updatedvisitors?.guest_email}
                            invitedTo={updatedvisitors?.invited_to}
                            invitedDate={updatedvisitors?.invited_date}
                            invitedTime={updatedvisitors?.invited_time}
                            visitingHours={updatedvisitors?.visiting_hours}
                            checkInStatus={updatedvisitors?.checked_in_status}
                            invitedPurpose={updatedvisitors?.invited_purpose}
                            start_date_time={updatedvisitors?.start_date_time}
                            end_date_time={updatedvisitors?.end_date_time}
                          />
                        </>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Guest Email",
                      field: "guest_email",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited By",
                      field: "invited_by" + "guest_for",
                      render: (rowData) => (
                        <div>
                          {rowData.invited_by ? rowData.invited_by : "NA"}
                          <br />({guestForName(rowData.guest_for)})
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited By User's Email ID",
                      field: "user_email_id",
                      render: (rowdata) => (
                        <div>
                          {rowdata.user_email_id ? rowdata.user_email_id : "NA"}
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited To",
                      field: "invited_to" + "row_property_name",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                      render: (rowData) => (
                        <div>
                          {rowData.row_property_name.property_name}
                        </div>
                      ),
                    },
                    {
                      title: "Invited Date & Time",
                      field: "invited_date" + "invited_time",
                      width: 150,
                      render: (rowData) => (
                        <div>
                          {moment(rowData.invited_date).format("Do MMM YYYY")}
                          <br />
                          {/* makeTime(rowData.invited_time) */}
                          {formatTime(rowData.invited_time)}
                          
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Visiting Hours",
                      field: "visiting_hours",
                      render: (rowData) => (
                        <div>
                          {makeTimeVisiting(rowData.visiting_hours)}
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Invited purpose",
                      field: "invited_purpose",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },

                    {
                      title: "Check-In Time",
                      field: "checked_in_time",
                      render: (rowdata) => (
                        <div>
                          {/* {rowdata.checked_in_time
                            ? makeTime(rowdata.checked_in_time)
                            : "NA"} */}
                          
                          {rowdata.checked_in_time
                            ?formatTime( rowdata.checked_in_time) : "NA" }
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Check-Out Time",
                      field: "checked_out_time",
                      render: (rowdata) => (
                        <div>
                          {/* {rowdata.checked_out_time
                            ? moment(
                                `${rowdata.invited_date},${rowdata.checked_out_time}`
                              ).format("hh : mm : A")
                            : "NA"} */}
                              {rowdata.checked_out_time
                            ?formatTime( rowdata.checked_out_time)
                            : "NA"}
                        </div>
                      ),
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                    },
                    {
                      title: "Check-In/Out Status",
                      field: "checked_in_status",
                      headerStyle: {
                        fontWeight: "700",
                        color: "#212529",
                        fontSize: "14px",
                      },
                      render: (row) => (
                        <>
                          {" "}
                          <button
                            onClick={() => {
                              // console.log(row.checked_in_status);
                              handleModal(row);
                            }}
                            className="visitor_row_button"
                            style={{
                              cursor:
                                row.checked_in_status == 3 ||
                                row.checked_in_status == 4 ||
                                row.checked_in_status == 5
                                  ? "default"
                                  : "pointer",
                              color:
                                row.checked_in_status == 3
                                  ? "#07bc0c"
                                  : row.checked_in_status == 4
                                  ? "#e74c3c"
                                  : row.checked_in_status == 5
                                  ? "#e74c3c"
                                  : "dodgerblue",
                              borderBottom:
                                row.checked_in_status == 4 ||
                                row.checked_in_status == 3 ||
                                row.checked_in_status == 5
                                  ? "none"
                                  : "1px solid dodgerblue",
                            }}
                          >
                            {/* {row.checked_in_status ? "Check Out" : "check In"} */}
                            {row.checked_in_status == 1
                              ? "Check In"
                              : row.checked_in_status == 2
                              ? "Check Out"
                              : row.checked_in_status == 8
                              ? "Over Due"
                              : ""}
                          </button>
                        </>
                      ),
                    },
                  ]}
                  data={activeVisitors?.map((visitor) => {
                    return {
                      id: visitor.id,
                      user_id: visitor.user_id,
                      guest_for: visitor.booking_type_id,
                      guest_name: visitor.guest_name,
                      guest_email: visitor.guest_email,
                      invited_by: visitor.user_name,
                      user_email_id: visitor.user_email,
                      invited_to: visitor.invited_property_id,
                      invited_date: visitor.invited_date,
                      invited_time: visitor.invited_time,
                      visiting_hours: visitor.visiting_hours,
                      invited_purpose: visitor.invited_purpose,
                      checked_in_time: visitor.check_in,
                      checked_out_time: visitor.check_out,
                      checked_in_status: visitor.status,
                      start_date_time: visitor.start_date_time,
                      end_date_time: visitor.end_date_time,
                      row_property_name: visitor.property,
                    };
                  })}
                  options={{
                    search: true,
                    addRowPosition: "first",
                  }}
                />
              </div>
            // ) : (
            //   <>
            //     <div className="visitor_empty_list_container">
            //       <p className="visitor_empty_list">No upcoming visitors</p>
            //     </div>
            //   </>
            // )}
          // </div>
        ) : (
          <div className="mytbl">
            {/* {visitors ? (
              <> */}
               <MaterialTable
              title={<div style={{ color:"#21201e" , fontWeight:"bold" }} >Visitor History</div>}
              className="vistorManagementTable"
              columns={[
                {
                  title: "Guest Name",
                  field: "guest_name" + "id" + "user_id",
                  render: (rowData) => (
                    <div style={{ color: "inherit" }}>
                      {rowData.guest_name}
                      <br />
                      <p style={{ display: "none" }}> {rowData.id}</p>
                    </div>
                  ),

                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Guest Email",
                  field: "guest_email",
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Invited By",
                  field: "invited_by" + "guest_for",

                  render: (rowData) => (
                    <div>
                      {rowData.invited_by ? rowData.invited_by : "NA"}
                      <br />({guestForName(rowData.guest_for)})
                    </div>
                  ),
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Invited By User's Email ID",
                  field: "user_email_id",
                  render: (rowdata) => (
                    <div className="">
                      {rowdata.user_email_id ? rowdata.user_email_id : "NA"}
                    </div>
                  ),
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Invited To",
                  field: "invited_to" + "row_property_name",
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                  render: (rowData) => (
                    <div className="">
                      {rowData.row_property_name.property_name}
                    </div>
                  ),
                },
                {
                  title: "Invited Date & Time",
                  field: "invited_date" + "invited_time",
                  width: 150,
                  render: (rowData) => (
                    <div className="" style={{ minWidth: "110px" }}>
                      {moment(rowData.invited_date).format("Do MMM YYYY")}
                      <br />
                      {rowData.invited_time}
                    </div>
                  ),
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                  
                },
                {
                  title: "Visiting Hours",
                  field: "visiting_hours",
                  render: (rowData) => (
                    <div className="">
                      {makeTimeVisiting(rowData.visiting_hours)}
                    </div>
                  ),
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Invited purpose",
                  field: "invited_purpose",
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Checked-In Time",
                  field: "checked_in_time",
                  render: (rowdata) => (
                    <div className="">
                     
                     {rowdata.checked_in_time
                            ? formatTime( rowdata.checked_in_time) : "NA" }
                    </div>
                  ),
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Checked-Out Time",
                  field: "checked_out_time",
                  render: (rowdata) => (
                    <div className="">
                    
                          {rowdata.checked_out_time
                            ?formatTime( rowdata.checked_out_time) : "NA" }
                    </div>
                  ),
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                },
                {
                  title: "Checked-In Status",
                  field: "checked_in_status",
                  headerStyle: {
                    fontWeight: "700",
                    color: "#212529",
                    fontSize: "14px",
                  },
                  render: (row) => (
                    <>
                      {" "}
                      <p
                        style={{
                          color:
                            row.checked_in_status == 3
                              ? "#07bc0c"
                              : row.checked_in_status == 4
                              ? "#e74c3c"
                              : row.checked_in_status == 5
                              ? "#e74c3c"
                              : row.checked_in_status == 7 ||
                                row.checked_in_status == 8
                              ? "orange"
                              : "dodgerblue",
                        }}>
                        {row.checked_in_status == 1
                          ? "Check In"
                          : row.checked_in_status == 2
                          ? "Check Out"
                          : row.checked_in_status == 3
                          ? "Completed"
                          : row.checked_in_status == 4
                          ? "Cancelled"
                          : row.checked_in_status == 5
                          ? "Rejected"
                          : row.checked_in_status == 6
                          ? "No Show"
                          : row.checked_in_status == 7
                          ? " Over Due"
                          : row.checked_in_status == 8
                          ? " Over Due"
                          : ""}
                      </p>
                      <CheckInModal
                        show={checkInModalShow}
                        onHide={() => setCheckInModalShow(false)}
                        id={formModalData?.id}
                        guest_name={formModalData?.guest_name}
                        guest_email={formModalData?.guest_email}
                        invited_by={formModalData?.invited_by}
                        user_email_id={formModalData?.user_email_id}
                        invited_to={formModalData?.invited_to}
                        invited_date={formModalData?.invited_date}
                        invited_time={formModalData?.invited_time}
                        visiting_hours={formModalData?.visiting_hours}
                        invited_purpose={formModalData?.invited_purpose}
                        checked_in_time={formModalData?.checkIn}
                        checked_out_time={formModalData?.checkOut}
                        Checked_in_status={formModalData?.checkInStatus}
                      />
                    </>
                  ),
                },
              ]}
              data={visitors?.map((visitor) => {
                return {
                  id: visitor.id,
                  user_id: visitor.user_id,
                  guest_for: visitor.booking_type_id,
                  guest_name: visitor.guest_name,
                  guest_email: visitor.guest_email,
                  invited_by: visitor.user_name,
                  user_email_id: visitor.user_email,
                  invited_to: visitor.invited_property_id,
                  invited_date: visitor.invited_date,
                  invited_time: visitor.invited_time,
                  visiting_hours: visitor.visiting_hours,
                  invited_purpose: visitor.invited_purpose,
                  checked_in_time: visitor.check_in,
                  checked_out_time: visitor.check_out,
                  checked_in_status: visitor.status,
                  row_property_name: visitor.property,
                };
              })}
              options={{
                search: true,
                addRowPosition: "first",
              }}
            />
              {/* </>
            ) : (
              <>
                <div className="visitor_empty_list_container">
                  <p className="visitor_empty_list">
                    visitor history is empty!
                  </p>
                </div>
              </>
            )} */}
          </div>
        )}
         <CheckInModal
                        show={checkInModalShow}
                        onHide={() => setCheckInModalShow(false)}
                        id={formModalData?.id}
                        guest_name={formModalData?.guest_name}
                        guest_email={formModalData?.guest_email}
                        invited_by={formModalData?.invited_by}
                        user_email_id={formModalData?.user_email_id}
                        invited_to={formModalData?.invited_to}
                        invited_date={formModalData?.invited_date}
                        invited_time={formModalData?.invited_time}
                        visiting_hours={formModalData?.visiting_hours}
                        invited_purpose={formModalData?.invited_purpose}
                        checked_in_time={formModalData?.checkIn}
                        checked_out_time={formModalData?.checkOut}
                        Checked_in_status={formModalData?.checkInStatus}
                      />
        <InviteGuest
          show={inviteGuestodalShow}
          onHide={() => setInviteGuestModalShow(false)}
        />
      </div>
      <ConfirmModal
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        // canProceed={canProceed}
        // setCanProceed={setCanProceed}
        modalText={modalText}
        modalId={modalId}
        modalStatus={modalStatus}
        setloadingTime={setloadingTime}
      />
    </>
  );
};
export default VisitorManagement;
