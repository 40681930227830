import NavbarContainer from "../HeaderSection/Navbar";
import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
  Navigate,
} from "react-big-calendar";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import toast from "react-simple-toasts";
import API from "./API_Services/ServiceLayer";
import { Select } from "../common";

import Tooltip from "react-tooltip-lite";
import { Token } from "react-bootstrap-typeahead";
import Spinner from "../Shared/Spinner";
import { Grid } from "@material-ui/core";
import "../Dashboards/BookMeeting.css";
import UpcomingReservaton from "./UpcomingReservation";
import { width } from "@mui/system";
const localizer = momentLocalizer(moment);
const userData = JSON.parse(localStorage.getItem("user"));

const TIMER = process.env.REACT_APP_TIMER;

const CustomEventRenderer = ({ event }) => {
  // console.log("Rendering event:", event); // Debugging

  const startDate = moment(event.start_date).format("DD-MMM-YY -h:mmA");
  const endDate = moment(event.end_date).format("DD-MMM-YY -h:mmA");
  const userName = event.user ? event.user.name : "Unknown";

  const tooltipContent = `\n Slot: Booked!\n Booked User: ${userName}\n Check in: ${startDate}\n Check out: ${endDate}\n `;

  return (
    <div title={tooltipContent} className="custom-event">
      {userName}
    </div>
  );
};

const BookMeeting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const grayGradientStyle = {
    background: "linear-gradient(to right, gray, lightgray)",
    borderColor: "gray",
    color: "white",
  };
  const colorGradientStyle = {
    background: "linear-gradient(90deg, #fdd195, #fc9200)",
    // borderColor: "#fc9200",
    border: "none",
    outline: "none",
    color: "white",
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const today = new Date();
  const endtime = new Date();

  const [earliestAllowedTimeEnd, setEarliestAllowedTimeEnd] = useState(null);
  const [latestAllowedTimeEnd, setLatestAllowedTimeEnd] = useState(null);

  const [earliestAllowedTime, setEarliestAllowedTime] = useState(null);
  const [latestAllowedTime, setLatestAllowedTime] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false); // State to manage calendar modal visibility
  const [calendarButtonDisabled, setCalendarButtonDisabled] = useState(true); // State to manage calendar button disable/enable

  const [typeresorce, settyperesorce] = useState();
  const [EventTiming, setEventTiming] = useState();
  const [extras, setextras] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [mycuststart, setmycuststart] = useState();
  const [mycustend, setmycustend] = useState();

  const [startSlots, setStartSlots] = useState([]);
  const [endSlots, setEndSlots] = useState([]);
  const [myeventend, setemyventend] = useState();
  const [myeventstart, setmyeventstart] = useState();
  const [userNames, setUserNames] = useState(new Set());
  const [calData, setCalData] = useState([]);
  //const [currentView, setCurrentView] = useState("day");
  const [currentView, setCurrentView] = React.useState(Views.DAY);
  const [selectedSlot, setSelectedSlot] = useState(null); // Track selected time slot
  const [show, setShow] = useState(false);
  const [Eventshow, setEventshow] = useState(false);
  const [minDate, setMinDate] = useState(today);
  const [maxDate, setMaxDate] = useState("");
  const [customMinDate, setCustomMinDate] = useState(null);
  const [customMaxDate, setCustomMaxDate] = useState(null);
  const [slprop, setSlprop] = useState(false);
  const [resourceGroup, setResourceGroup] = useState([]);
  const [resources, setResources] = useState([]);
  const [properties, setProperties] = useState([]);
  const [checkingresourceid, setcheckingresourceid] = useState(false);
  const [startTimeOptions, setStartTimeOptions] = useState([]);
  const [endTimeOptions, setEndTimeOptions] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [calPropName, setCalPropName] = useState(null);
  const [calResName, setCalResName] = useState(null);
  const [Event_Title, setEvent_Title] = useState("");
  const [noapicall, setnoApicall] = useState(false);
  const [mystarttime, setmystarttime] = useState("");
  const [myendtime, setmyendtime] = useState("");
  const [change, setchange] = useState(false);
  const [newstarttime, setnewstarttime] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [mydate, setmydate] = useState("");
  const [eventtitle, seteventtitle] = useState("");
  const [mytimings, setmytimings] = useState([]);
  const [newtimeOptions, setnewtimeOptions] = useState([]);
  const [lengthofResourceGroup, setlengthofResourceGroup] = useState([]);
  const [alreadybookedid, setalredybookedid] = useState("");
  const [editedmodalshow, setEditedModalshow] = useState(false);
  const [alreadystarttime, setalreadystarttime] = useState("");
  const [alreadystartdate, setalreadystartdate] = useState("");
  const [alreadyendtime, setalreadyendtime] = useState("");
  const [Alemyventend, setAlemyventend] = useState();
  const [Almyeventstart, setAlmyeventstart] = useState();
  const [cancelreason, setcancelreason] = useState("");
  const [updatetitle, setupdatetitle] = useState("");
  const [resourcevalue, setresourcevalue] = useState("");

  const [employees, setEmployees] = useState([]);
  const [employeebookingfor, setEmployeebookingfor] = useState();
  // const [propertyid, setpropertyid] = useState();
  const [getreservationpayload, setgetreservationpayload] = useState({});
  const [deriveddate, setderiveddate] = useState("");
  const [cancelinput, setcancelinput] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const [formData, setFormData] = useState({
    reserve_type_id: 2,
    no_of_people: 1,
    start_date: null,
    end_date: null,
    corporate_id: null,
  });

  // console.log(alreadybookedid, "alllr");

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const showMyCancelInput = () => {
    setcancelinput(!cancelinput);
  };
  const ForceCloseCancel = () => {
    setcancelreason("");
    setcancelinput(!cancelinput);
  };
  // console.log(alreadybookedid, "latestAllowedTime");
  // Function to check if all required fields are filled and enable/disable the button accordingly
  const checkButtonDisabled = useCallback(() => {
    // Check if all required fields are filled
    const isAllFieldsFilled =
      getreservationpayload.property_id &&
      getreservationpayload.resource_group_id &&
      getreservationpayload.resource_id;

    // Update the state of the button based on the condition
    setIsButtonDisabled(!isAllFieldsFilled);
  }, [getreservationpayload]);

  const handlediteDataClose = () => {
    setEditedModalshow(false);
    // setChangingstate(false)
    setcancelinput(false);
    setcancelreason("");
  };

  useEffect(() => {
    const fetchReservationHistory = async () => {
      const vdata = {
        property_id: getreservationpayload.property_id,
        resource_group_id: getreservationpayload.resource_group_id,
        resource_id: getreservationpayload.resource_id,
      };

      try {
        const response = await API.postMethodNotoken(
          "api/getreservationhistory",
          "POST",
          vdata
        );

        const data = await response.json();
        // console.log("<><><<><><><><<>>>>>", response);
        if (data.status === true) {
          // console.log(data.data, "mydatas");
          if (data.length < 1) {
            // console.log("that is empty data");
          }

          // console.log(data, "propdta");

          //   "0": {
          //     "start_at": "09:00",
          //     "end_at": "18:30"
          // }
          setmyendtime(data["0"]?.end_at);
          setmystarttime(data["0"]?.start_at);

          const bookedEvents = data.data.map((event) => ({
            ...event,
            property_start_time: mystarttime,
            property_end_time: myendtime,
          }));

          // console.log(mystarttime, myendtime, "mytimings");

          const earliestTime = Math.min(
            ...bookedEvents.map((event) => {
              const [hours, minutes] = mystarttime.split(":");
              const date = new Date(formData.start_date);
              date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
              return date.getTime(); // Convert to milliseconds for comparison
            })
          );

          const latestTime = Math.max(
            ...bookedEvents.map((event) => {
              const [hours, minutes] = myendtime.split(":");
              const date = new Date(formData.start_date);
              date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
              return date.getTime(); // Convert to milliseconds for comparison
            })
          );
          // console.log(new Date(earliestTime));
          setLatestAllowedTime(latestTime);
          const now = new Date();

          const adjustedEarliestTime = Math.max(earliestTime, now.getTime());

          setEarliestAllowedTime(adjustedEarliestTime);
          setLatestAllowedTime(latestTime);

          // console.log(
          //   `Adjusted Earliest: ${new Date(
          //     adjustedEarliestTime
          //   )}, Latest: ${new Date(latestTime)}`
          // );
        }
      } catch (error) {
        console.error("Error fetching reservation history:", error);
      }
    };

    fetchReservationHistory(); // Call function in useEffect to ensure data is fetched on component mount
  }, [
    formData.property_id,
    formData.resource_group_id,
    formData.resource_id,
    mydate,
  ]);

  // Call the function whenever any of the form data changes
  useEffect(() => {
    checkButtonDisabled();
  }, [checkButtonDisabled]);

  const handleClose = () => setShow(false);
  const handleDataClose = () => setEventshow(false);

  useEffect(() => {
    // Check if all required fields are filled to enable/disable calendar button
    const { property_id, resource_group_id, resource_id } = formData;
    if (property_id && resource_group_id && resource_id) {
      // handleInput();
      setCalendarButtonDisabled(false); // Enable button if all fields are filled
    } else {
      setCalendarButtonDisabled(true); // Disable button if any field is empty
    }
  }, [formData]);

  function roundUpToNearestHalfHour(date) {
    const minutes = date.getMinutes();

    // Determine if it needs rounding up to the next half-hour
    const roundedMinutes = minutes > 0 && minutes <= 30 ? 30 : 0;
    const addHours = minutes > 30 ? 1 : 0;

    const newDate = new Date(date);
    newDate.setMinutes(roundedMinutes);
    newDate.setSeconds(0); // Clear seconds and milliseconds
    newDate.setMilliseconds(0);

    if (addHours) {
      newDate.setHours(newDate.getHours() + 1);
    }

    return newDate;
  }

  useEffect(() => {
    _getCorporates();
  }, []);

  const _getCorporates = async () => {
    const arr = [];
    await API.getDatabyToken("api/allcorporate")
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          let corporateData = result.data.filter((stat) => {
            if (stat.status === 1) {
              return stat;
            }
          });
          // corporateData.map((corp) => {
          //   return arr.push({ value: corp.id, label: corp.name });
          // });
          // console.log(corporateData, "arr");
          setCorporates(corporateData);
        } else {
          console.log(result.status);
        }
      });
  };

  function _getProperties(d) {
    console.log(d, "myid");
    API.postMethodNotoken("api/employee-property-list", "POST", {
      emp_id: d,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // console.log(data, "myempprop");
          setProperties(data.result.data);
          // console.log(data.result.data[0]?.start_at, "myreses");
          setmycuststart(data.result.data[0]?.start_at);
          setmycustend(data.result.data[0]?.end_at);
        } else if (data.status === "error") {
          Swal.fire({
            html: "<i>" + data.message + "</i>",
            icon: data.status,
            showConfirmButton: false,
            timer: TIMER,
          });
          setFormData((prevFormData) => ({
            ...prevFormData,
            resource_group_id: "", // Assuming formData is a state variable managed by useState
          }));

          // setFormData(prevFormData => ({
          //   ...prevFormData,
          //   property_id: ""  // Assuming formData is a state variable managed by useState
          // }));

          setFormData((prevFormData) => ({
            ...prevFormData,
            resource_id: "", // Assuming formData is a state variable managed by useState
            // id:"",
          }));

          // setFormData(prevFormData => ({
          //   ...prevFormData,
          //   property_id: ""  // Assuming formData is a state variable managed by useState
          // }));
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
        // Handle error
        // Example: Display error message to the user
        toast("Error fetching properties. Please try again later.", TIMER);
      });
  }

  const [changingState, setChangingstate] = useState(false);

  const handleDateChange = (date) => {
    // setChangingstate(true);
    console.log(date, "mydatess");
    // Update the state with the selected date
    // console.log(changingState, "mys");
    setderiveddate(date);
    setchange(true);
    // console.log(deriveddate);
    const mysetdate = moment(date).format("dddd MMM DD");
    const mysetformat = moment(date, "DD-MMM-YY");
    const myformattime = moment().format("h:mmA");

    const combinedDateTime =
      mysetformat.format("ddd MMM DD YYYY ") + myformattime;

    // Get the timezone offset in minutes
    const timezoneOffset = moment().utcOffset();

    // Format the combined date and time with timezone information
    const formattedDateTime = moment(combinedDateTime, "ddd MMM DD YYYY h:mmA")
      .utcOffset(timezoneOffset)
      .format();

    // Example usage:
    // console.log(formattedDateTime);

    // Convert to the desired timezone
    // const formattedDateTime = moment.tz(combinedDateTime, 'ddd MMM DD YYYY h:mmA', 'Asia/Kolkata').format();

    // 10-May-24 -12:00AM

    // console.log(formattedDateTime, "combinedDateTime");
    setalreadystartdate(mysetdate);
    setStartTimeOptions([]);

    const timeSlots = [];
    // console.log(mytimings, "mytimings");
    // console.log(mystarttime, myendtime, "mystrt");

    const [startHour, startMinute] = mystarttime.split(":").map(Number);
    const [endHour, endMinute] = myendtime.split(":").map(Number);

    let currentHour = startHour;
    let currentMinute = startMinute;

    const today = new Date();

    const formattedDate1 = moment(today).format(
      "ddd MMM DD YYYY HH:mm [GMT]Z (z)"
    );
    const formattedDate2 = moment(formattedDateTime).format(
      "ddd MMM DD YYYY HH:mm [GMT]Z (z)"
    );

    // console.log(today, "combinedDateTime");

    // console.log(formattedDate1, formattedDate2, "istoday");

    if (formattedDate1 === formattedDate2) {
      // Parse the start moment using the formattedDate2
      const startMoment = moment(
        formattedDate2,
        "ddd MMM DD YYYY HH:mm [GMT]Z (z)"
      );

      // Adjust the start time to the next 30-minute interval
      if (startMoment.minutes() < 30) {
        startMoment.minutes(30);
      } else {
        startMoment.add(1, "hour").startOf("hour").minutes(0);
      }

      // Generate time slots until the end time
      const timeSlots = [];
      while (
        startMoment.hours() < endHour ||
        (startMoment.hours() === endHour && startMoment.minutes() <= endMinute)
      ) {
        // console.log("todat date");
        let amPm = "AM";
        let formattedHour = startMoment.hours();

        if (startMoment.hours() >= 12) {
          amPm = "PM";
          formattedHour =
            startMoment.hours() === 12 ? 12 : startMoment.hours() - 12;
        }

        if (formattedHour === 0) {
          formattedHour = 12; // Convert 0 to 12 for 12-hour clock
        }

        const timeSlot = `${formattedHour
          .toString()
          .padStart(2, "0")}:${startMoment
          .minutes()
          .toString()
          .padStart(2, "0")} ${amPm}`;
        timeSlots.push(timeSlot);

        // Move to next 30-minute interval
        startMoment.add(30, "minutes");
      }

      // console.log(timeSlots);
      setnewtimeOptions(timeSlots);
    } else {
      // Dates are not equal
      // console.log("future date");

      while (
        currentHour < endHour ||
        (currentHour === endHour && currentMinute + 30 <= endMinute)
      ) {
        // console.log("other date");

        let amPm = "AM";
        let formattedHour = currentHour;

        if (currentHour >= 12) {
          amPm = "PM";
          formattedHour = currentHour === 12 ? 12 : currentHour - 12;
        }

        if (formattedHour === 0) {
          formattedHour = 12; // Convert 0 to 12 for 12-hour clock
        }
        const timeSlot = `${formattedHour
          .toString()
          .padStart(2, "0")}:${currentMinute
          .toString()
          .padStart(2, "0")} ${amPm}`;
        timeSlots.push(timeSlot);

        currentMinute += 30;
        if (currentMinute >= 60) {
          currentHour++;
          currentMinute -= 60;
        }
      }
      setnewtimeOptions(timeSlots);
    }

    // console.log(timeSlots);
  };

  useEffect(() => {
    const myres_pro = resources.find(
      (reso) => reso.resource_group_id === formData.resource_group_id
    );

    // console.log(myres_pro, "myres_pro");

    setCalResName(myres_pro);
  }, []);

  const [corporvalue, setcorporvalue] = useState();

  const handlecorporates = ({ currentTarget: input, selectedOption }) => {
    const inputData = { ...formData };

    const value = /^\d*$/.test(input.value) ? +input.value : input.value;
    // console.log(value, "value");

    setcorporvalue(value);

    // console.log(corporates, "mycorps");

    const foundCorporate = corporates.filter(
      (corporate) => corporate.id === value
    );
    // If a corporate is found, set its properties as the filtered properties
    // Otherwise, set an empty array
    // setProperties(foundCorporate ? foundCorporate[0]?.properties : []);
    setEmployees(foundCorporate ? foundCorporate[0]?.employees : []);
    // console.log(properties, "proper");
    // console.log(employees, "myfilters");
  };

  const handleInput = ({ currentTarget: input, selectedOption }) => {
    const inputData = { ...formData };

    const value = /^\d*$/.test(input.value) ? +input.value : input.value;
    console.log(value, "value");
    setgetreservationpayload({
      ...getreservationpayload,
      [input.name]: value,
    });

    if (!formData.resource_id) {
      setcheckingresourceid(true);

      // console.log("my");
    } else {
      // console.log("no");
      setcheckingresourceid(false);
    }

    inputData[input.name] = value;
    if (input.name === "reserve_type_id") {
      inputData.property_id = "";
      inputData.resource_group_id = "";
      inputData.resource_id = "";
    }
    if (input.name === "property_id") {
      inputData.resource_group_id = "";
      inputData.resource_id = "";
      if (input.name === "resource_group_id") {
        inputData.resource_id = "";
      }
    }
    // console.log(checkingresourceid, "checkingresourceid");
    // settyperesorce(formData.resource_group_id);

    // console.log("myresii", typeresorce);
    let user_name;
    // setresrce(formData.resource_id);

    // setpropertyid(value);
    console.log(getreservationpayload, "getreserv");

    let vdata = {
      property_id: getreservationpayload.property_id,
      resource_group_id: getreservationpayload.resource_group_id,
      resource_id: inputData.resource_id,
    };

    console.log(vdata, "vdata");

    const matchedProperty = properties.find(
      (property) => property.id === inputData.property_id
    );

    console.log(matchedProperty, "matchedProperty");

    if (matchedProperty) {
      setCalPropName(matchedProperty.property_name);
      const matchedResource = matchedProperty.resources.find(
        (resource) => resource.id === inputData.resource_id
      );

      if (matchedResource) {
        // If a matching resource is found, set the resource description
        setCalResName(matchedResource.resource_name);
      }
    }
    if (input.name === "resource_id") {
      console.log(vdata, "vdata");
      API.postMethodNotoken("api/getreservationhistory", "POST", vdata)
        .then((response) => {
          console.log(response);

          return response.json();
        })

        .then((data) => {
          if (data.status == true) {
            console.log(data, "recentdata");
            console.log(data["0"]?.end_at, "recentstart");
            console.log(data["0"]?.start_at, "recentend");
            setmytimings(data.data);
            setmyendtime(data["0"]?.end_at);
            setmystarttime(data["0"]?.start_at);

            // console.log(data?.data, "mysdata");

            const bookedEvents = data.data.map((event) => ({
              ...event,
              title: event.title ? event.title : "Booked",
              start_date: new Date(event.start_date).toISOString(),
              end_date: new Date(event.end_date).toISOString(),
              property_start_time: mystarttime,
              property_end_time: myendtime,
              user_name: event.user.name,
            }));
            console.log(bookedEvents, "bookedevents");

            console.log(mystarttime, myendtime, "bookedEvents");

            if (bookedEvents.length > 0) {
              const [startHours, startMinutes] = mycuststart.split(":");

              // Create a new Date object for the current date and set the start time
              const customStartDate = new Date();
              customStartDate.setHours(
                parseInt(startHours, 10),
                parseInt(startMinutes, 10),
                0,
                0
              );

              // Split the end time into hours and minutes
              const [endHours, endMinutes] = mycustend.split(":");

              // Create another Date object for the end time
              const customEndDate = new Date();
              customEndDate.setHours(
                parseInt(endHours, 10),
                parseInt(endMinutes, 10),
                0,
                0
              );

              // Return the hours set as timestamps (number of milliseconds since Unix epoch)
              const startTimestamp = customStartDate.getTime();
              const endTimestamp = customEndDate.getTime();

              // You can also convert them to readable strings
              const startFormatted = customStartDate.toISOString(); // ISO 8601 format
              const endFormatted = customEndDate.toISOString();
              // console.log(endTimestamp, startTimestamp, "lateststrt");
              setCustomMinDate(startTimestamp);
              setCustomMaxDate(endTimestamp);
              console.log(endTimestamp, "asw");
            } else {
              // Split the hours and minutes

              // Split the start time into hours and minutes
              const [startHours, startMinutes] = mycuststart.split(":");

              // Create a new Date object for the current date and set the start time
              const customStartDate = new Date();
              customStartDate.setHours(
                parseInt(startHours, 10),
                parseInt(startMinutes, 10),
                0,
                0
              );

              // Split the end time into hours and minutes
              const [endHours, endMinutes] = mycustend.split(":");

              // Create another Date object for the end time
              const customEndDate = new Date();
              customEndDate.setHours(
                parseInt(endHours, 10),
                parseInt(endMinutes, 10),
                0,
                0
              );

              // Return the hours set as timestamps (number of milliseconds since Unix epoch)
              const startTimestamp = customStartDate.getTime();
              const endTimestamp = customEndDate.getTime();

              // You can also convert them to readable strings
              const startFormatted = customStartDate.toISOString(); // ISO 8601 format
              const endFormatted = customEndDate.toISOString();
              // console.log(endTimestamp, startTimestamp, "lateststrt");
              setCustomMinDate(startTimestamp);
              setCustomMaxDate(endTimestamp);
            }
            setCalData(bookedEvents);
            console.log(bookedEvents, "bookedEvents");
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error fetching reservation history:", error);
        });
    }

    // setFormData(inputData);
    setFormData({
      ...formData,
      corporate_id: corporvalue ? corporvalue : null,
    });
    const ifInputIs = (name, cb) => {
      const userData = JSON.parse(localStorage.getItem("user"));

      let cddata = {
        emp_id: employeebookingfor,
      };

      if (input.name === name) {
        const userData = JSON.parse(localStorage.getItem("user"));
        // console.log(userData);

        API.postMethodNotoken(
          "api/employee-property-list",
          "POST",
          // ...inputData,
          cddata
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(data, "mydatatsasa");
            cb(data);
          });
        // .then((data) => {
        //   console.log(data);
        //   cb(data);
        // });
      }
    };
    ifInputIs("property_id", (data) => {
      setSlprop(true);

      if (data.status === "success") {
        console.log(data.result.data[0]?.resource_groups, "my resoure");
        setResourceGroup(data.result.data[0]?.resource_groups);
        setlengthofResourceGroup(
          resourceGroup
            .filter(({ id }) => id === 5 || id === 10)
            .map(({ id, resource_group_name }) => ({
              id,
              name: resource_group_name,
            }))
        );
        console.log(lengthofResourceGroup, "length");
        // if(lengthofResourceGroup.length<1 ) {
        //   Swal.fire({
        //     html: "No Resource Group for this property",
        //     icon: "warning",
        //     showConfirmButton: false,
        //     timer: TIMER,
        //   });
        //   inputData.resource_group_id=""
        // }
        console.log(vdata.property_id, "mypayload");
      }
    });

    ifInputIs("resource_group_id", (data) => {
      setSlprop(true);

      if (data.status === "success") {
        // console.log(data.result.data[0]?.resource_groups, "my resoure");
        // setResourceGroup(data.result.data[0]?.resource_groups);
        // console.log(vdata.property_id,"mypayload")

        // const userData = JSON.parse(localStorage.getItem("user"));
        API.postMethodNotoken("api/get-seat-reservation", "POST", {
          property_id: vdata.property_id,
          resource_group_id: getreservationpayload.resource_group_id,
          resource_id: getreservationpayload.resource_id,
          corporate_id: corporvalue,
          reserve_type_id: 2,
        })

          .then((response) => response.json())
          .then((crsdata) => {
            // console.log("<<<<<<<<>>>>>>>>>>>");
            // cb(data);
            // console.log(crsdata.corporate_agreement, "mydatas");
            setextras(crsdata?.corporate_agreement);
            console.log(extras);
          });

        // const { start_date, end_date } = extras;
        const min = new Date(mystarttime);
        const max = new Date(myendtime);

        if (min.getTime() > today.getTime()) {
          setMinDate(min);
        }
        if (max.getTime() > today.getTime()) {
          setMaxDate(max);
        }
      } else setResourceGroup([]);
    });
    ifInputIs("resource_group_id", (data) => {
      if (data.status === "success") {
        // console.log(data.result.data[0]?.resources, "mysetups");
        // setResources(data.result.data[0]?.resources);
        // console.log(data.result.data[0]?.resources, "mraaddsss");
        // console.log(
        //   inputData.resource_group_id,
        //   formData.resource_group_id,
        //   "123123"
        // );
        const filteredResources = data.result.data[0]?.resources.filter(
          (resource) =>
            resource.resource_group_id === inputData.resource_group_id
        );
        console.log(filteredResources, "filresources");
        setResources(filteredResources);
      } else setResources([]);
    });
  };

  const [resrce, setresrce] = useState();
  const userData = JSON.parse(localStorage.getItem("user"));
  const handleRequest = (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      employee_id: employeebookingfor,
      corporate_id: corporvalue,
      provider_id: 1,
      start_date: Almyeventstart,
      end_date: Alemyventend,
      // ...formData,
      reserve_type_id: 2,
      no_of_people: 1,
      property_id: getreservationpayload.property_id,
      resource_group_id: getreservationpayload.resource_group_id,
      resource_id: getreservationpayload.resource_id,
      Booking_done_by_id: userData?.id,
      is_cluster: 1,
    };

    API.postMethodNotoken("api/post-seat-reservation", "POST", data)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, "myseettings");
        Swal.fire({
          html: "<i>" + data.message + "</i>",
          icon: data.status,
          showConfirmButton: false,
          timer: TIMER,
        });

        // console.log(data, "mydt");
        // window.location.reload();

        if (data.status === "success") {
          // console.log("tata", data);
          Swal.fire({
            html: `<i>success</i>`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          setTimeout(() => {
            window.location.reload();
            // if (data.resource_group_id === 10) {
            //   window.location.href = `/discover/booking-details/${data.data.id}`;
            // }
            // if (data.resource_group_id === 9 || data.resource_group_id === 5) {
            //   window.location.href = `/discover/booking-details/${data.data.booking_id}`;
            // } else {
            //   window.location.href = `/discover/upcoming-reservation`;
            // }
          }, 2000);
        } else {
          Swal.fire({
            html: `<i>${data.message}</i>`,
            icon: "error",
            showConfirmButton: false,
            buttonsStyling: false,
            timer: 2000,
          });

          setTimeout(() => {
            window.location.href = "/book";
          }, 2000);
        }
      });
  };

  let minTime = new Date();
  minTime.setHours(0, 0, 0, 0);

  function setMinTime(date, time) {
    let cloneToday = new Date(time);
    let cloneDate = new Date(date);
    cloneToday.setHours(0, 0, 0, 0);
    cloneDate.setHours(0, 0, 0, 0);

    if (cloneToday.getTime() === cloneDate.getTime()) return time;
    return minTime;
  }

  const [isDisabled, setIsDisabled] = useState(false);

  const handleSelectSlot = useCallback(
    async ({ start, end, action }) => {
      const durationInHours = moment(end).diff(moment(start), "hours");
      console.log("started");
      // Check if the duration is less than or equal to 2 hours
      if (durationInHours <= 2) {
        // Allow selection

        if (currentView !== "day") {
          // console.log("Slot selection is only allowed in 'day' view.");
          return;
        }

        // console.log(action);

        const startTime = new Date(start);
        const endTime = new Date(end);

        // Find overlapping event
        const overlappingEvent = calData.find((event) => {
          const eventStartTime = new Date(event.start_date);
          const eventEndTime = new Date(event.end_date);

          // Check if the slot overlaps with an existing booking
          return (
            (startTime >= eventStartTime && startTime < eventEndTime) ||
            (endTime > eventStartTime && endTime <= eventEndTime) ||
            (startTime <= eventStartTime && endTime >= eventEndTime)
          );
        });

        if (overlappingEvent) {
          // Swal.fire({
          //   text: `This time slot is already booked (Booking ID: ${overlappingEvent.id}). Please select another slot.`,
          //   icon: "warning",
          //   showConfirmButton: true,
          // });
          console.log(overlappingEvent, "overlappingEvent");
          setalredybookedid(overlappingEvent);
          setEditedModalshow(true);

          const eventMoment = moment(overlappingEvent.end_date);

          if (!eventMoment.isValid()) {
            // console.error("Invalid date format");
            return;
          }

          const isWithinOneHourBefore =
            currentTime.isSameOrBefore(eventMoment) &&
            currentTime.isAfter(eventMoment.clone().subtract(1, "hour"));

          const hasEventPassed = currentTime.isAfter(eventMoment);
          console.log(overlappingEvent?.user_id);
          // Set disable if within one hour before or if event has passed
          setIsDisabled(hasEventPassed);

          // console.log(isDisabled);
          // Format the dates to display or log
          const eventFormatted = eventMoment.format("DD-MM-YY h:mm A");
          const currentFormatted = currentTime.format("DD-MM-YY h:mm A");

          // console.log("Event Time:", eventFormatted);
          // console.log("Current Time:", currentFormatted);

          // console.log("Within One Hour Before:", isWithinOneHourBefore);
          // if (isWithinOneHourBefore) {
          //   setIsDisabled(true);
          // }
          // console.log(isDisabled, "isDisabled");

          return;
        }

        if (startTime < new Date()) {
          Swal.fire({
            text: "You cannot book slots before today's date.",
            icon: "warning",
            showConfirmButton: true,
          });
          return;
        }

        const isSlotAvailable = calData.every((event) => {
          const eventStartTime = new Date(event.start_date);
          const eventEndTime = new Date(event.end_date);

          // console.log(alreadybookedid, "eventssss");

          return endTime <= eventStartTime || startTime >= eventEndTime;
        });

        if (!isSlotAvailable) {
          Swal.fire({
            text: "This time slot is already booked. Please select another slot.",
            icon: "warning",
            showConfirmButton: true,
          });
          return;
        }

        // console.log(startTime, "startTime");

        const EventStartsOn = moment(startTime).format("YYYY-MM-DD HH:mm:ss");
        const EventEndssOn = moment(endTime).format("YYYY-MM-DD HH:mm:ss");

        setmyeventstart(EventStartsOn);
        setemyventend(EventEndssOn);

        const formattedDate = moment(startTime).format("dddd MMM DD");
        start = moment(startTime).format("h:mmA");
        end = moment(endTime).format("h:mmA");
        setEventTiming(formattedDate);
        setSelectedStartTime(start);
        setSelectedEndTime(end);

        TimeSlotSelected(startTime);
        TimeSlotEndSelected(endTime);

        // console.log(start, "--------------<cal-----------------");
        // console.log(EventStartsOn, "EventStartsOn");
        // console.log(EventEndssOn, "EventEndssOn");
        // console.log(start, "start");
        // console.log(end, "end");
        // console.log(start, "--------------<cal-----------------");
      } else {
        // Do not allow selection

        Swal.fire({
          text: "Maximum 2 hours allowed to book",
          icon: "warning",
          showConfirmButton: true,
        });
      }
    },
    [calData, setCalData, formData, userData]
  );

  //cancel booking
  const CancelBooking = useCallback(async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      booking_id: alreadybookedid.id,
      user_id: user.id,
      rsn_for_req_cancel: cancelreason,
    };

    if (cancelreason) {
      // console.log(data, "mycalls");

      try {
        const response = await API.postMethod(
          "api/booking-cancel-request",
          "POST",
          data
        );
        const responseData = await response.json();

        if (response.ok && responseData.status === "success") {
          // console.log("success");
          // const newEvent = {
          //   start_date: selectedStartTime,
          //   end_date: selectedEndTime,
          //   title: formData.title,
          // };
          // setEvents((prev) => [...prev, newEvent]);
          // setCalData((prev) => [...prev, newEvent]);

          Swal.fire({
            html: `<i>${responseData.message}</i>`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          handlediteDataClose();
          setEventshow(false);
          setChangingstate(true);
          // console.log(responseData, "mysrrr");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          Swal.fire({
            html: `<i>${responseData.message}</i>`,
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        // console.error("Error submitting event:", error);
        Swal.fire({
          html: `<i>Something went wrong. Please try again later.</i>`,
          icon: "error",
          showConfirmButton: true,
        });
        setChangingstate(true);
      }
    }
  });

  const EventSubmitHandler = useCallback(
    async (e) => {
      e.preventDefault(); // Prevent default form submission behavior
      // console.log("e", e);
      // console.log(selectedStartTime, "selectedStartTime ");
      // console.log(selectedEndTime, "selectedEndTime ");
      const user = JSON.parse(localStorage.getItem("user"));
      const data = {
        employee_id: employeebookingfor,
        corporate_id: corporvalue,
        provider_id: 1,
        property_id: getreservationpayload.property_id,
        resource_group_id: getreservationpayload.resource_group_id,
        resource_id: getreservationpayload.resource_id,
        start_date: myeventstart,
        end_date: myeventend,
        title: eventtitle,
        reserve_type_id: 2,
        no_of_people: 1,
        Booking_done_by_id: userData?.id,
        is_cluster: 1,
      };

      // start_date

      // "2024-05-10 12:30:00"

      // console.log(data, "myevents");

      try {
        const response = await API.postMethodNotoken(
          "api/post-seat-reservation",
          "POST",
          data
        );
        const responseData = await response.json();

        if (response.ok && responseData.status === "success") {
          const newEvent = {
            start_date: selectedStartTime,
            end_date: selectedEndTime,
            title: formData.title,
          };
          setCalData((prev) => [...prev, newEvent]);

          Swal.fire({
            html: `<i>${responseData.message}</i>`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          // console.log(responseData, "mysrrr");

          // console.log(data, "mydt");

          setTimeout(() => {
            // if (data.resource_group_id === 10) {
            //   window.location.href = `/discover/booking-details/${responseData.data.id}`;
            // }
            // if (data.resource_group_id === 9 || data.resource_group_id === 5) {
            //   window.location.href = `/discover/booking-details/${responseData.data.booking_id}`;
            // } else {
            //   window.location.href = `/discover/upcoming-reservation`;
            // }
            window.location.href = "/book";
          }, 2000);
        } else {
          Swal.fire({
            html: `<i>${responseData.message}</i>`,
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        // console.error("Error submitting event:", error);
        Swal.fire({
          html: `<i>Something went wrong. Please try again later.</i>`,
          icon: "error",
          showConfirmButton: true,
        });
      }
    }
    // [formData, setEvents, setCalData, userData]
  );

  //event update handler

  const UpdateEventSubmitHandler = useCallback(
    async (e) => {
      e.preventDefault(); // Prevent default form submission behavior
      console.log("e", e);
      const user = JSON.parse(localStorage.getItem("user"));
      const data = {
        employee_id: employeebookingfor,
        corporate_id: corporvalue,
        // provider_id: 1,
        // ...formData,
        property_id: getreservationpayload.property_id,
        resource_group_id: getreservationpayload.resource_group_id,
        resource_id: getreservationpayload.resource_id,
        reserve_type_id: 2,
        start_date: Almyeventstart,
        end_date: Alemyventend,
        id: alreadybookedid.id,
        title: updatetitle,
        no_of_people: 1,
        Booking_done_by_id: userData?.id,
        // title:
      };

      if (!Almyeventstart) {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: "Choose Start time Again",
          // confirmButtonText: "OK",
        });
        setnoApicall(true);
      } else if (!Alemyventend) {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: "Choose End time Again",
          // confirmButtonText: "OK",
        });
        setnoApicall(true);
      } else {
        // console.log(data, "myevents");
        // if(!noapicall) {

        try {
          const response = await API.postMethodNotoken(
            "api/modify-seat-reservation",
            "POST",
            data
          );
          const responseData = await response.json();
          setIsLoading(true);
          if (response.ok && responseData.status === "success") {
            // console.log("yesss");
            setChangingstate(true);

            setIsLoading(false);
            Swal.fire({
              html: `<i>${responseData.message}</i>`,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            // console.log(responseData, "mysrrr");
            setnewstarttime(false);
            setTimeout(() => {
              window.location.href = `/discover/booking-details/${alreadybookedid.id}`;
            }, 2000);
          } else {
            Swal.fire({
              html: `<i>${responseData.message}</i>`,
              icon: "error",
              showConfirmButton: true,
            });
            setIsLoading(false);
            setnewstarttime(false);
            // setAlmyeventstart("");
            // setAlemyventend("");
          }
        } catch (error) {
          setnewstarttime(false);
          setChangingstate(false);

          // console.error("Error submitting event:", error);
          Swal.fire({
            html: `<i>Something went wrong. Please try again later.</i>`,
            icon: "error",
            showConfirmButton: true,
          });
        }
      }
    }
    // }
    // [formData, setEvents, setCalData, userData]
  );

  const handleSelectEvent = (event) => {
    // console.log("Event clicked:", event); // Log the whole event object
    const eventId = event.id; // Check if the event object contains the ID
    if (eventId) {
      // console.log("Event ID:", eventId); // Should output the ID
    } else {
      // console.log("Event does not contain an ID");
    }
  };

  const AgendaDate = ({ label }) => {
    // Customize the date format here
    const formattedDate = moment(label).format("DD MMM YYYY");
    return <span>{formattedDate}</span>;
  };

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  const eventPropGetter = (event) => {
    // console.log(`Clicked event: ${event.title}`);
    if (currentView === "day" && event.title !== "") {
      return {
        className: "booked-slot", // Apply your custom class
      };
    }

    return {};
  };
  const isValidTimeFormat = (time) => {
    // Regular expression to match the "hh:mm am/pm" pattern
    const timePattern = /^(\d{1,2}):(\d{2})\s*(am|pm)$/i;
    return timePattern.test(time); // Returns true if it matches, false otherwise
  };

  //convert 24 format
  const convertTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":");

    // Convert hours and minutes to numbers only when necessary
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (modifier === "pm" && hours !== 12) {
      hours += 12;
    }

    if (modifier === "am" && hours === 12) {
      hours = 0;
    }

    return { hours, minutes };
  };

  const isGreaterOrEqual = (time1, time2) => {
    const time1_24 = convertTo24Hour(time1);
    const time2_24 = convertTo24Hour(time2);

    // console.log(time1_24, time2_24, "mytimes");

    if (time1_24.hours > time2_24.hours) {
      return true;
    } else if (time1_24.hours === time2_24.hours) {
      return time1_24.minutes >= time2_24.minutes;
    } else {
      return false;
    }
  };

  const generateTimeSlots = (startTime, increment, count) => {
    // console.log(
    //   "<-------generateTimeSlots-------->",
    //   startTime,
    //   increment,
    //   count
    // );
    if (isValidTimeFormat(startTime)) {
      const dateWithTime = moment().format("YYYY-MM-DD") + " " + startTime; // Get today's date and concatenate with time
      startTime = moment(dateWithTime, "YYYY-MM-DD hh:mm a").format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
      );
    }

    // console.log(startDate, customMaxDate, "mycustominitialDate");
    let slots = [];
    let current = new Date(startTime);
    let endDate = new Date(customMaxDate);
    let maxTimeLimit = moment(endDate).format("hh:mm a");
    // Generate slots with the specified count and increment
    // console.log(maxTimeLimit, "maxtimelimit");
    for (let i = 0; i < count; i++) {
      slots.push(
        current.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );

      current.setMinutes(current.getMinutes() + increment);
    }
    let val = [];
    // console.log("slots", slots);
    let myData = slots.filter((data) => {
      // console.log(maxTimeLimit, ">=", data.toLowerCase(), "------");
      let myObj = isGreaterOrEqual(maxTimeLimit, data.toLowerCase());
      if (myObj) {
        return val.push(data.toLowerCase());
      }
      // console.log(val.push(data), "push data");
    });

    // console.log(myData, maxTimeLimit, "3333333333333");
    return myData;
  };

  const TimeSlotSelected = (time) => {
    const initialStartOptions = generateTimeSlots(time, 30, 4);
    // console.log("returned data", initialStartOptions);

    if (initialStartOptions.length == 1) {
    } else {
      setEventshow(true);
    }

    setStartTimeOptions(initialStartOptions);
  };

  //already time slot

  const TimeSlotalredySelected = (time) => {
    const initialStartOptions = generateTimeSlots(time, 30, 4);
    // console.log("returned data", initialStartOptions);

    if (initialStartOptions.length == 1) {
      // Swal.fire({
      //   icon: "warning",
      //   title: "Warning",
      //   text: "End property time reached",
      //   confirmButtonText: "OK",
      //   customClass: {
      //     confirmButton: "swal2-confirm",
      //   },
      //   buttonsStyling: true,
      // });
    }

    setStartTimeOptions(initialStartOptions);
  };
  //alredy time slot

  const TimeSlotaledyEndSelected = (time) => {
    // console.log("time", time);

    const initialStartOptions = generateTimeSlots(time, 30, 4);
    setEndTimeOptions(initialStartOptions);
    // console.log("initialStartOptions", initialStartOptions);
  };

  const TimeSlotEndSelected = (time) => {
    // console.log("time", time);
    const initialStartOptions = generateTimeSlots(time, 30, 4);
    setEndTimeOptions(initialStartOptions);
    // console.log("initialStartOptions", initialStartOptions);
  };

  function addOneHour(time) {
    // Parse the time string to extract hours, minutes, and AM/PM
    const parts = time.toLowerCase().split(/[: ]/); // Split by ":" and " "
    let hours = parseInt(parts[0]); // Get the hours part
    let minutes = parseInt(parts[1]); // Get the minutes part
    const period = parts[2]; // "am" or "pm"

    // Convert to 24-hour format
    let hours24 =
      period === "pm"
        ? hours === 12
          ? 12
          : hours + 12
        : hours === 12
        ? 0
        : hours;

    // Add 30 minutes
    minutes += 30;

    // If the minutes exceed 59, adjust the hours
    if (minutes >= 60) {
      hours24 = (hours24 + 1) % 24; // Handle hour wrap-around
      minutes -= 60; // Normalize the minutes
    }

    // Convert back to 12-hour format and set AM/PM
    const newPeriod = hours24 >= 12 ? "pm" : "am";
    const newHours = hours24 > 12 ? hours24 - 12 : hours24 === 0 ? 12 : hours24;

    // Format the result as a string "hh:mm am/pm"
    const formattedTime = `${newHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${newPeriod}`;
    // console.log("<-------addOneHour end--------->");
    return formattedTime;
  }

  // Handle Start Time change
  const handleStartTimeChange = (e) => {
    // console.log("<-------handleStartTimeChange --------->", selectedStartTime);
    const newStartTime = e.target.value;
    // console.log("newStartTime", newStartTime);
    setSelectedStartTime(newStartTime);
    TimeSlotEndSelected(addOneHour(newStartTime));
    // setchangablestartdate(newStartTime);

    console.log(newStartTime);
    // const currentDate = moment().format("YYYY-MM-DD");
    const currentDate = moment(myeventstart).format("YYYY-MM-DD");
    const dateTimeString = `${currentDate} ${newStartTime}`;
    const dateTime = moment(dateTimeString, "YYYY-MM-DD hh:mm a");
    setmyeventstart(dateTime.format("YYYY-MM-DD HH:mm:ss"));

    // console.log("<-------handleStartTimeChange end--------->");
  };

  // Handle alredy Start Time change
  const handleAlStartTimeChange = (e) => {
    // setEndTimeOptions([]);
    setnewstarttime(true);
    // console.log("<-------handleStartTimeChange --------->", selectedStartTime);
    const newStartTime = e.target.value;
    // console.log("newStartTime", newStartTime);
    setalreadystarttime(newStartTime);
    TimeSlotaledyEndSelected(addOneHour(newStartTime));

    // setchangablestartdate(newStartTime);

    // console.log(newStartTime);
    // const currentDate = moment().format("YYYY-MM-DD");
    const mynewstart = change ? deriveddate : alreadybookedid.start_date;
    // console.log(mynewstart, "mynewtimestart");
    const currentDate = moment(mynewstart).format("YYYY-MM-DD");
    // console.log(currentDate);
    const dateTimeString = `${currentDate} ${newStartTime}`;
    // console.log(dateTimeString);
    const dateTime = moment(dateTimeString, "YYYY-MM-DD hh:mm a");
    // console.log(dateTime);
    setAlmyeventstart(dateTime.format("YYYY-MM-DD HH:mm:ss"));
    // console.log(Almyeventstart);
  };

  const handleEndTimeChange = (e) => {
    // console.log("<-------handleEndTimeChange--------->");

    setSelectedEndTime(e.target.value);
    // const currentDate = moment().format("YYYY-MM-DD");
    const currentDate = moment(myeventend).format("YYYY-MM-DD");

    const dateTimeString = `${currentDate} ${e.target.value}`;
    const dateTime = moment(dateTimeString, "YYYY-MM-DD hh:mm a");
    setemyventend(dateTime.format("YYYY-MM-DD HH:mm:ss"));
    // console.log("<-------handleEndTimeChange end--------->");
  };

  const handleAlEndTimeChange = (e) => {
    setalreadyendtime(e.target.value);
    // const currentDate = moment().format("YYYY-MM-DD");
    const mynewstart = change ? deriveddate : alreadybookedid.end_date;
    // console.log(mynewstart, "mynewtimeend");

    const currentDate = moment(mynewstart).format("YYYY-MM-DD");

    const dateTimeString = `${currentDate} ${e.target.value}`;
    const dateTime = moment(dateTimeString, "YYYY-MM-DD hh:mm a");
    // console.log(dateTime, "mydaatetime");
    setAlemyventend(dateTime.format("YYYY-MM-DD HH:mm:ss"));
  };

  useEffect(() => {
    const mydatesss = moment(alreadybookedid.start_date).format("h:mmA");
    const mydatesssend = moment(alreadybookedid.end_date).format("h:mmA");
    const mysetdate = moment(alreadybookedid.start_date).format("dddd MMM DD");

    console.log(mydatesss, mydatesssend, mysetdate, "value");
    setupdatetitle(alreadybookedid.title);
    setalreadystartdate(mysetdate);
    setalreadystarttime(mydatesss);
    setalreadyendtime(mydatesssend);
    TimeSlotalredySelected(mydatesss);
    TimeSlotaledyEndSelected(mydatesssend);

    // Time
  }, [editedmodalshow]);

  const startDate = moment(alreadybookedid.start_date);
  const currentTime = moment();

  // Check if the start date is within one hour before the current time
  const isWithinOneHourBefore =
    currentTime.isSameOrBefore(startDate) &&
    currentTime.isAfter(startDate.subtract(1, "hour"));
  return (
    <div>
      <NavbarContainer />
      <div className="mx-5">
        <div className="row">
          <div className="col" style={{ margin: "0px" }}>
            <h2 className="text-primary" style={{ marginTop: "20px" }}>
              Meeting Room Booking
            </h2>
          </div>
          <div className="col-auto">
            <Button
              // type="submit"
              variant="primary"
              className="bg-gradient-primary"
              //disabled={!isFieldsFilled} // Disable button if fields are not filled
              onClick={() => setShow(true)}
              disabled={isButtonDisabled}
              style={isButtonDisabled ? grayGradientStyle : colorGradientStyle} // Bind the disabled state to the button
            >
              Calender View
            </Button>
          </div>
        </div>
        <form
          onSubmit={handleRequest}
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            color: "#000",
            backgroundColor: "#fff", // Container with white background
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="form-content">
            <div className="row">
              <div className="col-md-3">
                <Select
                  label="Corporate Name"
                  id="corporate_id"
                  name="Corporate_id"
                  options={corporates.map(({ id, name }) => ({
                    id,
                    name,
                  }))}
                  value={formData.corporate_id}
                  placeholder="Select Corporate"
                  isClearable
                  required
                  onChange={handlecorporates}
                />
              </div>

              <div className="col-md-3">
                <Select
                  label="Booking for"
                  name="booking_for"
                  options={employees.map(({ id, name }) => ({
                    id,
                    name,
                  }))}
                  value={employeebookingfor}
                  placeholder="Select Employee"
                  required
                  onChange={(e) => {
                    setEmployeebookingfor(e.target.value);
                    _getProperties(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <Select
                  label="Property Name"
                  name="property_id"
                  options={properties?.map(({ id, property_name }) => ({
                    id,
                    name: property_name,
                  }))}
                  value={formData.id}
                  placeholder="Select Property"
                  required
                  onChange={handleInput}
                />
              </div>
              <div className="col-md-3">
                <Select
                  label="Resource Type"
                  name="resource_group_id"
                  options={resourceGroup
                    .filter(({ id }) => id === 5 || id === 10)
                    .map(({ id, resource_group_name }) => ({
                      id,
                      name: resource_group_name,
                    }))}
                  value={formData.resource_group_id}
                  placeholder="Select Resource"
                  required
                  onChange={handleInput}
                />
                {slprop && resourceGroup.length < 1 && (
                  <p className="text-danger mt-n3 mb-3">No plan available</p>
                )}
              </div>
              <div className="col-md-3">
                <Select
                  label="Resource Name"
                  name="resource_id"
                  options={
                    resources &&
                    resources.map(({ id, resource_name }) => ({
                      id,
                      name: resource_name,
                    }))
                  }
                  value={formData.resource_id}
                  placeholder="Select Resource Name"
                  required
                  onChange={
                    handleInput
                    // handleInput()
                  }
                />
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="fromdatepicker"
                    className="form-label"
                    style={{ color: "#000" }}>
                    Date <span className="text-danger">*</span>
                  </label>
                  <div className="datefilter w-100">
                    <DatePicker
                      className="form-control w-100"
                      minDate={minDate}
                      required
                      value={alreadystartdate}
                      disabled={isDisabled}
                      autoComplete="off"
                      onChange={handleDateChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="start_time"
                    className="form-label"
                    style={{ color: "#000" }}>
                    Start Time: <span className="text-danger">*</span>
                  </label>
                  <div className="datefilter">
                    {change ? (
                      <Form.Control
                        as="select"
                        required
                        disabled={isDisabled}
                        onChange={handleAlStartTimeChange}
                        value={alreadystarttime}>
                        <option> selct time </option>
                        {newtimeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                    ) : (
                      <Form.Control
                        as="select"
                        required
                        disabled={isDisabled}
                        onChange={handleAlStartTimeChange}
                        value={alreadystarttime}>
                        {startTimeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="end_time"
                    className="form-label"
                    style={{ color: "#000" }}>
                    End Time:<span className="text-danger">*</span>
                  </label>
                  <div className="datefilter">
                    {change ? (
                      <Form.Control
                        disabled={isDisabled}
                        required
                        as="select"
                        onChange={handleAlEndTimeChange}
                        value={alreadyendtime}>
                        <option> selct time </option>
                        {endTimeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                    ) : (
                      <Form.Control
                        disabled={isDisabled}
                        required
                        as="select"
                        onChange={handleAlEndTimeChange}
                        value={alreadyendtime}>
                        {newstarttime && <option> selct time </option>}
                        {endTimeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  </div>
                </div>
              </div>

              {formData.reserve_type_id === 2 && (
                <div className="col-md-6">
                  {/* Add your input field for number of people here if needed */}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button type="submit" className="btn gradient-primary">
              Reserve
            </button>
          </div>
        </form>

        {/* model */}
        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>Calender</Modal.Header>

          <Modal.Body className="reactBigCalender">
            <div
              style={{
                // border: "1px solid #ccc",
                borderRadius: "5px",
                // padding: "16px",
                // backgroundColor: "rgb(247 199 148)",
                width: "100%",
                margin: "10px auto",
              }}>
              {/* <Grid lg={12} item container>
                <Grid item lg={4} md={12} sm={12}>
                  <Select
                    label="Property Name"
                    name="property_id"
                    options={properties.map(({ id, property_name: name }) => ({
                      id,
                      name,
                    }))}
                    value={formData.property_id}
                    placeholder="Select Property"
                    required
                    onChange={handleInput}
                  />
                </Grid>

                <Grid item lg={4} md={12} sm={12}>
                  <Select
                    label="Resource Type"
                    name="resource_group_id"
                    disabled={typeof formData.property_id !== "number"}
                    options={resourceGroup
                      .filter(({ id }) => id === 5 || id === 10)
                      .map(({ id, resource_group_name: name }) => ({
                        id,
                        name,
                      }))}
                    value={formData.resource_group_id}
                    placeholder="Select Resource"
                    required
                    onChange={handleInput}
                  />
                  {slprop && resourceGroup.length < 1 && (
                    <p className="text-danger mt-n3 mb-3">No plan available</p>
                  )}
                </Grid>

                <Grid item lg={4} md={12} sm={12}>
                  <Select
                    label="Resource Name"
                    name="resource_id"
                    disabled={typeof formData.resource_group_id !== "number"}
                    options={
                      resources &&
                      resources
                        .filter(
                          (reso) =>
                            reso.resource_group_id ===
                            formData.resource_group_id
                        )
                        .map(({ id, resource_name: name }) => ({
                          id,
                          name,
                        }))
                    }
                    value={formData.resource_id}
                    placeholder="Select Resource Name"
                    required
                    onChange={handleInput}
                  />
                </Grid>
              </Grid> */}
            </div>
            <div
            //  className={checkingresourceid ? "blurcss" : "noblur"}
            >
              <Calendar
                localizer={localizer}
                defaultView="day"
                defaultDate={new Date()}
                views={{ day: true, week: true, month: true, agenda: true }}
                events={calData}
                startAccessor={(event) => new Date(event.start_date)} // Adjust to your actual property name
                endAccessor={(event) => new Date(event.end_date)}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                selectable={currentView === "day"}
                onView={setCurrentView}
                style={{ height: 500 }}
                min={customMinDate}
                max={customMaxDate}
                // minTime="1716229800000"
                // maxTime="1716229800000"
                eventPropGetter={eventPropGetter}
                components={{
                  event: CustomEventRenderer,
                  agenda: {
                    date: AgendaDate,
                  },
                }}
                formats={{
                  agendaDateFormat: "DD MMM YYYY", // Custom agenda date format
                }}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={Eventshow}
          onHide={handleDataClose}
          centered
          size="md"
          style={{ backgroundColor: "#00000075" }}>
          <Modal.Header closeButton>
            <Modal.Title>Booking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={EventSubmitHandler}>
              <Form.Group as={Row} style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  Title:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    required
                    placeholder="Enter event title"
                    value={eventtitle}
                    onChange={(e) => seteventtitle(e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="formatted-date"
                style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  Date:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control readOnly type="text" value={EventTiming} />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="start-time"
                style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  Start Time:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    onChange={handleStartTimeChange}
                    value={selectedStartTime}>
                    {startTimeOptions.map((option, index) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="end-time"
                style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  End Time:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    onChange={handleEndTimeChange}
                    value={selectedEndTime}>
                    {endTimeOptions.map((option, index) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <div className="d-flex justify-content-between mt-3">
                <Button
                  variant="secondary"
                  onClick={handleDataClose}
                  style={{
                    background:
                      "linear-gradient(90deg, rgb(158 158 158 / 35%), rgb(158, 158, 158))",

                    borderColor: "#9E9E9E",
                    color: "white",
                    padding: "10px 20px",
                  }}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  style={{
                    background:
                      "linear-gradient(90deg, rgb(253, 209, 149), rgb(252, 146, 0))",
                    borderColor: "#FF9800",
                    color: "white",
                    padding: "10px 20px",
                  }}>
                  Submit
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={editedmodalshow}
          onHide={handlediteDataClose}
          centered
          size="md"
          style={{ backgroundColor: "#00000075" }}>
          <Modal.Header closeButton>
            <Modal.Title>View Booking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={UpdateEventSubmitHandler}>
              <Form.Group
                as={Row}
                controlId="event-title"
                style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  Title:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="textarea"
                    placeholder="Enter event title"
                    value={updatetitle}
                    disabled={isDisabled}
                    onChange={(e) => setupdatetitle(e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="formatted-date"
                style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  Date :
                </Form.Label>
                <Col sm={9} className="rct_dpicker">
                  <DatePicker
                    style={{ width: "100%" }}
                    // id="todatepicker"
                    className="form-control w-100"
                    // placeholderText="End Date"
                    // name="to_date"
                    minDate={minDate}
                    required
                    value={alreadystartdate}
                    disabled={isDisabled}
                    autoComplete="off"
                    onChange={(e) => {
                      console.log(alreadystartdate);
                      handleDateChange(e);
                      setChangingstate(false);
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="start-time"
                style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  Start Time:
                </Form.Label>
                <Col sm={9}>
                  {change ? (
                    <Form.Control
                      as="select"
                      required
                      disabled={isDisabled}
                      onChange={handleAlStartTimeChange}
                      value={alreadystarttime}>
                      <option> selct time </option>
                      {newtimeOptions.map((option, index) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    <>
                      <Form.Control
                        as="select"
                        required
                        disabled={isDisabled || !changingState}
                        onChange={handleAlStartTimeChange}
                        value={alreadystarttime}>
                        {startTimeOptions.map((option, index) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                      {!isDisabled && (
                        <small style={{ color: "red" }}>
                          {" "}
                          Choose date to change the time
                        </small>
                      )}
                    </>
                  )}
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="end-time"
                style={{ marginBottom: "10px" }}>
                <Form.Label column sm={3}>
                  End Time:
                </Form.Label>
                <Col sm={9}>
                  {change ? (
                    <Form.Control
                      disabled={isDisabled}
                      required
                      as="select"
                      onChange={handleAlEndTimeChange}
                      // value={moment(alreadybookedid.start_date).format("h:mmA")}
                      value={alreadyendtime}>
                      <option> selct time </option>

                      {endTimeOptions.map((option, index) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    <Form.Control
                      disabled={isDisabled || !changingState}
                      required
                      as="select"
                      onChange={handleAlEndTimeChange}
                      // value={moment(alreadybookedid.start_date).format("h:mmA")}
                      value={alreadyendtime}>
                      {newstarttime && <option> selct time </option>}

                      {endTimeOptions.map((option, index) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                </Col>
              </Form.Group>

              {cancelinput && (
                <Form.Group
                  as={Row}
                  controlId="cancel"
                  style={{ marginTop: "10px" }}>
                  <Form.Label column sm={3}>
                    Reason for cancellation:
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="textarea"
                      placeholder="Reason for cancel"
                      value={cancelreason}
                      required
                      onChange={(e) => setcancelreason(e.target.value)}
                      style={{ borderColor: cancelreason ? "" : "red" }}
                    />
                    {!cancelreason && (
                      <span className="text-danger">
                        This field is required.
                      </span>
                    )}
                  </Col>
                </Form.Group>
              )}

              {cancelinput ? (
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    variant="secondary"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(158 158 158 / 35%), rgb(158, 158, 158))",

                      borderColor: "#9E9E9E",
                      color: "white",
                      padding: "10px 20px",
                    }}
                    onClick={ForceCloseCancel}>
                    No , I want to continue
                  </Button>
                  <Button
                    variant="primary"
                    onClick={CancelBooking}
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(253, 209, 149), rgb(252, 146, 0))",
                      borderColor: "#FF9800",
                      color: "white",
                      padding: "10px 20px",
                    }}>
                    Confirm to cancel
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-between mt-3">
                  {!cancelinput && !isDisabled && (
                    <Button
                      style={{
                        background:
                          "linear-gradient(90deg, rgb(158 158 158 / 35%), rgb(158, 158, 158))",

                        borderColor: "#9E9E9E",
                        color: "white",
                        padding: "10px 20px",
                      }}
                      disabled={isDisabled}
                      variant="info"
                      // className="w-100"
                      onClick={showMyCancelInput}>
                      Cancel Booking
                    </Button>
                  )}
                  {!isDisabled && (
                    <>
                      <Button
                        style={{
                          background:
                            "linear-gradient(90deg, rgb(253, 209, 149), rgb(252, 146, 0))",
                          borderColor: "#FF9800",
                          color: "white",
                          padding: "10px 20px",
                        }}
                        variant="primary"
                        type="submit"
                        disabled={isDisabled}>
                        Update Booking
                      </Button>
                    </>
                  )}
                </div>
              )}
            </Form>
          </Modal.Body>
        </Modal>

        {/* //edited modal  */}

        {isLoading && <Spinner className="position-static my-3" />}
      </div>
      <UpcomingReservaton />
    </div>
  );
};

export default BookMeeting;
