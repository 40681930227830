import React, { Component } from 'react';

export class Spinner extends Component {
  render() {
    const { className } = this.props;
    return (
      <div>
        <div
          className={
            className ? className + ' spinner-wrapper' : 'spinner-wrapper'
          }
        >
          <div className="donut"></div>
        </div>
      </div>
    );
  }
}

export default Spinner;
