import React from "react";
import { Chart } from "react-google-charts";

const ChartComponent = ({ type, data, options }) => {
  return (
    <Chart
      chartType={type}
      width="100%  "
      height="220px"
      data={data}
      options={options}
    />
  );
};

export default ChartComponent;
