import React, { useMemo } from "react";
import styles from "./reports.module.css";
import moment from "moment";
import {
  ISSUE_ASSIGNED,
  ISSUE_CLOSED,
  ISSUE_REOPEN,
  ISSUE_RESOLVED,
  ISSUE_UNASSIGNED,
  ISSUE_WORK_STARTED,
} from "./Reports";
import { NormalTable } from "../../tables/NormalTable";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default function SummaryTable({
  dispatch,
  filteredIssuesMonth,
  scrollAnimation,
  type = "month",
  startDate,
  endDate,
}) {
  const KEYS = Object.keys?.(filteredIssuesMonth);
  const VALUES = Object.values?.(filteredIssuesMonth);
  console.log(VALUES, "values");
  const column = useMemo(
    () => [
      // name
      {
        accessorKey: "status",
        header: capitalizeFirstLetter(type),
        enableColumnFilter: false,
        size: "30",
        Cell: ({ cell }) => {
          console.log("lk", cell.row.original);
        },
      },
      // Total
      {
        accessorKey: "status",
        header: "Total",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      // Open
      {
        accessorKey: "status",
        header: "Open",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      // Assign Staff
      {
        accessorKey: "status",
        header: "Assign Staff",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      // Work Started
      {
        accessorKey: "status",
        header: "Work Started",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      // Resolved
      {
        accessorKey: "status",
        header: "Resolved",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      // Closed
      {
        accessorKey: "status",
        header: "Closed",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      // Delayed
      {
        accessorKey: "status",
        header: "Delayed",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
    ],
    []
  );
  // console.log("laksh", filteredIssuesMonth);
  return (
    <>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            <th>{capitalizeFirstLetter(type)}</th>
            <th>Total</th>
            <th>Open</th>
            <th>Assign Staff</th>
            <th>Work Started</th>
            <th>Resolved</th>
            <th>Closed</th>

            <th
              style={{
                backgroundColor: "red",
                color: "#fff",
              }}
            >
              Delayed
            </th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {/* {console.log("laksh", VALUES)} */}
          {VALUES.length === 0 ? (
            <tr>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
          ) : (
            VALUES.map((filteredIssues, i) => {
              const typeString =
                type === "month"
                  ? moment(KEYS[i]).format("MMMM-YYYY")
                  : `(${moment(startDate).format("DD-MMM-YYYY")}) to (${moment(
                      endDate
                    ).format("DD-MMM-YYYY")}) | ${KEYS[i]}`;
              return (
                <tr>
                  <td>
                    {type === "month"
                      ? moment(KEYS[i]).format("MMMM-YYYY")
                      : KEYS[i]}
                    {/* {typeString} */}
                  </td>
                  <td>
                    <button
                      style={{ color: "blue", textDecoration: "underline" }}
                      onClick={() => {
                        dispatch({
                          type: "filterIssue/tableData",
                          // purpose: "total",
                          payload: {
                            header: `${typeString}-Total`,
                            data: filteredIssues,
                          },
                        });
                        scrollAnimation();
                      }}
                    >
                      {filteredIssues.length}
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ color: "blue", textDecoration: "underline" }}
                      onClick={() => {
                        dispatch({
                          type: "filterIssue/tableData",
                          // purpose: "unassigned",
                          payload: {
                            header: `${typeString}-Un-Assigned`,
                            data: filteredIssues.filter(
                              (issue) =>
                                issue.status === ISSUE_UNASSIGNED ||
                                issue.status === ISSUE_REOPEN
                            ),
                          },
                          // payload: filteredIssues.filter(
                          //   (issue) =>
                          //     issue.status === ISSUE_UNASSIGNED ||
                          //     issue.status === ISSUE_REOPEN
                          // ),
                        });
                        scrollAnimation();
                      }}
                    >
                      {
                        filteredIssues.filter(
                          (issue) =>
                            issue.status === ISSUE_UNASSIGNED ||
                            issue.status === ISSUE_REOPEN
                        )?.length
                      }
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ color: "blue", textDecoration: "underline" }}
                      onClick={() => {
                        dispatch({
                          type: "filterIssue/tableData",
                          // purpose: "assigned",
                          payload: {
                            header: `${typeString}-Assigned`,
                            data: filteredIssues.filter(
                              (issue) => issue.status === ISSUE_ASSIGNED
                            ),
                          },
                          // payload: filteredIssues.filter(
                          //   (issue) => issue.status === ISSUE_ASSIGNED
                          // ),
                        });
                        scrollAnimation();
                      }}
                    >
                      {
                        filteredIssues.filter(
                          (issue) => issue.status === ISSUE_ASSIGNED
                        )?.length
                      }
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ color: "blue", textDecoration: "underline" }}
                      onClick={() => {
                        dispatch({
                          type: "filterIssue/tableData",
                          // purpose: "started",
                          payload: {
                            header: `${typeString}-In-Progress`,
                            data: filteredIssues.filter(
                              (issue) => issue.status === ISSUE_WORK_STARTED
                            ),
                          },
                          // payload: filteredIssues.filter(
                          //   (issue) => issue.status === ISSUE_WORK_STARTED
                          // ),
                        });
                        scrollAnimation();
                      }}
                    >
                      {
                        filteredIssues.filter(
                          (issue) => issue.status === ISSUE_WORK_STARTED
                        )?.length
                      }
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ color: "blue", textDecoration: "underline" }}
                      onClick={() => {
                        dispatch({
                          type: "filterIssue/tableData",
                          // purpose: "resolved",
                          payload: {
                            header: `${typeString}-Resolved`,
                            data: filteredIssues.filter(
                              (issue) => issue.status === ISSUE_RESOLVED
                            ),
                          },
                          // payload: filteredIssues.filter(
                          //   (issue) => issue.status === ISSUE_RESOLVED
                          // ),
                        });
                        scrollAnimation();
                      }}
                    >
                      {
                        filteredIssues.filter(
                          (issue) => issue.status === ISSUE_RESOLVED
                        )?.length
                      }
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ color: "blue", textDecoration: "underline" }}
                      onClick={() => {
                        dispatch({
                          type: "filterIssue/tableData",
                          // purpose: "closed",
                          payload: {
                            header: `${typeString}-Closed`,
                            data: filteredIssues.filter(
                              (issue) => issue.status === ISSUE_CLOSED
                            ),
                          },
                          // payload: filteredIssues.filter(
                          //   (issue) => issue.status === ISSUE_CLOSED
                          // ),
                        });
                        scrollAnimation();
                      }}
                    >
                      {
                        filteredIssues.filter(
                          (issue) => issue.status === ISSUE_CLOSED
                        )?.length
                      }
                    </button>
                  </td>

                  <td>
                    <button
                      style={{
                        color: "red",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        dispatch({
                          type: "filterIssue/tableData",
                          // purpose: "delayed",
                          payload: {
                            header: `${typeString}-Delayed`,
                            data: filteredIssues.filter(
                              (issue) => issue.isDelayed === true
                            ),
                          },
                          // payload: filteredIssues.filter(
                          //   (issue) => issue.isDelayed === true
                          // ),
                        });
                        scrollAnimation();
                      }}
                    >
                      {
                        filteredIssues.filter(
                          (issue) => issue.isDelayed === true
                        )?.length
                      }
                    </button>
                  </td>
                </tr>
              );
              // <tr>
              //   <td>{moment(KEYS[i]).format("MMMM")}</td>
              //   <td>{issues?.length}</td>
              //   <td></td>
              //   <td>Assign Staff</td>
              //   <td>Work Started</td>
              //   <td>Resolved</td>
              //   <td>Closed</td>
              //   <td>Delayed</td>
              // </tr>
            })
          )}
        </tbody>
      </table>
      {/* {VALUES.map((filteredIssues, i) => {
        const typeString =
          type === "month"
            ? moment(KEYS[i]).format("MMMM-YYYY")
            : `(${moment(startDate).format("DD-MMM-YYYY")}) to (${moment(
                endDate
              ).format("DD-MMM-YYYY")}) | ${KEYS[i]}`;
        return <NormalTable data={VALUES[i]} columns={column} />;
      })} */}
    </>
  );
}
