import React from "react";
import CounterBox from "../Dashboards/CounterBox";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { modalSlice } from "../Dashboards/ModalSlice";
import { Container, Grid, LinearProgress, Typography } from "@mui/material";

import ApexCharts from 'react-apexcharts';



const ResponseResolutionEfficiencyChart = ({ data }) => {
  // Calculate the chart value based on the current count, capped between 0 and 200
  const progressValue = Math.min(Math.max(data?.count, 0), 200);  // Ensure value is between 0 and 200

  // Determine the arrow direction based on progressValue
  const arrow = progressValue >= 100 ? '↑' : '↓'; // Up arrow for values >= 100, down arrow for values < 100

  // Define colors based on chart name or other condition
  // const chartColor = data?.name === 'Response Efficiency' ? '#00E396' : '#FF4560'; 
  const chartColor = progressValue >= 100 ? '#00E396' : '#f30b0b'; 
  const chartOptions = {
    chart: {
      height: 90,  // Adjust the height as needed
      width: '80%',  // Set the width of the chart
      type: 'radialBar',
      toolbar: {
        show: false,  // Hide the toolbar if it's not needed
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 2,    // Reduce margin to make the hollow center smaller
          size: '20%',  // Decrease the size of the hollow center area (adjust as needed)
        },
        dataLabels: {
          name: {
            show: false,  // Hide the name inside the chart
          },
          value: {
            show: true,  // Show value inside the chart
            fontSize: '22px',
            fontWeight: 700,
            color: undefined,
            offsetY: 6,
            formatter: function() {
              return `${progressValue} ${arrow}`;  // Display the value and arrow inside the chart
            },
          },
        },
        barWidth: '8%',  // Set a smaller value for thinner bars
        strokeWidth: "4px",
      },
    },
    fill: {
      type: 'solid',
      colors: [chartColor], // Use color based on chart name
    },
    stroke: {
      lineCap: 'round',
      width: 1,  // Reduce stroke width (try 1 for a thin stroke)
    },
    grid: {
      padding: {
        top: -55,  // Reduces padding on top of the chart
        right: -10,  // Right padding
        bottom: -60,  // Bottom padding
        left: -10,  // Left padding
      },
    },
  };
  
  
  
  

  // Normalize the value to a percentage (0-100) for the radial chart
  const chartSeries = [(progressValue / 200) * 100];

  return (
    <Grid item key={data?.name}>
      <div style={{ textAlign: 'center' }}>
        {/* Display the name above the chart */}
       
      </div>

      {/* The RadialBar chart with the progress value and arrow inside */}
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="radialBar"
        height={200}
      />
       <p variant="body2" className="text-center">
          {data?.name}
        </p>
    </Grid>
  );
};






const HeaderSection = () => {
  const notificationsData = useSelector(
    (state) => state?.modal?.notificationData
  );


  return (
    // <Row className="m-0 p-1">
    //   {notificationsData?.map((data, index) => (
    //     <Col key={index} className="m-0 p-0 h-100 w-100">
    //       <CounterBox
    //         key={index}
    //         id={index}
    //         name={data?.name}
    //         count={data?.count}
    //         value={data?.value}
    //       />
    //     </Col>

    //   ))}
    // </Row>
    
<div style={{ marginTop: '2rem', marginLeft:'2rem',marginRight:'2rem' }}>
  <Grid container spacing={2}>
    {/* Left side: Notifications */}
    <Grid item lg={9} xs={12}>
      <Grid
        container
        rowGap={1}
        columnSpacing={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
          border: '2px solid #fff',
        }}
      >
        {notificationsData.map((data, index) => (
          // Exclude "Response Efficiency" and "Resolution Efficiency" from this list
          !(data.name === 'Response Efficiency' || data.name === 'Resolution Efficiency') && (
            <Grid item sm={6} md={3} key={index}>
              <CounterBox
                id={index}
                name={data?.name}
                count={data?.count}
                value={data?.value}
              />
            </Grid>
          )
        ))}
      </Grid>
    </Grid>

    {/* Right side: Other content */}
    <Grid item lg={3} xs={12}>
  {/* Wrap both charts in a container Grid */}
  <Grid container spacing={2}>
    {notificationsData
      .filter((data) => data.name === 'Response Efficiency' || data.name === 'Resolution Efficiency')
      .map((data, index) => (
        <Grid item xs={6} key={index}> {/* Each chart takes half the width on small screens */}
          <ResponseResolutionEfficiencyChart data={data} />
        </Grid>
      ))}
  </Grid>
</Grid>
  </Grid>
</div>

  );
};

export default HeaderSection;
