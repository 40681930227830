import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CounterBox.module.css";
import { Button, Grid, Paper } from "@mui/material";
import { Stack } from "react-bootstrap";
import styled from "@emotion/styled";
import { counterBoxValue } from "./ModalSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CounterBox({ id, name, count, value }) {
  // console.log("lak", props);
  const activeBoxValue = useSelector((state) => state?.modal?.filteredValue);
  // const boxColor =
  //   value === 1
  //     ? "#a5d8ff"
  //     : value === 2
  //     ? "#1c7ed6"
  //     : value === 3
  //     ? "#d32f2f"
  //     : value === 4
  //     ? "#2e7d32"
  //     : value === 5
  //     ? "#9c27b0"
  //     : value === 7
  //     ? "#c2255c"
  //     : value === 8
  //     ? "#fcc419"
  //     : "rgba(0, 0, 0, 0.6";
  const boxColor =
    value === 1
      ? "#ff8787"
      : value === 2
      ? "#51cf66"
      : value === 3
      ? "#d32f2f"
      : value === 4
      ? "#2e7d32"
      : value === 5
      ? "#9c27b0"
      : value === 7
      ? "#c2255c"
      : value === 8
      ? "#fab005"
      : "rgba(0, 0, 0, 0.6";
  const dispatch = useDispatch();
  return (
    // sx={{ maxWidth: "300px" }} 2e7d32
    // fullWidth variant="contained"
    <button
      className={`${styles.counterBoxButton} ${
        value === activeBoxValue ? styles.counterBoxActive : ""
      } `}
      onClick={() => dispatch(counterBoxValue(value))}
      // style={{ backgroundColor: boxColor, border: `1px solid ${boxColor}` }}
    >
      <div className={styles.flexContainer}>
        <span>{name}</span>
        <span className={styles.counter} style={{ backgroundColor: boxColor }}>
          {count}
        </span>
      </div>
      {/* <Stack direction="horizontal" justifyContent="space-between" spacing={2}>
        <Item>{name} </Item>
        <Item>{count}</Item>
      </Stack> */}
    </button>
  );
}
