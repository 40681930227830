import React, { useState, useEffect } from "react";
import MaterialTable, { MTableBodyRow } from "material-table"; // Import MTableBodyRow

import { Modal, Button, ModalFooter, Form, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import tableIcons from "../common/MaterialTableIcons";
import Spinner from "../Shared/Spinner";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import toast from "react-simple-toasts";
import { setMinutes, setHours, getDay } from "date-fns";
import { ImageUploader, FileUploader, Input, Select } from "../common";
import API from "../Visitor/API_Services/ServiceLayer";
import { fontSize, fontWeight } from "@mui/system";

const userData = JSON.parse(localStorage.getItem("user"));

const TIMER = process.env.REACT_APP_TIMER;

function UpcomingReservaton() {
  const today = new Date();
  const endtime = new Date();
  endtime.setHours(endtime.getHours() + 1);
  const maxTime = new Date();
  maxTime.setHours(23, 30, 0, 0);

  const [todayRes, setTodayRes]=useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [reservationData, setReservationData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [startmaxTime, setstartmaxTime] = useState(maxTime);
  const [startminTime, setstartminTime] = useState(maxTime);
  const [date, setDate] = useState([null, null]);
  const [item, setItem] = useState(null);
  const [checkItem, setCheckItem] = useState(null);
  const [showModification, setShowModification] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [properties, setProperties] = useState([]);
  const [resourceGroup, setResourceGroup] = useState([]);
  const [resourceTypeError, setResourceTypeError] = useState(null);
  const [slprop, setSlprop] = useState(false);
  const [resources, setResources] = useState([]);
  const [resourcesError, setResourcesError] = useState(null);
  const [govtDoc, setGovtDoc] = useState(null);
  const [govtIdType, setGovtIdType] = useState([]);
  const [vcDoc, setVcDoc] = useState(null);
  const [open, setOpen] = useState(false);
  const [proofType, setProofType] = useState("");
  const [proofImg, setProofImg] = useState("");
  const [minDate, setMinDate] = useState(today);
  const [maxDate, setMaxDate] = useState("");
  const [vcf, setVcf] = useState("");
  const [issueRaise, setIssueRaise] = useState(false);
  const [propName, setPropName] = useState("");
  const [status, setStatus] = useState(1);
  const [categoryId, setCategotyId] = useState("");
  const [propertyId, setPropertyID] = useState("");
  const [resourceId, setResourceID] = useState("");
  const [title, setTitle] = useState("");
  const [raisedBy, setRaisedBy] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [roleId, setRoleId] = useState("");
  const [corporateId, setCorporateId] = useState("");
  const [description, setDescription] = useState("");
  const [issueImage, setImageSelect] = useState("");
  const [imagePathName, setImagePathName] = useState("");
  const [filesData, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileArray, setFileArray] = useState([]);
  const [issueImages, setIssueImages] = useState();
  const [myallResource, setmyallresource] = useState([]);
  const [categoryData, setIssueCategory] = useState([]);
  const [allocatedData, setAllocatedData] = useState([]);
  const [issueFormData, setIssueFormData] = useState({
    title: title,
    category_id: categoryId,
    property_id: propertyId,
    resource_id: resourceId,
    status: 1,
    issue_image: issueImage,
    description: description,
  });
  const [formData, setFormData] = useState({
    reserve_type_id: 1,
    no_of_people: 1,
    start_date: today,
    end_date: endtime,
    property_id: "",
    property: {},
    resource_group_id: "",
  });

  useEffect(() => {
    const disabledDays = formData.property.property_timings
      ? formData.property.property_timings.filter((item) => item.is_open === 0)
      : [];

    if (disabledDays.length > 0) {
      const day = getDay(today);
      let isDisabled = disabledDays.find((item) => item.day_code === day);
      if (isDisabled) {
        let dayArr = [0, 1, 2, 3, 4, 5, 6];
        let removedDayes = dayArr.splice(day, dayArr.length);
        dayArr = [...removedDayes, ...dayArr];

        let noDates = _.orderBy(disabledDays, ["day_code"], ["asc"]);
        const dayCode = [];
        noDates.map((d) => dayCode.push(d.day_code));

        let intersection = dayArr.find((x) => !dayCode.includes(x));
        let dayIndex = dayArr.indexOf(intersection);
        today.setDate(today.getDate() + dayIndex);
        today.setHours(startminTime.getHours(), startminTime.getMinutes());

        let data = { ...formData };
        data.start_date = today;
        setFormData(data);
      }
    }
  }, [formData.property]);

  const [selectedResourceGroup, setSelectedResourceGroup] = useState("");
  const [newFilter, setNewFilter] = useState([]);

  const handleResourceGroupChange = (event) => {
    const selectedGroup = event.target.value;
    setSelectedResourceGroup(selectedGroup);
    setNewFilter(
      filteredData.filter(
        (d) => d.resource_group.resource_group_name === selectedGroup
      )
    );
  };

  console.log(selectedResourceGroup, "selectedResourceGroup");

  useEffect(() => {
    loadReservationData();
    loadCurrentData();
    fetchReservationHistory();
    // LoadTodayData();
  }, []);

  useEffect(() => {
    loadReservationData();
    loadCurrentData();
    _getProperties();
    // LoadTodayData();
    
    getUserData();
    allocatedSeatsData();

    API.getData("api/get_role_document/7")
      .then((response) => response.json())
      .then((result) => {
        setGovtIdType(result);
      });

    API.getDatabyToken("api/issues_category?limit=500")
      .then((response) => response.json())
      .then((result) => {
        setIssueCategory(result);
      })
      .catch((err) => console.error(err));
  }, [fileName]);

 
  function allocatedSeatsData() {
    setIsLoading(true);

    API.postMethodNotoken("api/employee-allocated-seat", "POST", {
      employee_id: userData.id,
      corporate_id: userData.corporate_id,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") setAllocatedData(data.results);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error message: ", err);
      });
  }

  function _getProperties() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user, "user");
    API.postMethodNotoken("api/employee-property-list", "POST", {
      emp_id: user.id,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setProperties(data.result.data);
          setmyallresource(data.result.data[0]?.resource_groups);
          console.log(data.result.data[0]?.resource_groups);
        }
      });
  }

  const [myresr, setmyresr] = useState([]);
  // const fetchReservationHistory = async () => {

  //   const userData = JSON.parse(localStorage.getItem("user"));

  //     const vdata = {
  //       Booking_done_by_id: userData?.id
  //     };

  //     try {
  //       const response = await API.postMethodNotoken(
  //         "api/ClusterReservationHistory",
  //         "POST",
  //         {employee_id:userData?.id}
  //       );

  //       const data = await response.json()
  //       console.log(data,"gts")
  //       if(data.status===true){
  //         console.log(data.data,"myhistory")
  //         setReservationHistoryData(data.data);

  //       } else {

  //           console.error("Error message:");

  //       }
  //     } catch(err) {

  //       console.log(err);
  //     }

  // }

  const handleListItemClick = async (data) => {
    const vdata = {
      bookingId: `${data.id}`,
    };
    console.log("vdata", vdata);

    try {
      console.log("done");
      await API.postMethodNotoken(
        "api/edit-cluster-reservation",
        "POST",
        vdata
      );
      window.location.href = `/discover/booking-details/${data.id}`;
      console.log("done2");
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  function fetchReservationHistory() {
    const userData = JSON.parse(localStorage.getItem("user"));

    setIsLoading(true);
    API.postMethodNotoken("api/ClusterReservationHistory", "POST", {
      Booking_done_by_id: userData?.id,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "ClusterReservationHistory");
        if ((result.status = true)) setHistoryData(result.results);
        console.log(result.results, "his");
        console.log(reservationData, "reservedata");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error message: ", err);
      });
  }

  function loadReservationData() {
    const userData = JSON.parse(localStorage.getItem("user"));

    setIsLoading(true);
    API.postMethodNotoken("api/upcomingClusterReservation", "POST", {
      Booking_done_by_id: userData?.id,
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result, "upcomingClusterReservation");
        if ((result.status = true))
          // console.log(result.data.results.fetch_resource, "resttttt");
          // console.log(
          //   "resttfttt",
          //   result.data.results.map((c) => c)
          // );
          //  setmyresr(result.data.results.map((c) => c.fetch_resource));
          setReservationData(result.results);
        LoadTodayData();

        console.log(reservationData, "myreservation");

        if (reservationData.length > 1) {
          let mydt2 =
            reservationData &&
            reservationData?.map((agr) => agr.resource_group_name);
        } else {
          let mydt2 = "";
        }
        console.log(reservationData, "reservedata");
        LoadTodayData();

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error message: ", err);
      });
  }
  function loadCurrentData() {
    setIsLoading(true);

    API.postMethodNotoken("api/currentClusterReservation", "POST", {
      Booking_done_by_id: userData.id,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result.results, "currentClusterReservation");
        if (result.status === "success") setCurrentData(result.results);
        setIsLoading(false);
        LoadTodayData();
      })
      .catch((err) => {
        setIsLoading(false);
        LoadTodayData();

        console.error("Error message: ", err);
      });
  }

  function getUserData() {
    API.getData("api/get_user_documents/" + userData.id)
      .then((response) => response.json())
      .then((result) => {
        setGovtDoc(result.data.find((item) => item.doc_type_id === 4));
        setVcDoc(result.data.find((item) => item.doc_type_id === 5));
      })
      .catch((err) => console.error(err));
  }

  const handleClose = () => {
    setShow(false);
    setShowModification(false);
  };



  const LoadTodayData =()=>{
    
const mergedArray = [...currentData, ...reservationData];

const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
const day = String(today.getDate()).padStart(2, '0');
const todayStr = `${year}-${month}-${day}`;

// Filter to remove duplicates and get only today's reservations
const TodayReservationData = mergedArray.filter((obj, index, self) =>
    index === self.findIndex((o) => o.booking_id === obj.booking_id) &&
    obj.start_date.startsWith(todayStr)
);

setTodayRes(TodayReservationData);
  }



  const [startDate, endDate] = dateRange;
  const sortedData = _.orderBy(reservationData, ["start_date"], ["asec"]);
  const sortedData2 = _.orderBy(currentData, ["start_date"], ["desc"]);
  const sortedData3 = _.orderBy(currentData, ["start_date"], ["desc"]);
  let filteredData =
    !!startDate && !!endDate
      ? sortedData.filter((data) => {
          const startFrom = new Date(data.from_date);
          endDate.setHours(23);
          endDate.setMinutes(59);
          return (
            startFrom.getTime() >= startDate.getTime() &&
            startFrom.getTime() <= endDate.getTime()
          );
        })
      : sortedData;
  // console.log(selectedResourceGroup || reservationData?.resources.resource_group_id === parseInt(selectedResourceGroup));
  const handleInput = ({ currentTarget: input }) => {
    const inputData = { ...formData };
    const value = /^\d*$/.test(input.value) ? +input.value : input.value;
    inputData[input.name] = value;

    if (input.name === "resource_group_name") {
      const selectedValue = input.value;
      setSelectedResourceGroup(selectedValue);
      setSelectedResourceGroup(input.value);
    }

    if (input.name === "reserve_type_id") {
      inputData.property_id = "";
      inputData.resource_group_id = "";
      inputData.resource_id = "";
      setResourceTypeError(null);
      setResourcesError(null);
    }
    if (input.name === "property_id") {
      inputData.resource_group_id = "";
      inputData.resource_id = "";

      const selectedProperty = properties.find((elm) => elm.id == input.value);
      if (selectedProperty) {
        let cloneToday = new Date();
        let starttime = selectedProperty.start_at.toString();
        var fields = starttime.split(":");

        cloneToday.setHours(fields[0], fields[1], 0, 0);

        setstartminTime(cloneToday);

        let cloneend = new Date();
        let endtime = selectedProperty.end_at.toString();

        var fieldend = endtime.split(":");

        cloneend.setHours(fieldend[0], fieldend[1], 0, 0);

        setstartmaxTime(cloneend);

        const cloneForm = { ...formData };
        let aDate = new Date();
        setSlot(aDate);
        inputData.start_date = aDate;

        inputData.end_date = cloneend;
        inputData.property = selectedProperty;
        setFormData(cloneForm);
      }
    }
    if (input.name === "resource_group_id") {
      inputData.resource_id = "";
    }

    setFormData(inputData);

    const ifInputIs = (name, cb) => {
      if (input.name === name) {
        API.postMethodNotoken("api/get-seat-reservation", "POST", {
          ...inputData,
          corporate_id: userData.corporate_id,
        })
          .then((response) => response.json())
          .then((data) => {
            cb(data);
          });
      }
    };

    ifInputIs("property_id", (data) => {
      setSlprop(true);

      if (data.status === "success") {
        setResourceGroup(data.results);
        setResourceTypeError(null);

        const { start_date, end_date } = data.corporate_agreement;
        const min = new Date(start_date);
        const max = new Date(end_date);

        if (min.getTime() > today.getTime()) {
          setMinDate(min);
        }
        if (max.getTime() > today.getTime()) {
          setMaxDate(max);
        }
      } else {
        setResourceTypeError("Resource Type is not available");
        setResourceGroup([]);
      }
    });
    ifInputIs("resource_group_id", (data) => {
      if (data.status === "success") {
        setResources(data.results);
        setResourcesError(null);
      } else {
        setResources([]);
        setResourcesError("Resource Name is not available");
      }
    });
  };

  const handleRequest = (e) => {
    e.preventDefault();

    let start = new Date(formData.start_date);
    start.setMinutes(start.getTime() + 5);

    if (today.getTime() > start.getTime()) {
      toast("Start Date & Time can not be less then current time", TIMER);
      return;
    }
    if (formData.start_date.getTime() >= formData.end_date.getTime()) {
      toast("End Date & Time must be greater than Start Date & Time", TIMER);
      return;
    } else {
      setIsLoading(true);
    }
    const user = JSON.parse(localStorage.getItem("user"));

    const data = {
      employee_id: userData.id,
      corporate_id: user.corporate_id,
      provider_id: 1,
      ...formData,
    };
    console.log(data);

    API.postMethodNotoken("api/post-seat-reservation", "POST", data)
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          html: "<i>" + data.message + "</i>",
          icon: data.status,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "custom-confirm",
          },
        }).then((result) => {
          if (result.isConfirmed && data.status === "success")
            window.location.reload();
        });
        setIsLoading(false);
      });
  };

  const handleEdit = (id) => {
    const data = [...reservationData];
    const row = data.find((item) => item.id === id);

    if (row) {
      row.start_date = new Date(row.start_date);
      row.end_date = new Date(row.end_date);
      setItem(row);
      setShowModification(true);

      API.postMethodNotoken("api/get-seat-reservation", "POST", {
        property_id: row.property_id,
        reserve_type_id: row.reserve_type_id,
        corporate_id: userData.corporate_id,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setResourceGroup(data.results);

            const { start_date, end_date } = data.corporate_agreement;
            const min = new Date(start_date);
            const max = new Date(end_date);

            if (min.getTime() > today.getTime()) {
              setMinDate(min);
            }
            if (max.getTime() > today.getTime()) {
              setMaxDate(max);
            }
          }
        });
      API.postMethodNotoken("api/get-seat-reservation", "POST", {
        property_id: row.property_id,
        reserve_type_id: row.reserve_type_id,
        resource_group_id: row.resource_group_id,
        corporate_id: userData.corporate_id,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setResources(data.results);
          }
        });
    }
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#fc9200",
      confirmButtonText: "Yes, delete",
    }).then((result) => {
      if (result.isConfirmed) {
        API.postMethodNotoken("api/delete-seat-reservation", "POST", {
          id,
          employee_id: userData.id,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              const data = [...reservationData];
              const index = data.findIndex((item) => item.id === id);
              data.splice(index, 1);
              setReservationData(data);
            }
            Swal.fire({
              title: data.status,
              text: data.message,
              icon: data.status,
              timer: TIMER,
            });
          });
      }
    });
  };

  const modifyRequest = (e) => {
    e.preventDefault();
    setIsLoading(true);

    API.postMethodNotoken("api/modify-seat-reservation", "POST", {
      employee_id: userData.id,
      ...item,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          Swal.fire({
            title: "<strong>" + data.status + "</strong>",
            html: "<i>" + data.message + "</i>",
            icon: data.status,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "custom-confirm",
            },
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        }
        setIsLoading(false);
      });
  };

  const modifyInput = ({ currentTarget: input }) => {
    const inputData = { ...item };
    const value = /^\d*$/.test(input.value) ? +input.value : input.value;
    inputData[input.name] = value;
    if (input.name === "reserve_type_id") {
      inputData.property_id = "";
      inputData.resource_group_id = "";
      inputData.resource_id = "";
    }
    if (input.name === "property_id") {
      inputData.resource_group_id = "";
      inputData.resource_id = "";
      inputData.property_details = properties.find(
        (property) => property.id === value
      );
    }
    if (input.name === "resource_group_id") {
      inputData.resource_id = "";
    }

    setItem(inputData);

    const ifInputIs = (name, cb) => {
      if (input.name === name) {
        API.postMethodNotoken("api/get-seat-reservation", "POST", {
          ...inputData,
          corporate_id: userData.corporate_id,
        })
          .then((response) => response.json())
          .then((data) => {
            cb(data);
          });
      }
    };
    ifInputIs("property_id", (data) => {
      setSlprop(true);

      if (data.status === "success") {
        setResourceGroup(data.results);

        const { start_date, end_date } = data.corporate_agreement;
        const min = new Date(start_date);
        const max = new Date(end_date);

        if (min.getTime() > today.getTime()) setMinDate(min);
        if (max.getTime() > today.getTime()) setMaxDate(max);
      } else setResourceGroup([]);
    });
    ifInputIs("resource_group_id", (data) => {
      if (data.status === "success") setResources(data.results);
      else setResources([]);
    });
  };

  const EditBox = (value) => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    setPropertyID(value.property_id);
    setRaisedBy(user["id"]);
    setCreatedBy(user["id"]);
    setCorporateId(user["corporate_id"]);
    setRoleId(user["role_id"]);
    setPropName(value.property_details.property_name);
    setIssueRaise(true);
    setIssueImages();
  };

  function handleFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData();

    {
      issueImages &&
        issueImages.map((value) => {
          formData.append("issue_image[]", value);
        });
    }

    //formData.append("issue_image[]", issueImages);

    if (!issueImages) {
      formData.append("issue_image", []);
    }

    formData.append("description", description);
    formData.append("status", status);
    formData.append("raised_by", createdBy);
    formData.append("category_id", categoryId);
    formData.append("property_id", propertyId);
    formData.append("title", title);
    formData.append("corporate_id", corporateId);

    formData.append("role_id", roleId);
    formData.append("created_by", createdBy);

    API.fileupload("api/issues_detail", formData).then((result) => {
      if (result.data.status === "success") {
        Swal.fire({
          title: "<strong>" + result.data.status + "</strong>",
          html: "<i>" + "Issue raised successfully...!" + "</i>",
          icon: result.data.status,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "custom-confirm",
          },
        }).then((result) => {
          if (result.isConfirmed) window.location.reload();
        });
      }
    });
  }
  const handleCancel = (e) => {};
  const handleFilesChange = ({ target }) => {
    if (target.files[0]) {
      let type = target.files[0].type.split("/");
      let size = target.files[0].size;
      let path = target.files[0].name;
      let name = target.name;
      if (name === "issue_image") {
        setImagePathName(path);

        if (type[1] == "png" || type[1] == "jpg" || type[1] == "jpeg") {
          if (size <= 102400) setFileError("File should be greater than 100Kb");
          if (size >= 204800)
            setFileError("File should not be greater than 200Kb");
          else {
            let files = target.files;
            let files_array = [];
            if (files) {
              files_array = files;
            }

            let array_file_to_display = [...filesData];
            let array_filename_to_dispaly = [];
            let files_data_to_upload = [];

            for (let i = 0; i < files.length; i++) {
              setFileError("");

              array_file_to_display.push(URL.createObjectURL(files[i]));
              array_filename_to_dispaly.push(target.files[i].name);
              files_data_to_upload.push(target.files[i]);
              //setFileArray();
              setFiles(array_file_to_display);
              setFileName(array_filename_to_dispaly);
              setIssueImages(files_data_to_upload);
              //files_array.push(URL.createObjectURL(files[i]));
              //this.setState({ files: files_array });
              //files_data.push(e.target.files[i]);
              //files_name.push(e.target.files[i].name);
              // this.setState({ fileError: "" });
              //this.setState({ issueImages: files_data }, () =>
              //console.log("Issue  data", this.state.issueImages)
              //);
              // this.setState({ files_name: files_name }, () => {
              //   console.log("file name-->", this.state.files_name);
              // });
            }
          }
        } else {
          setFileError("Invalid Format ");
        }
      }
    }
  };

  function handleImageUrlDelete(index, url) {
    const cloneFiles = [...filesData];
    const cloneImages = [...issueImages];
    cloneFiles.splice(index, 1);
    cloneImages.splice(index, 1);
    setFiles(cloneFiles);
    setIssueImages(cloneImages);

    // let data = [];
    // let image_url = [];

    // issueImages &&
    //   issueImages.filter((value, key) => {
    //     if (key != index) {
    //       data.push(value);
    //       // this.setState({files:value}) ;
    //     }
    //     // setIssueImages({ ...issueImages, data });
    //     // return data;
    //   });

    // filesData &&
    //   filesData.filter((val, index) => {
    //     if (val != url) {
    //       image_url.push(val);
    //     }
    //     setFileData({ ...filesData, image_url });
    //   });
  }

  const handleCheckOut = (bookingId, id) => {
    setIsLoading(true);
    const data = {
      booking_id: bookingId,
      user_id: userData.id,
      id: id,
    };
    API.postMethodNotoken("api/employee_check_details", "POST", data)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        Swal.fire({
          title: "<strong>" + data.status + "</strong>",
          html: "<i>" + data.message + "</i>",
          icon: data.status,
          timer: TIMER,
        });
        setProofType("");
        setProofImg("");
        setVcf("");
        loadCurrentData();
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error message: ", err);
      });
  };
  const handleCheckinClose = () => setOpen(false);

  const handleCheckin = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("booking_id", checkItem.id);
    formData.append("user_id", userData.id);
    formData.append("govt_type_id", proofType);
    formData.append("govt_id_path", proofImg);
    formData.append("vaccination_certificate", vcf);

    API.fileupload("api/employee_check_details", formData).then((response) => {
      Swal.fire({
        title: "<strong>" + response.data.status + "</strong>",
        html: "<i>" + response.data.message + "</i>",
        icon: response.data.status,
        timer: TIMER,
      });
      setIsLoading(false);
      setProofType("");
      setProofImg("");
      setVcf("");
      loadCurrentData();
      handleCheckinClose();
      getUserData();
    });
  };
  const columnsHistory = [
    {
      title: "Reservation ID",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "booking_id",
      render: (rowData) => {
        return (
          <Link
            className="font-weight-bold"
            to={{
              pathname: "/discover/booking-details/" + rowData.id,
              state: {
                id: rowData.id,
                from: "Reservation History",
                mydats: true,
              },
            }}>
            {rowData.booking_id}
          </Link>
        );
      },
    },
    {
      title: "Property Name",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "property_details.property_name",
    },
    {
      title: "No.of Resource Booked",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "no_of_people",
    },
    {
      title: "Start Date",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      render: (rowData) => dateFormat(rowData.start_date),
    },
    {
      title: "End Date",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      render: (rowData) => dateFormat(rowData.end_date),
    },
    {
      title: "Property Address",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "property_details.address1",
    },
    {
      title: "Status",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      render: (rowData) => {
        return <>{rowData.booking_status == 11 ? "Cancelled" : "Completed"}</>;
      },
    },
  ];
  const columns = [
    {
      title: "Reservation ID",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "booking_id",

      render: (rowData) => {
        return (
          <Link
            className="font-weight-bold"
            to={{
              pathname: "/discover/booking-details/" + rowData.id,
              state: {
                id: rowData.id,
                from: "Reservations",
                mydats: false,
              },
            }}>
            {rowData.booking_id}
          </Link>
        );
      },
      // filtering: false,
    },
    {
      title: "Property Name",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "property_details.property_name",
      // filtering: false,
    },
    {
      title: "Resource Group",
      render: (rowData) => rowData.resource_group.resource_group_name,
      field: "resource_group_name",
      hidden: true,
      // lookup: lookupOptions,
    },
    {
      title: "Resource",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "resources.resource_name",
      filtering: false,
    },
    {
      title: "Start Date",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      render: (rowData) => dateFormat(rowData.start_date),
      field: "start_date",
      defaultSort: "asc", // Set default sorting order to ascending
      customSort: (a, b) => new Date(a.start_date) - new Date(b.start_date), // Custom sorting function
      // filtering: false,
    },
    {
      title: "End Date",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      render: (rowData) => dateFormat(rowData.end_date),
      field: "end_date",
      // filtering: false,
    },
    {
      title: "Property Address",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "property_details.address1",
      // filtering: false,
    },
    // {
    //   title: 'Status',
    //   render: (rowData) => {
    //     if (rowData.booking_status === '1')
    //       return <span className="text-success">Confirmed</span>;
    //     if (rowData.booking_status === '2')
    //       return <span className="text-danger">Request Denied</span>;
    //     return (
    //       <span className="text-primary">Booking Request In Progress</span>
    //     );
    //   },
    // },
    // {
    //   title: "Actions",
    //   render: (rowData) => (
    //     <div className="text-center">
    //       <i
    //         className="fas fa-calendar-alt text-info icon-sm pointer"
    //         title="Modify Reservation"
    //         onClick={() => handleEdit(rowData.id)}></i>
    //       <i
    //         className="fas fa-times text-danger icon-sm pointer ml-3"
    //         title="Delete Reservation"
    //         onClick={() => handleDelete(rowData.id)}></i>
    //     </div>
    //   ),
    //   width: "30",
    // },
  ];

  const currentColumns = [...columns];



  const Todaycolumns = [
    {
      title: "Reservation ID",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "booking_id",

      render: (rowData) => {
        return (
          <Link
            className="font-weight-bold"
            to={{
              pathname: "/discover/booking-details/" + rowData.id,
              state: {
                id: rowData.id,
                from: "Reservations",
                mydats: false,
              },
            }}>
            {rowData.booking_id}
          </Link>
        );
      },
      // filtering: false,
    },
    {
      title: "Property Name",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "property_details.property_name",
      // filtering: false,
    },
    {
      title: "Resource Group",
      render: (rowData) => rowData.resource_group.resource_group_name,
      field: "resource_group_name",
      hidden: true,
      // lookup: lookupOptions,
    },
    {
      title: "Resource",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "resources.resource_name",
      filtering: false,
    },
    {
      title: "Start Date",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      render: (rowData) => dateFormat(rowData.start_date),
      field: "start_date",
      defaultSort: "asc", // Set default sorting order to ascending
      customSort: (a, b) => new Date(a.start_date) - new Date(b.start_date), // Custom sorting function
      // filtering: false,
    },
    {
      title: "End Date",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      render: (rowData) => dateFormat(rowData.end_date),
      field: "end_date",
      // filtering: false,
    },
    {
      title: "Property Address",
      headerStyle: {
        fontWeight: "bold",
        fontSize: "15px",
      },
      field: "property_details.address1",
      // filtering: false,
    },
    
  ];
  // currentColumns.splice(6, 1, {
  //   title: "Check In / Out",
  //   render: (rowData) => {
  //     console.log(
  //       "End date time < present time",
  //       moment(rowData?.end_date).format("HH:MM"),
  //       new Date().toTimeString().slice(0, 5)
  //     );
  //     console.log(
  //       "End date time > present time",
  //       moment(rowData?.end_date).format("HH:MM"),
  //       new Date().toTimeString().slice(0, 5)
  //     );
  //     console.log(
  //       "Start date time < present time",
  //       moment(rowData?.start_date).format("HH:MM"),
  //       new Date().toTimeString().slice(0, 5)
  //     );
  //     console.log(
  //       "Start date time > present time",
  //       moment(rowData?.start_date).format("HH:MM"),
  //       new Date().toTimeString().slice(0, 5)
  //     );

  //     return rowData.today_check_in_out.length > 0 &&
  //       rowData.today_check_in_out[0].check_in_status == 1 &&
  //       rowData.today_check_in_out[0].check_out_status == 0 &&
  //       moment(rowData?.end_date).format("HH:MM") >
  //         new Date().toTimeString().slice(0, 5) ? (
  //       <Button
  //         variant="link"
  //         className="p-0 m-0"
  //         onClick={() =>
  //           handleCheckOut(rowData.id, rowData.check_in_check_out[0].id)
  //         }>
  //         <strong>Check out</strong>
  //       </Button>
  //     ) : rowData.today_check_in_out.length > 0 &&
  //       rowData.today_check_in_out[0].check_in_status == 1 &&
  //       rowData.today_check_in_out[0].check_out_status == 1 ? (
  //       <h6>Checked out</h6>
  //     ) : moment(rowData?.start_date).format("HH:MM") <
  //       new Date().toTimeString().slice(0, 5) ? (
  //       <Button
  //         variant="link"
  //         className="p-0 m-0"
  //         onClick={() => {
  //           setCheckItem(rowData);
  //           setOpen(true);
  //         }}>
  //         <strong>Check in</strong>
  //       </Button>
  //     ) : (
  //       "Confirmed"
  //     );
  //   },
  //   width: "60",
  // });

  // currentColumns.splice(7, 1, {
  //   title: "Raise an Issue",
  //   headerStyle: {
  //     fontWeight: "bold",
  //     fontSize: "15px",
  //   },

  //   render: (rowData) => (
  //     <strong
  //       style={{
  //         cursor: "pointer",
  //         textDecoration: "underline",
  //       }}
  //       className="text-primary"
  //       onClick={() => EditBox(rowData)}>
  //       Raise an Issue
  //     </strong>
  //   ),
  // });

  let minTime = new Date();
  minTime.setHours(0, 0, 0, 0);

  function setMinTime(date, time) {
    let cloneToday = new Date(time);
    let cloneDate = new Date(date);
    cloneToday.setHours(0, 0, 0, 0);
    cloneDate.setHours(0, 0, 0, 0);

    if (cloneToday.getTime() === cloneDate.getTime()) return time;
    return minTime;
  }

  const [start, end] = date;
  const sortedDatas = _.orderBy(allocatedData, ["start_date"], ["asc"]);

  const dateFormat = (date) => moment(date).format("Do MMM, YYYY - h:mm a");
  const dateFormats = (date) => moment(date).format("Do MMM, YYYY - h:mm a");

  // const seatColumn = [
  //   {
  //     title: "Seat ID",
  //     render: (rowData) => {
  //       return (
  //         <Link
  //           className="font-weight-bold"
  //           to={{
  //             pathname: "/reservation-details",
  //             state: {
  //               id: rowData.seat_id,
  //               from: "Allocated Seats",
  //               url: "/discover/reservaton-history",
  //             },
  //           }}>
  //           {rowData.resource_type_code}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     title: "Start Date",
  //     render: (rowData) => dateFormats(rowData.start_date),
  //   },
  //   {
  //     title: "End Date",
  //     render: (rowData) => dateFormats(rowData.end_date),
  //   },
  //   {
  //     title: "Status",
  //     render: (rowData) => {
  //       if (rowData.status === 0)
  //         return <span className="text-success">Completed</span>;
  //       if (rowData.status === 1)
  //         return <span className="text-success">Confirmed</span>;
  //       if (rowData.status === 2)
  //         return <span className="text-danger">Cancel</span>;
  //     },
  //   },
  // ];
  const filterData =
    !!start && !!end
      ? sortedDatas.filter((data) => {
          const startFrom = new Date(data.from_date);
          end.setHours(23);
          end.setMinutes(59);
          return (
            startFrom.getTime() >= start.getTime() &&
            startFrom.getTime() <= end.getTime()
          );
        })
      : sortedDatas;

  const isWeekday = (date, timings) => {
    const day = getDay(date);

    const disabledDay = timings.filter((item) => item.is_open === 0);

    if (disabledDay.length === 1) return day !== disabledDay[0].day_code;
    if (disabledDay.length === 2)
      return day !== disabledDay[0].day_code && day !== disabledDay[1].day_code;
    if (disabledDay.length === 3)
      return (
        day !== disabledDay[0].day_code &&
        day !== disabledDay[1].day_code &&
        day !== disabledDay[2].day_code
      );
    if (disabledDay.length === 4)
      return (
        day !== disabledDay[0].day_code &&
        day !== disabledDay[1].day_code &&
        day !== disabledDay[2].day_code &&
        day !== disabledDay[3].day_code
      );
    if (disabledDay.length === 5)
      return (
        day !== disabledDay[0].day_code &&
        day !== disabledDay[1].day_code &&
        day !== disabledDay[2].day_code &&
        day !== disabledDay[3].day_code &&
        day !== disabledDay[4].day_code
      );

    return day !== null;
  };

  function setMinMaxTime(time) {
    const timeArray = time.split(":");
    return setHours(setMinutes(new Date(), timeArray[1]), timeArray[0]);
  }

  const isToday = (someDate) => {
    let today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const isSameDate = (dateOne, dateTwo) => {
    const one = new Date(dateOne);
    const two = new Date(dateTwo);
    return (
      one.getDate() == two.getDate() &&
      one.getMonth() == two.getMonth() &&
      one.getFullYear() == two.getFullYear()
    );
  };

  const setSlot = (dateTime) => {
    let nowMinute = dateTime.getMinutes();
    const slot = nowMinute == 0 ? 30 : nowMinute <= 30 ? 60 : 90;
    dateTime.setHours(dateTime.getHours(), slot, 0);
  };

  let minTimeToday = new Date();
  setSlot(minTimeToday);

  console.log(filteredData, "ssdts");
  return (
    <div className="mx-5">
      {isLoading && <Spinner className="position-static my-3" />}
      <div className="row">
        {properties.length > 0 && (
          <div className="col-md-auto ml-auto mb-4">
            {/* <button
              className="btn btn-outline-primary"
              onClick={() => setShow(true)}>
              <i className="mdi mdi-backburger btn-icon-prepend" /> Reserve a
              Seat
            </button> */}
          </div>
        )}
      </div>

      <div className="mb-2 d-flex justify-content-between">
        <div className="visitorpage">
          <Button
            className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
            onClick={() => {
              setActiveTab(1);
            }}
            style={{
              color: activeTab == 1 ? "#fff" : "#fc9200",
              backgroundColor: activeTab == 1 ? "#fc9200" : "#fff",
              border: "1px solid rgb(252, 146, 0)",
              marginRight: "10px",
            }}>
            Current Reservation
          </Button>
          <Button
            className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
            onClick={() => {
              setActiveTab(4);
              LoadTodayData();
            }}
            style={{
              color: activeTab == 4 ? "#fff" : "#fc9200",
              backgroundColor: activeTab == 4 ? "#fc9200" : "#fff",
              border: "1px solid rgb(252, 146, 0)",
              marginRight: "10px",
            }}>
            Today Reservation
          </Button>
          <Button
            className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
            style={{
              color: activeTab == 2 ? "#fff" : "#fc9200",
              backgroundColor: activeTab == 2 ? "#fc9200" : "#fff",
              border: "1px solid rgb(252, 146, 0)",
              marginRight: "10px",
            }}
            onClick={() => {
              setActiveTab(2);
            }}>
            Upcoming Reservation
          </Button>
          <Button
            className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
            onClick={() => {
              setActiveTab(3);
            }}
            style={{
              color: activeTab == 3 ? "#fff" : "#fc9200",
              backgroundColor: activeTab == 3 ? "#fc9200" : "#fff",
              border: "1px solid rgb(252, 146, 0)",
              marginRight: "10px",
            }}>
            Reservation History
          </Button>
        </div>
      </div>
      {activeTab == 1 ? (
        <>
          <div className="mb-4">
            <MaterialTable
              options={{
                tableLayout: "auto",
                pageSize: 2,
                pageSizeOptions: [2, 4, 8, 10],
              }}
              title={
                <h2 style={{ color: "#fc9200", marginBottom: "30px" }}>
                  Current Reservation
                </h2>
              }
              icons={tableIcons}
              columns={currentColumns}
              data={sortedData2}
            />
          </div>
        </>
      ) : activeTab == 2 ? (
        <>
          <div className="mb-4 ">
            <MaterialTable
              options={{
                tableLayout: "auto",
                pageSize: 2,
                pageSizeOptions: [2, 4, 8, 10],
              }}
              title={
                <h2 style={{ color: "#fc9200", marginBottom: "30px" }}>
                  Upcoming Reservation
                </h2>
              }
              icons={tableIcons}
              columns={columns}
              data={newFilter.length > 0 ? newFilter : filteredData}
            />
          </div>
        </>
      ) :
      
      activeTab == 4 ? (
        <>
          <div className="mb-4 ">
            <MaterialTable
              options={{
                tableLayout: "auto",
                pageSize: 2,
                pageSizeOptions: [2, 4, 8, 10],
              }}
              title={
                <h2 style={{ color: "#fc9200", marginBottom: "30px" }}>
                  Today Reservation
                </h2>
              }
              icons={tableIcons}
              columns={Todaycolumns}
              data={newFilter.length > 0 ? newFilter : todayRes}
            />
          </div>
        </>
      ) 
      : (
        <>
          <div className="mb-4">
            <MaterialTable
              options={{
                tableLayout: "auto",
                pageSize: 2,
                pageSizeOptions: [2, 4, 8, 10],
              }}
              title={
                <h2 style={{ color: "#fc9200", marginBottom: "30px" }}>
                  Reservation History
                </h2>
              }
              icons={tableIcons}
              columns={columnsHistory}
              data={historyData}
              components={{
                Row: (props) => {
                  console.log("Row Data:", props.data);
                  console.log("is_cluster Value:", props.data.is_cluster);

                  return (
                    <MTableBodyRow
                      {...props}
                      onClick={(event) => {
                        if (props.data.is_cluster === 0) {
                          handleListItemClick(props.data);
                        }
                      }}
                      rowStyle={{
                        backgroundColor:
                          props.data.is_cluster === 0 ? "red" : "yellow",
                      }}
                    />
                  );
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UpcomingReservaton;
