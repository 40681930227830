import React from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-simple-toasts";
import axios from "axios";
import { INVITEGUEST } from "../Visitor/apiConfig";
import Swal from "sweetalert2";

function ConfirmModal(props) {
  function success(message) {
    // toast(`${message}!`, {
    //   time: 2000,
    //   position: "top-right",
    //   clickable: true,
    //   clickClosable: true,
    //   className: "success",
    // });
    Swal.fire({
      html: `<i>${message}</i>`,
      icon: "success",
      // title: "Success",
      showConfirmButton: false,
      timer: 2000,
    });
  }
  function error() {
    Swal.fire({
      html: `<i>Something Went wrong</i>`,
      icon: "warning",
      // title: "Warning",
      showConfirmButton: false,
      timer: 2000,
    });
  }
  const handleContinue = () => {
    if (props.modalText == "Check Out") {
      console.log("checkOut");
      console.log(props.modalId, props.modalStatus);
      let hours = new Date().getHours();
      let minutes = new Date().getMinutes();
      let temp = `${hours}:${minutes}`;
      // console.log(temp);
      const formdata = {
        check_out: temp,
        status: props.modalStatus,
      };
      axios
        .put(`${INVITEGUEST}/${props.modalId}`, formdata)
        .then((res) => {
          if (res?.data?.status) {
            success(res?.data?.message);
            props.setloadingTime(true);
          } else {
            error();
          }
          console.log(res, "API-CHECKOUT");
        })
        .catch((err) => console.log(err));
    } else if (props.modalText == "Cancel") {
      const formData = {
        status: props.modalStatus,
      };
      axios
        .delete(`${INVITEGUEST}/${props.modalId}`, formData)
        .then((res) => {
          if (res?.data?.status) {
            success(res?.data?.message);
            props.setloadingTime(true);
            Swal.fire({
              html: `<i>Cancelled Successfully</i>`,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            error();
          }
          console.log(res, "API-CHECKIN");
        })
        .catch((err) => console.log(err));
    }
  };
  console.log({ ...props });
  return (
    <div className="confirm_model_cancel">
      <Modal
        style={{ background: "#0d0c0cba" }}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>
          <p
            className="modal_confirm_text"
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
            }}>
            Are you sure you want to {props?.modalText}?
          </p>
          <div
            className="d-flex justify-content-around confirmModal_parent"
            style={{ marginTop: "20px" }}>
            <button
              onClick={() => {
                props.onHide();
              }}
              className="confirmModal_button"
              style={{
                backgroundColor: "#fc9200",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "5px",
                fontSize: "16px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}>
              Go Back
            </button>
            <button
              onClick={() => {
                handleContinue();
              }}
              className="confirmModal_button"
              style={{
                backgroundColor: "#9E9E9E",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "5px",
                fontSize: "16px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}>
              {props?.modalText}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
